 .display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}
.modal-right-side {
    right: 0px !important;
}

.checkbox-tools:checked + label {
    background: #ecbfcc !important;
}

.checkbox-tools-remove-bg:checked + label, .checkbox-tools:not(:checked) + label {
      background: unset !important;
      border: unset !important;
}

.btn_submit {
    height: 63px;
    width: auto;
    display: inline-block;
    background: #ff95a2;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    border-radius: 5px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 6px 26px;
    letter-spacing: normal;
    position: relative;
    top: -3px;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.checkbox-tools-dropoff:checked + label {
     border: 2px solid #ecbfcc !important;
     background : #ecbfcc ;
}

.imageCloseButton {
    top: -1px !important;
    position: absolute;
    right: 22px;
}


.notification_center .pagination {
    width: unset !important;
        margin-top: 10px;
}

.notification_center .pagination .page-link {
    color: #000 !important;
}
.alert-confirm-main {
    top: 50% !important;
}

.custom-loader{
    top: 50%;
    position: fixed;
    margin: auto;
    width: 100%;
    z-index: 999999;
}

.why-imgicons img {
    width: 150px;
}
.ptop-133 {
    padding-top: 0px;
}
.nopading {
    padding: 0px;
}
.promo button {
    top: 0px;
}


.charges-details .form-group.check-group:nth-last-child(2) {
    margin-bottom: 25px;
}
.charges-details .form-group.check-group {
    margin-bottom: 15px;
}
.charges-details .form-group {
    position: relative;
    margin-bottom: 25px;
}
.charges-details .form-group.check-group .custom-checkbox .custom-control-input {
    height: 18px;
    width: 18px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.charges-details .form-group.check-group .custom-checkbox .custom-control-label {
    width: auto;
    text-align: left;
    position: static;
    top: auto;
    left: auto;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    padding-left: 30px;
    cursor: default;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    letter-spacing: normal;
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
    line-height: 32px;
}
.charges-details .form-group.check-group .custom-checkbox .custom-control-label {
    font-size: 14px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 20px !important;
}
.charges-details .form-group label {
    position: absolute;
    top: 18px;
    margin-bottom: 0;
    left: 17px;
    z-index: 2;
    font-size: 14px;
    font-weight: 400;
    color: #546274;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: text;
    letter-spacing: normal;
}
.charges-details .form-group.check-group .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background: #e9b6bc;
}
.charges-details .form-group.check-group .custom-checkbox .custom-control-label:before {
    top: 4px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 0px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}
.charges-details .form-group.check-group .custom-checkbox .custom-control-label:after {
    top: 4px;
    left: 0;
    height: 18px;
    width: 18px;
}

.chargedetails-btn {
     display: flex;
}
.chargedetails-btn .prev:hover {
    text-decoration: none;
}
.chargedetails-btn .prev {
    font-size: 16px;
    font-weight: 700;
    text-transform: none;
    color: #fff;
    margin-top: 10px;
    display: inline-block;
    line-height: 46px;
    padding: 6px 20px;
}
.chargedetails-btn .btn {
    height: 55px;
    width: auto;
    display: inline-block;
    background: #e78293;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    border-radius: 0px;
    outline: none;
    box-shadow: none;
    padding: 6px 24px;
    letter-spacing: normal;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
}


.pagination-wrapper {
    text-align: center;
    margin: 0px auto;
    width: 100%;
    position: relative;
    justify-content: center;
    display: flex;
    margin-top: 20px;
}
.pagination-wrapper .page-item button:after {
    display:none;
}
.pagination-wrapper .pagination li {
    margin: 0 0 0px 6px;
    font-size: 14px;
}
.pagination-wrapper .pagination .page-item.active .page-link {
    z-index: 3;
    color: #fff !important;
    background-color: #ff95a2;
    border-color: #ff95a2;
}
.pagination-wrapper .pagination .page-link {
    color: #6c757d;
}


.unit-apartment-box {
    background: #f5f5f5;
    padding: 15px;
    border-radius: 10px;
    padding-bottom: 0px;
}

.ufg-job-application-wrapper .unit-apartment-box .form-group.radio-group:nth-last-child(2) {
    margin-bottom: 5px;
}
.ufg-job-application-wrapper .unit-apartment-box .form-group.radio-group {
    border-bottom: none;
}

.form-control:focus {
    box-shadow: none;
}

.logo-topvideo {
    position: relative;    
    width: 100%;top: -35px;
}
.logo-icontop {
        width: 100px;
    float: none;
    position: relative;
    top: 50px;
    right: -105px;
    display: block;
    margin: 0px auto;
}
.moveitlog-center {
    width: 500px;
    display: block;
    display: block;
    margin: 0px auto;
}
.moveit-tagline {
    width: 470px;
    display: block;
    margin: 0px auto;
    margin-top: 5px;
        position: relative;
    left: -15px;
}
.share-details-btn button:after {
    display: none;
}

.lgout span {
    position: relative;
    z-index: 1;
}
.lgout:after {
    background: #e78293;
    -webkit-clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.lgout:focus:after {
    -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.lgout:hover:after {
    -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
        transition: all .4s cubic-bezier(.645, .045, .355, 1);
}


.helperbtn span {
    position: relative;
    z-index: 1;
}
.helperbtn:after {
    background: #e78293;
    -webkit-clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.helperbtn:focus:after {
    -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.helperbtn:hover:after {
    -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
        transition: all .4s cubic-bezier(.645, .045, .355, 1);
}



.blog-t .flourish .flourish-square {
    display: block;
    height: 90px;
    left: -35px;
    position: absolute;
    top: -45px;
    width: 90px;
    z-index: -1;
}
.ptb-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

#map_canvas button:after {
    display: none;
}

.move-summary .editbtn {
    background: #ff95a2;
    color: #fff;
    padding: 2px 16px;
    display: inline-block;
    border-radius: 2px;
    font-size: 12px;
    height: auto;
    line-height: 20px;
    margin-right: 5px;
}
.move-summary .delete {
    background: #5a5a5a;
    color: #fff;
    padding: 2px 16px;
    display: inline-block;
    border-radius: 2px;
    font-size: 12px;
    height: auto;
    line-height: 20px;
}
.movedetail-boxcustomer .move-summary .cardbox img {
    width: 55px;
    height: 55px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-bottom: 0px;
    float: left;
    margin-right: 10px;
    border-radius: 0px;
}
.movedetail-boxcustomer .move-summary .cardbox h5 {
    text-align: left;
    font-size: 14px;
    margin-bottom: 8px;
    position: relative;
    top: 0px;
    font-weight: 700;
    color: #000;
}
.movedetail-boxcustomer .move-summary .t-item {
    display: inline-block;
    background: #ff95a2;
    padding: 4px;
    border: none;
    height: auto;
    font-size: 12px;
    color: #fff;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 10px;
    margin-left: 5px;
    float: initial;
}

.move-summary-charges .last-itme-box h6 {
    display: inline-block;
    width: 70%;
}
.move-summary-charges .last-itme-box .edit {
    float: right;
    color: #000;
    padding-top: 0px;
    padding-right: 0px;
    font-size: 16px;
    width: 30%;
}
.move-summary-charges .last-itme-box .edit a {
    color: #ff95a2;
    font-weight: bold;
    font-size: 20px;
    float: right;
    display: inline-block;
    text-align: right;
}
.move-summary-charges .fontname a {
    color: #ff95a2;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    font-weight: 600;
    text-decoration: underline;
}
.move-summary-charges .fontname {
    font-size: 15px !important;
    font-weight: bold;
    padding-top: 10px !important;
}

.nomoveimg {
    text-align: center;
    font-weight: 700;
    color: #000;
    font-size: 18px;
}
.nomoveimg img {
   width: 170px;margin-top: 50px;
    margin-bottom: 10px;
}

.space-stepone {
    margin: 0px;
}
.space-stepone .col-md-12 {
    padding: 0px 5px;
}
.space-stepone-1 {
    margin: 0px;
}
.space-stepone-1 .col-md-12 {
    padding: 0px 5px;
}
.abput-txt {
    padding-bottom: 0px;
}
.abput-txt p {
    font-size: 16px;
    font-weight: 500;
}
.abput-txt a {
    color: #ff95a2;
    font-size: 14px;
    font-weight: 600;
}
.abput-txt .inline-button i {
    color: #e78293;
    position: relative;
    right: 0;
}
.abput-txt .image-content img {
    margin: 0 0 -68px -300px;
    max-width: calc(100% + 280px);
}
.about-middle-content {
    padding-top: 0px;
}
.about-middle-content p {
    font-size: 17px;
    line-height: 28px;
    color: #000;
    font-weight: 500;
}
.about-middle-content .highlights .highlight:nth-of-type(2n) .content .content-container {
    text-align: left;
}

.movepro-title {
        text-align: left;
    margin-bottom: 15px;
    font-weight: 600;
    padding-top: 5px;
    padding-left: 20px;
}
.movepro-title span {
    font-weight: 400;
}
.fnt {
    margin-bottom: 0px;
}
.fnt .form-row {
    margin-bottom: 0px;
}


.text-danger {
    color: #ff0018 !important;
    font-size: 11px;
    font-weight: 600;
}
.login-page > .phonecountry > select {
    padding: 15px 10px;
    font-size: 12px;
}


/****** Move it Pro Muscles page css ****/
.breadcrumbs-wrapper {
        position: relative;
    z-index: 1;
    padding: 125px 0 100px;
    align-items: center;
    text-align: center;
}
.breadcrumbs-wrapper:before {
    left: 0;
    background: url(../img/dots.png) no-repeat 50%;
    background-size: auto;
    background-repeat: repeat;
    background-position: center;
}
.breadcrumbs-wrapper:after, .breadcrumbs-wrapper:before {
    position: absolute;
    top: 0;
    content: "";
    width: 50%;
    height: 100%;
}
.breadcrumbs-wrapper:after {
    right: 0;
    background: url(../img/04.jpg) no-repeat 50%;
    background-size: cover;
}
.mpvepro-muscles {
	padding:0px;
}
.breadcrumbs-wrapper .page-title h1.title {
    font-size: 26px;
    margin-bottom: 10px;
    line-height: 30px;
}
.breadcrumbs-wrapper .page-title .breadcrumbs-link {
    display: inline-block;
}
.breadcrumbs-wrapper .page-title .breadcrumbs-link li {
    display: inline-block;
    margin-left: 0px;
    margin-right: 10px;
}
.promuscle {
	padding: 30px;
}
.promuscle h3 {
    font-size: 30px;
    font-weight: bold;
    color: #ff95a2;
}
.promuscle h6 {
    font-size: 16px;
    font-weight: bold;
}
.pro-muscles img {
	width:100%;
}
.pro-muscles .highlights:before {
	display:none;
}


.why-pro-muscles .themestek-ihbox-icon {
    display: inline-block;
    vertical-align: top;
}
.why-pro-muscles .themestek-ihbox-icon img {
    width:55px;
	margin-bottom:10px;
}
.why-pro-muscles .themestek-ihbox-contents {
    padding-left: 0px;
}
.why-pro-muscles .themestek-custom-heading {
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
}
.why-pro-muscles .themestek-cta3-content-wrapper {
    font-size: 15px;
    color: #000;
    line-height: 24px;
}

.nabkimg-point {
	background: url(../img/pattern_3.svg) no-repeat center center;
	background-color:#f8f8f8;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    z-index: 1;
}
.rigtxt {
	padding-left:0px;
	display:inline-block;list-style: none;
}
.rigtxt li {
	font-size: 16px;
    margin-bottom: 20px;
    text-align: right;
    line-height: 28px;
    font-weight: 500;
    color: #000;
    margin-left: 0px;
}
.cen0img {
	width:auto;
	display:block;
	margin:0px auto;
}
.lfttxt {
	padding-left:0px;
	display:inline-block;list-style: none;
}
.lfttxt li {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: left;
    line-height: 28px;
    font-weight: 500;
    color: #000;
    margin-left: 0px;
}
.pro-muscles .text {
    font-size: 24px;
}
.why-pro-muscles .text {
	font-size:24px;
	line-height:30px;
	text-align: left;
}
.why-pro-muscles .flourish.text .flourish-square {
    left: -55px;
    top: -45px;
}
.why-pro-muscles small {
    font-size: 60%;
    font-weight: 400;
}
.nabkimg-point .text {
    font-size: 24px;
}
.sigCanvas {
    width: 100%;
    height: 100%;
}
.highlights .highlight .content .content-container h4 {
    font-size: 26px;
    font-weight: bold;
}
.highlights .highlight .content .content-container p {
    font-size: 18px;
    line-height: 25px;
}
.plink {
    color: #ff95a2 !important;
    margin-top: 10px;
    display: block;
    text-decoration: underline;
}



/***** HELPER VIEW DETAIL ****/


.helper-msg-map {
    background: #747474;
    color: #fff !important;
    padding: 5px;
    border-radius: 100px;
    position: relative;
    top: 5px;
    width: 35px;
    height: 35px;
    line-height: 25px;
    display: inline-block;
    text-align: center;
}
.helper-msg-review {
    background: #fdbb00;
    color: #fff !important;
    padding: 5px;
    border-radius: 100px;
    position: relative;
    top: 5px;
    width: 35px;
    height: 35px;
    line-height: 25px;
    display: inline-block;
    text-align: center;
}
.helper-msg-info {
    background: #ff95a2;
    color: #fff !important;
    padding: 11px 10px;
    border-radius: 100px;
    position: relative;
    top: 5px;
    width: 35px;
    height: 35px;
    line-height: 45px;
    display: inline-block;
    text-align: center;
}
.heper-revew {
    margin-left: 10px;
    font-weight: 600;
}
.heper-revew i {
	color: #fdbb00;
}
.helperinfo-box {
    padding: 15px;
    border: 1px solid #ddd;
    background: #f5f5f5;
    margin-bottom: 20px;
}
.accpetstatus-helper .timeline {
  list-style-type: none;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    left: 25px;
}

.accpetstatus-helper .li {
    transition: all 200ms ease-in;
    margin-left: 0px;
}

.accpetstatus-helper .timestamp {
  margin-bottom: 20px;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 100;
}
.accpetstatus-helper .status {
    padding: 20px 60px;
    display: flex;
    justify-content: center;
    border-top: 2px solid #D6DCE0;
    position: relative;
    transition: all 200ms ease-in;
    padding-left: 0px;
}
.accpetstatus-helper .status h6 {
      font-weight: 600;
    font-size: 12px;
    position: relative;
    left: -20px;
}
.accpetstatus-helper .status:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #ddd;
  position: absolute;
  top: -15px;
  left: 0%;
  transition: all 200ms ease-in;
}

.accpetstatus-helper .li .line-status {
    border-top: 2px solid #ddd;
}
.accpetstatus-helper .line-status.done-line {
    border-top: 2px solid #ff95a2;
}

.lastline-none {
    border-top: 2px solid #f5f5f5 !important;
}
.accpetstatus-helper .li.complete:last-child .status {
    border-top: 2px solid #f5f5f5;
}




.accpetstatus-helper .li.complete .status:before {
  background-color: #ff95a2;
  border: none;
  transition: all 200ms ease-in;
}
.accpetstatus-helper .li.complete .status h4 {
  color: #ff95a2;
}
.callmsg-icon {
    margin-left: 10px;
    font-weight: 600;
}
.callmsg-icon i {
    border: 1px solid #ddd;
    padding: 5px;
    display: inline-block;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    line-height: 20px;
}
.tiphelper-box {
	list-style:none;
	margin-left:0px;
}
.tiphelper-box li {
        margin-left: 0px;
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    padding: 0px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.tiphelper-box .checkbox-tools:checked + label {
    background: #ff95a2 !important;
    color: #fff;
}
.tiphelper-box .checkbox-tools:not(:checked) + label:hover {
    box-shadow: none;
}
.tiphelper-box .checkbox-tools:checked + label, .tiphelper-box .checkbox-tools:not(:checked) + label {
    position: relative;
    display: inline-block;
    padding: 8px;
    display: block;
    width: 100%;
    line-height: 20px;
    letter-spacing: 1px;
    margin: 0 auto;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    color: #fff;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    border-radius: 0px;
    border: 1px solid #ff95a2;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 100px;
}
.tiphelper-box .cardbox h5 {
    text-align: center;
    font-size: 13px;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 0px;
    position: relative;
    top: 8px;
    font-weight: 600;
}
.tiphelper-box .checkbox-tools:checked + label h5 {
    color: #fff;
}
.addtip-review-helper .modal-dialog {
    width: 350px !important;
}
.helper-profile {
    text-align: left;
    display: flex;
    align-items: center;
}
.helper-profile img {
	    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 100px;
    margin: 0px;
    display: block;
    margin-bottom: 10px;
    float: left;
    margin-right: 25px;
}
.helper-profile h6 {
	margin-bottom:0px;
	font-size:18px;
	font-weight:600;
}
.star-rating {
  direction: rtl;
  display: inline-block;
  padding: 0px;
  cursor: default;
}
.star-rating input[type=radio] {
  display: none;
}
.star-rating label {
  color: #bbb;
  font-size: 1.4rem;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.star-rating label:hover,
.star-rating label:hover ~ label,
.star-rating input[type=radio]:checked ~ label {
  color: #f2b600;
}
.helper-msg-accept {
    background: #ff95a2;
    color: #fff !important;
    padding: 10px;
    border-radius: 100px;
    position: relative;
    top: 3px;
}
.helper-msg-reject {
    background: #404040;
    color: #fff !important;
    padding: 10px;
    border-radius: 100px;
    position: relative;
    top: 3px;
}
.acc-reject a:hover {
	text-decoration:none;
}
.viewdetail {
    background: #ff95a2;
    color: #fff;
    padding: 10px 20px;
    display: inline-block;
    margin-top: 10px;
	position:relative;
}
.viewdetail:hover {
    text-decoration:none;
	color:#fff;
}
.viewdetail span {
    position: relative;
    z-index: 1;
}
.viewdetail:after {
    background: #e78293;
    -webkit-clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.viewdetail:focus:after {
    -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.viewdetail:hover:after {
    -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
        transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.ytp-chrome-top, .ytp-chrome-bottom {
    display: none;
}
.video-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0px;z-index: -1;
}
.video-wrapper video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.pt30import {
    padding-top: 30px !important;
}
.z-dx1 {
    z-index: 1 !important;
}
.soculbtn {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0;
    margin-bottom: 10px;
}
.soculbtn .facebook {
    width: 70%;
    margin: 0px 5px;
}
.soculbtn .twitter {
    width: 70%;
    margin: 0px 5px;
}
.facebook-button-class {
    background: #3763d2;
    display: inline-block;
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    padding-top: 12px;
    padding-bottom: 9px;
    font-size: 11px !important;
}
.google-button-class {
    background: #ea4335 !important;
    display: inline-block !important;
    margin-top: 20px;
    opacity: 1 !important;
    padding: 10px !important;
    box-shadow: none !important;
    width: 100% !important;
    border-radius: 0px !important;
    border: none !important;
    width: 100%;
    color: #fff !important;
}
.google-button-class svg {
    display: none;
}
.google-button-class div {
    display: none;
}
.google-button-class span {
    padding: 0px !important;
}
.facebook-button-class:after {
    display: none;
}
.google-button-class:after {
    display: none;
}

.newbnt-promuscle {
    background: #1e1f24;
    color: #fff;
    padding: 10px 20px;
    display: block;
    margin-bottom: 40px;
    position: relative;
    text-align: center;
    border-radius: 10px;
    transition: all 300ms ease;
}

.newbnt-promuscle:hover {
    color: #fff;
    text-decoration: none;
    transition: all .2s cubic-bezier(.645, .045, .355, 1);
}
.zin1 {
    z-index: 1 !important;
}

.insurnaceupload-edit .avatar-edit {
    position: absolute;
    right: 35px;
    z-index: 1;
    top: 2px;
}
.insurnaceupload-edit .avatar-edit input {
    display: none;
}
.insurnaceupload-edit .avatar-edit input + label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 12%);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
}
.insurnaceupload-edit .avatar-edit input + label:after {
    content: "\f040";
    font-family: 'FontAwesome';
    color: #757575;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
}
.tab-content a {
	color: #ff95a2;
}

.phonetxt {
	top: 0 !important;
    font-size: 0.9rem !important;
}



@media (min-device-width: 320px) and (max-device-width: 700px) {
  .accpetstatus-helper .timeline {
    list-style-type: none;
    display: flex;
    left: 0px;
}
  .accpetstatus-helper .status {
		padding: 20px 5px;
		display: flex;
   }
  .accpetstatus-helper .li {
    transition: all 200ms ease-in;
    margin-left: 0px;
}

 .accpetstatus-helper .timestamp {
    width: auto;
}

  .accpetstatus-helper .status:before {
        left: 30%;
    top: -25%;
    transition: all 200ms ease-in;
  }
	  .accpetstatus-helper .status h6 {
		font-weight: 600;
		font-size: 10px;
		position: relative;
		left: 0px;
	}
}



@media (max-width:567px) {
.accordion-section .hero.full {
    min-height: 400px;
    max-height: 400px;
    padding: 30px 0px;
}
.blogbox img {
    max-height: 340px;
    -o-object-fit: cover;
    object-fit: cover;
    height: 340px;
}
.flourish {
    margin-left: 25px;
}
.f-46 {
    font-size: 24px !important;
    font-weight: bold !important;
}
.image-content img {
    margin: 0 0 20px -40px !important;
    max-width: calc(100% + 40px) !important;
    transform: none!important;
}
.bf-testimonial-slick .slick-next {
    right: 10px;
}
.bf-testimonial-slick .slick-prev {
    left: 10px;
    z-index: 1;
}


}

@media (max-width:767px) {
.hero h1 {
    font-size: 26px;
}
.moveit-tagline {
    width: 285px;
    left: -10px;
}
.moveitlog-center {
    width: 300px;
}
.logo-icontop {
    width: 50px;
    top: 35px;
    right: -65px;
}
.text {
    font-size: 16px;
}
.bf-testimonial-author-info .author-name h4 {
    font-size: 18px;
    font-weight: bold;
    padding-left: 0px;
}
.slick-slide {
    padding: 0px 25px;
}
.callout h2 {
    font-size: 18px;
    line-height: 30px;
}
.servicw-sliderbox {
    padding-bottom: 100px;
}
.servicw-sliderbox .slick-slide img {
    height: 150px;
    -o-object-fit: cover;
    object-fit: cover;
}
.servicw-sliderbox .slick-initialized .slick-slide {
    height: auto;
    padding: 0px 10px;
}
.servicebox .card-body {
    height: 110px;
    padding: 10px;
}
.servicebox .card-text p {
    margin-top: 0px;
}
.servicw-sliderbox .card-title {
    font-size: 16px;
}
.cituform-box {
    padding: 20px 10px;
}
.businessbox {
    margin-bottom: 65px;
    position: relative;
}
.businessbox-mt-0 {
    margin-bottom: 0px;
}
.margin-bottom-md {
    margin-bottom: 20px !important;
}
.accordion-section {
    height: calc(100% - 100px);
    position: relative;
    height: fit-content;
    min-height: auto;
    max-height: initial;
}
.hero.full {
    height: auto;
    min-height: calc(50vh - 0px);
}
.accordion-section .accordions section article {
    padding-bottom: 0px;
}
.breadcrumbs-wrapper .page-title .breadcrumbs-link {
    display: flex;
}

}

#ReactSimpleImageViewer { 
    z-index : 9999;
}

.react-datepicker__day--disabled {
    color : gray;
}




.customer_edit_move {
    color: red;
    border-bottom: 1px solid red;
    width: auto;
}

.removeBackGroundEffect:after {
    background:unset !important;
}
.reconfirm-message {
    
} 

.activeTAb{
    color: #ff95a2 !important;
} 
.custom-loader-span {
    width : 100% !important;
}

.helper-available-job {
    background-color: rgb(255 149 162);
    background-image: linear-gradient(357deg, rgb(255 111 129) 0%, #ffc9d0 74%);
    text-align: center;
    margin: auto;
    color: #fff;
    top: auto;
    padding: 10px;
    border-radius: 5px;
}
.detail-cties-img {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
	padding:75px 0px;
}
.nobg li {
    background: transparent;
}
.w130 {
	width:130px;
	margin-right:10px;
}
.citiesbg-opcity {
	background: #ffffffba;
    position: relative;
       z-index: 1;
    padding: 20px;
    text-align: left;
    border-radius: 10px;
}
.citiesbg-opcity h5 {
    color: #1a1a1a;
    display: block;
    font-weight: 500;
        line-height: 34px;
    position: relative;
    text-align: left;
    width: 100%;
    z-index: 2;text-shadow: none;
	font-size:24px;
	padding: 0px;
}
.citiesbg-opcity h6 {
    font-size: 24px;
    padding: 0px;
    margin-bottom: 10px;
    padding-top: 10px;
    font-weight: 500;
        line-height: 34px;
    text-shadow: none;
    color: #1a1a1a;
    text-align: left;
}
.addextra-text {
    font-size: 18px !important;
    line-height: 23px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    color: #000 !important;
    text-shadow: none !important;
    text-align: left !important;
    padding: 0px !important;
}


/********* Service detail page css ***********/

.mv_lightgray_wrapper {
    width: 100%;
    padding: 110px 0 50px;
    background-color: #f5f5f5;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAp0lEQVQ4je2UQQ7CMAwEZ9P+pkf+/xduUN5AvT0kKXBBiPjYPSWRMlrb8up6W31/rFyWhXkqDMmAYAOKJ+Mi0BgTAMXr+LRdyOFCALVqeQujhs2hA4HCdhbPADaSqm8ngQWoVV9EYgfeHBZMnuXOt5Ftd26ac2DOBnYNrtoJPsF/gCNp+Woeu4IDXBDx/c9PalkMNTb7ZVTunGhZ8fGYpGN4Ka04BhXsvGg//h22FYkAAAAASUVORK5CYII=);
    background-size: 11px 11px;
}
.mv_aboutus1 h2 {
    font-size: 45px;
    color: #333;
    font-weight: 700;
    margin-bottom: 20px;
}
.mv_aboutus2 {
    z-index: 1;
    position: relative;
    margin-bottom: 50px;
}
.mv_aboutus2 img {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.mv_aboutus2:after {
    position: absolute;
    content: "";
    top: -40px;
    width: 90%;
    border: solid 6px rgb(255 149 162);
    height: 100%;
    right: -40px;
    z-index: -1;
}

.bookbtn-service {
	position: relative;
    background: #ff95a2;
    z-index: 5;
    padding: 10px 30px;
    border-radius: 0px;
    color: white;
    font-weight: 600;
    margin-top: 15px;
}
.bookbtn-service:after {
	z-index:-1;
	background: #e78293;
    clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    content: "";
    display: block !important;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.bookbtn-service:hover:after {
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.bookbtn-service:hover {
    background: #ff95a2;
	color: white;
	border:1px solid #ff95a2;
}

.mv_aboutus2 img {
    transition: all 0.3s;
    height: 380px;
    width: 100%;
    object-fit: cover;
}

@media all and (max-width:480px) and (min-width:360px){
.mv_aboutus2 img {
    transition: all 0.3s;
    height: 250px;
    width: 100%;
    object-fit: cover;
}
}

@media all and (max-width:767px) and (min-width:481px){
.mv_aboutus2 img {
    transition: all 0.3s;
    height: 340px;
    width: 100%;
    object-fit: cover;
}
}


@media (max-width:767px) {
.citiesbg-opcity {
    padding: 25px;
}
.citiesbg-opcity h6 {
    font-size: 18px !important;
    padding: 0px !important;
    line-height: 26px !important;
	margin-bottom: 10px !important;
}
.citiesbg-opcity h5 {
   padding-top: 0px !important;
   font-size: 16px !important;
   line-height: 26px !important;
}
.addextra-text {
    font-size: 14px !important;
    line-height: 23px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    color: #000 !important;
    text-shadow: none !important;
    text-align: left !important;
}
.mv_aboutus2:after {
	display:none;
}
.mv_lightgray_wrapper {
    padding: 60px 0 50px;
}
.mv_aboutus2 {
    z-index: 2;
    position: relative;
    margin-bottom: 10px;
    margin-top: 40px;
}

}

@media all and (max-width:1080px) and (min-width:768px){
.mv_lightgray_wrapper {
    padding: 60px 0 50px;
}
.mv_aboutus2 {
    z-index: 5;
    position: relative;
    margin-bottom: 10px;
        margin-top: 80px;
}
.mv_aboutus2 img {
    transition: all 0.3s;
    height: 480px;
    width: 100%;
    object-fit: cover;
}
}

   .achievements-awards {
      margin-top: 20px;
   }

   .awards-logos img {
      max-width: 100%;
      height: auto;
      margin-bottom: 10px;
   }


   .moveitliceningHead{margin:20px 0;font-size:16px;padding-left:0}
   .moveitliceningHead:before{display:none}
   .movelicensingFirst .mv_aboutus2 img {
    width: 90%;
   }
   .ShildImg{margin:0;transform:none!important;max-width:100%;width:100%}
   .movelicensingFirst{padding:80px 0;padding-bottom:30px;position:relative}
   .whatwedoLicense{background-color:#ededed;padding-top:80px;padding-bottom:80px;position:relative}
   .whatwedoLicense h2{text-align:center;font-weight:700;margin-bottom:15px;color:#ff95a2}
   .whatwedoLicense p{text-align:center;font-weight:400}
   .moreInfoBtn{border:2px solid #ff95a2;padding:13px 15px;color:#212529;display:block;margin:0 auto;text-align:center;width:250px;font-weight:700}
   .moreInfoBtn:hover{text-decoration:none;background-color:#ff95a2;color:#fff}
   .flexBox{padding:40px;background-color:#fff;box-shadow:0 0 15px #e9e9e9;text-align:center;border-radius:10px;margin-bottom:30px}
   .flexBox i{font-size:40px;margin-bottom:20px}
   .pinkBoxCenter{background-color:#ff95a2;color:#fff}
   .licensingBoxThird{padding:80px 0;padding-bottom:60px;position:relative}
   .founderTxt{font-size:24px;line-height:38px;font-weight:300}
   .nameF{font-size:22px;margin-top:30px;font-style:italic;color:#ff95a2;font-weight:700}
   .circleImgLicense{width:90px;height:90px;background:#fff;padding:0;border-radius:12px;margin-bottom:20px;border:2px solid #fa929f}
   .circleImgLicense img{width:45px !important; display:block;text-align:center;margin:20px auto}
   .headingServicesLic{text-align:center;font-size:24px;font-weight:700}
   .subHeadingServicesLic{text-align:center;font-size:14px;font-weight:400;line-height:22px!important}
   .bookconsutancy{position:relative;padding:80px 0;text-align:center;background-position:top;background-size:cover;background-color:#dae4e6}
   .bookconsutancy h2{color:#242424;position:relative;z-index:1}
   .bookconsutancy p{color:#242424;position:relative;z-index:1}
   .bookconsutancy a{color:#fff;position:relative;z-index:1;border:1px solid #fff}
   .lassFooterSection{position:relative;padding:100px 0;text-align:center;background-position:top;background-size:cover}
   .lassFooterSection:after{background:#000;content:"";height:100%;left:0;opacity:.8;pointer-events:none;position:absolute;top:0;width:100%}
   .lastFooterHead{position:relative;top:0;font-size:64px;color:#f2b9bf;z-index:1}
   .lastFooterHead img{width:220px;top:-30px;position:relative}
   .copyrightFooterLicense{background-color:#242424;color:#fff;text-align:center;padding-top:10px;padding-bottom:10px;}
   .copyrightFooterLicense p{margin-bottom:0}
   .lastFooterSubHead{font-style:italic;color:#f2b9bf;position:relative;z-index:1;font-size:40px}
   .lastFooterSubHead-two{color:#f2b9bf;position:relative;z-index:1;font-size:30px;margin:30px 0}
   .lastFooterSubHead-three{color:#f2b9bf;position:relative;z-index:1;font-size:26px;margin-bottom:0}
   .lastFooterHeadPra{color:#f2b9bf;position:relative;z-index:1;font-size:14px;margin-bottom:0;text-align:left}
   .lastFooterHeadPra-copyrigt{color:#fff;position:relative;z-index:1;font-size:12px;margin-bottom:0}

   .faqmoveitlicense .tab-label{background:#dae4e6;font-size:18px}
   .faqmoveitlicense .tab-content{font-size:16px}
   .whyusLicensing{padding-bottom:50px}
   .whyusLicensing h6{font-size:16px;line-height:24px;margin-bottom:20px}
   .licendingBgServices{background-color:#dae4e6;padding:80px 0;padding-bottom:30px}


   .comingSoonBlink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 10px;
    background-color: #0075d9;
    border-radius: 50%;
    position: relative;
  }
  .comingSoonBlink:before {
    content: "";
    width: inherit;
    height: inherit;
    border: 5px solid #0075d9;
    border-radius: inherit;
    position: absolute;
    z-index: 10;
    opacity: 1;
    animation: 1.5s expand cubic-bezier(0.29, 0, 0, 1) infinite;
    animation-delay: 1.5s;
  }
pre {
    font-family: 'Josefin Sans', sans-serif;
}

#style-4::-webkit-scrollbar-track { background-color: #fff;position:absolute;right:20px;}
#style-4::-webkit-scrollbar { width: 0px; background-color: #fff;}
#style-4::-webkit-scrollbar-thumb { background-color: #fff; border: #fff;}

.citiesbg-opcity {
    height: 95vh;
    overflow-y: scroll;
    max-height: 95vh;
    min-height: 95vh;
}
  @keyframes expand {
    /* Starting at 1 to prevent IOS flicker */
    1% {
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      width: 450%;
      height: 450%;
      opacity: 0;
      border-color: #fff;
      background-color: none;
      border-width: 2px;
    }
  }


@media (max-width:767px) {
    .movelicensingFirst .mv_aboutus2 img {
        margin-bottom: 20px;
    }
    .movelicensingFirst {
        padding: 20px 0px;
        padding-bottom: 30px;
        position: relative;
    }
    .whatwedoLicense img { margin-bottom: 30px;}
    .moreInfoBtn {
        margin-bottom: 40px;
    }
    .whatwedoLicense {
        padding-bottom: 30px;
    }



}

@media (min-width: 2001px) and (max-width: 2600px) {
    .citiesbg-opcity {
        height: 40vh;
        overflow-y: scroll;
        max-height: 40vh;
        min-height: 40vh;
    }
}

@media (min-width: 1800px) and (max-width: 2000px) {
    .citiesbg-opcity {
        height: 55vh;
        overflow-y: scroll;
        max-height: 55vh;
        min-height: 55vh;
    }
}
@media (min-width: 1500px) and (max-width: 1799px) {
    .citiesbg-opcity {
        height: 60vh;
        overflow-y: scroll;
        max-height: 60vh;
        min-height: 60vh;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .citiesbg-opcity {
        height: 100vh;
        overflow-y: scroll;
        max-height: 100vh;
        min-height: 100vh;
    }
}