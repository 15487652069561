:root {
  --bg-page: #ffffff;
  --bg-page-darker: #f7f7f7;
  --bg-page-darkest: #b3b3b3;
  --colour-primary: #3996fb;
  --colour-primary-lightest: #e8f3ff;
  --colour-primary-darker: #f2c2ca;
  --colour-third: #419d78;
  --colour-third-lighter: #7bc9aa;
  --colour-third-lightest: #e6f7f0;
  --colour-text: #696969;
  --colour-text-lighter: #9b9b9b;
  --colour-text-darker: #626262;
  --colour-text-darkest: #363636;
  --border-color: #e8e7e7;
  --form-radius: 13px;
  --search-form-bg-colour: #f2f2f2;
  --send-form-bg-colour: #f2f2f2;
  --send-btn-box-shadow-colour: #7bbafd;
  --chat-bubble-me: #f2f2f2;
  --chat-bubble-you: #f2c2ca;
}
/* ===================================
    Main
====================================== */
.messages-page {
  height: 65vh;
}
.messages-page__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.messages-page__title {
  color: var(--colour-text-darker);
  font-weight: bold;
  font-size: 1.5rem;
}
@media screen and (max-width: 1199px) {
  .messages-page__title {
    font-size: 1.5rem;
  }
}
.messages-page__dark-mode-toogler {
  width: 2.6rem;
  height: 2.6rem;
  padding: 0.35rem;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.messages-page__dark-mode-toogler:hover {
  background-color: var(--colour-primary);
  border-color: var(--colour-primary);
}
.messages-page__dark-mode-toogler:hover path {
  fill: var(--bg-page-darker);
}
.messages-page__list {
  list-style: none;
  flex-grow: 1;
  overflow-y: auto;
}
.messages-page__list-scroll {
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-form {
  color: var(--colour-text-darkest);
  padding: 1.5rem;
  border-radius: 13px;
}
.custom-form__search-wrapper, .custom-form__send-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.custom-form__search-wrapper input::-moz-placeholder, .custom-form__send-wrapper input::-moz-placeholder {
  color: var(--colour-text-lighter);
  font-size: 0.9rem;
}
.custom-form__search-wrapper input:-ms-input-placeholder, .custom-form__send-wrapper input:-ms-input-placeholder {
  color: var(--colour-text-lighter);
  font-size: 0.9rem;
}
.custom-form__search-wrapper input::placeholder, .custom-form__send-wrapper input::placeholder {
  color: var(--colour-text-lighter);
  font-size: 0.9rem;
}
.custom-form__search-wrapper input:focus, .custom-form__send-wrapper input:focus {
  outline: none;
  box-shadow: none;
}
.custom-form__search-wrapper input {
  padding-right: 3rem;
  background-color: var(--search-form-bg-colour);
  border: 1px solid var(--bg-page);
}
.custom-form__search-wrapper input:-moz-placeholder-shown {
  background-color: var(--search-form-bg-colour);
  border: 1px solid var(--bg-page);
}
.custom-form__search-wrapper input:-ms-input-placeholder {
  background-color: var(--search-form-bg-colour);
  border: 1px solid var(--bg-page);
}
.custom-form__search-wrapper input:placeholder-shown {
  background-color: var(--search-form-bg-colour);
  border: 1px solid var(--bg-page);
}
.custom-form__search-wrapper input:focus {
  background-color: var(--bg-page);
  border-color: var(--border-color);
  color: var(--colour-text);
}
.custom-form__send-wrapper input {
  padding-right: 6rem;
  padding-left: 3.25rem;
  background-color: var(--send-form-bg-colour);
  border: none;
}
.custom-form__send-wrapper input:focus {
  background-color: var(--send-form-bg-colour);
  border-color: transparent;
  color: var(--colour-text);
}
.custom-form__search-submit {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 3.5rem;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-form__search-submit:focus {
  outline: none;
  border: none;
}
.custom-form__send-submit {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  height: 2.3rem;
  width: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #242424;
  border-radius: 50%;
  box-shadow: 0 3px 3px var(--send-btn-box-shadow-colour);
  border: none;
  outline: none;
  text-align: center;
  font-size: 1.2rem;
  padding-top: 0.3rem;
  color: white;
  padding-right: 0.1rem;
  font-size: 14px
}
.custom-form__send-submit i {
	    position: relative;
    right: 15px;
    top: 5px;
}
.custom-form__send-submit:after{
	display:none;
}
.custom-form__send-submit:focus {
  outline: none;
  border: none;
}
.custom-form__send-submit:hover {
  background-color: var(--colour-primary-darker);
}
.custom-form__send-img {
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
  width: 2.3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-form__send-emoji {
  position: absolute;
  top: 50%;
  right: 3.2rem;
  transform: translateY(-50%);
  width: 2.3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messaging-member {
  border-radius: var(--form-radius);
      margin-left: 0px;
}
.messaging-member:hover {
  background-color: var(--bg-page-darker);
}
.messaging-member--new .messaging-member__name {
  color: var(--colour-text-darker);
}
.messaging-member--new .messaging-member__message {
  color: var(--colour-text-darker);
}
.messaging-member--online .user-status {
  background-color: var(--colour-third-lighter);
}
.messaging-member--active {
  background-color: var(--colour-primary-lightest);
}
.messaging-member--active:hover {
  background-color: var(--colour-primary-lightest);
}
@media screen and (max-width: 767px) {
  .messaging-member--active {
    background-color: var(--bg-page);
  }
  .messaging-member--active:hover {
    background-color: var(--bg-page-darker);
  }
}
.messaging-member__wrapper {
  cursor: pointer;
  padding: 0.5rem 0rem;
  border-radius: var(--form-radius);
  display: grid;
  grid-template-columns: 4rem 4fr;
  grid-template-rows: 2rem 2rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  grid-template-areas: "avatar     name" "avatar     message";
}
@media screen and (max-width: 1199px) {
  .messaging-member__wrapper {
    grid-template-columns: 3.5rem 1fr;
    grid-template-rows: 1.75rem 1.75rem;
  }
}
@media screen and (max-width: 991px) {
  .messaging-member__wrapper {
    grid-template-columns: 3.2rem 1fr;
    grid-template-rows: 1.75rem 1.75rem;
  }
}
.messaging-member__avatar {
  grid-area: avatar;
  position: relative;
}
.messaging-member__avatar img {
      border-radius: 50%;
    width: 65px;
    height: 65px;
    object-fit: cover;
}
.messaging-member__name {
  font-weight: bold;
  grid-area: name;
  color: var(--colour-text-darker);
  margin-top: auto;
  font-size: 0.9rem;
}
.messaging-member__message {
  grid-area: message;
  white-space: nowrap;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.9rem;
}

.chat {
  height: 100%;
  width: 100%;
}
.chat__container {
  height: 100%;
  width: 100%;
}
.chat__wrapper {
  background-color: var(--bg-page);
  height: 100%;
  width: 100%;
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .chat__wrapper {
    border-left: none;
    border-right: none;
  }
}
.chat__messaging {
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chat__previous {
  width: 8%;
  min-width: 2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  cursor: pointer;
  color: var(--colour-primary);
}
.chat__notification {
  width: 4%;
  min-width: 1.5rem;
}
.chat__notification span {
  display: none;
  width: 1.4rem;
  height: 1.4rem;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
  color: white;
  background-color: #ff002b;
  font-size: 0.9rem;
}
.chat__notification--new span {
  display: block;
}
.chat__infos {
  flex-grow: 1;
}
.chat__actions {
  font-size: 5px;
  height: 2rem;
  min-width: 2rem;
  color: var(--colour-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.chat__actions ul {
  list-style: none;
  display: flex;
}
.chat__actions li {
  width: 2.6rem;
  height: 2.6rem;
  padding: 0.35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--bg-page);
}
.chat__actions li + li {
  margin-left: 0.3rem;
}
.chat__actions li:hover {
  background-color: var(--colour-primary-lightest);
}
.chat__content {
  flex-grow: 1;
  overflow-y: auto;
}
.chat__list-messages {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.chat__list-messages li {
  margin-bottom: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.chat__list-messages li .chat__bubble {
  margin-bottom: 0.2rem;
}
.chat__bubble {
  position: relative;
  color: var(--colour-text-darkest);
  padding: 0.5rem 1rem;
  border-radius: 0px;
  background-color: var(--bg-page);
      max-width: 30rem;
    font-size: 14px;
    overflow: hidden;
    overflow-wrap: break-word;
    word-break: break-word;
    line-height: 20px;
}
.chat__bubble--you {
  margin-right: 2rem;
  color: white;
  background-color: var(--chat-bubble-you);
}
.chat__bubble--me {
  margin-left: 2rem;
  background-color: var(--chat-bubble-me);
  align-self: flex-end;
}
.chat__time {
  font-size: 0.8rem;
  color: var(--colour-text-lighter);
  align-self: flex-end;
 padding-bottom: 0px;
    line-height: 23px;
}
.chat__time__left {
  font-size: 0.8rem;
  color: var(--colour-text-lighter);
  align-self: flex-start;
  padding-bottom: 0px;
    line-height: 23px;
}
.chat__send-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chat-member__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.chat-member__avatar {
  position: relative;
  width: 3.5rem;
}
.chat-member__avatar img {
  border-radius: 50%;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .chat-member__avatar {
    width: 2.5rem;
  }
}
.chat-member__name {
  font-weight: bold;
  color: var(--colour-text-darker);
  margin-top: auto;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1rem;
}
@media screen and (max-width: 1199px) {
  .chat-member__name {
    font-size: 0.9rem;
  }
}
.chat-member__details {
  margin-left: 0.8rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .chat-member__details {
    margin-left: 1rem;
  }
}
.chat-member__age {
  font-size: 0.9rem;
  color: var(--colour-text-lighter);
  position: relative;
}
.chat-member__age::after {
  content: " . ";
  font-size: 0px;
  position: absolute;
  top: 50%;
  right: -4px;
  width: 3px;
  height: 3px;
  background-color: var(--colour-text-lighter);
  border-radius: 50%;
}
.chat-member__status {
  color: var(--colour-text-lighter);
  font-size: 0.8rem;
}
.chat__profile {
  width: 100%;
  height: 100%;
  max-width: 20rem;
}
.chat--mobile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  transform: translateY(100%);
  display: none;
  transition: transform 0.3s ease-in-out 0.1s;
}
.chat--mobile .chat__wrapper {
  border-radius: 0;
}
.chat--mobile.chat--show {
  display: block;
  transform: translateY(0%);
  border-radius: 0;
}

.user-profile {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
}
.user-profile__wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.user-profile__close {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  width: 2rem;
  height: 2rem;
  color: var(--colour-primary);
  font-size: 1.375rem;
  cursor: pointer;
  z-index: 10003;
}
.user-profile__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-profile__avatar img {
  width: 9rem;
  border-radius: 50%;
}
.user-profile__name {
  font-weight: bold;
  margin-top: 0.7rem;
  color: var(--colour-text-darker);
  word-wrap: break-word;
  font-size: 1.15rem;
}
@media screen and (max-width: 767px) {
  .user-profile__name {
    font-size: 1.1rem;
  }
.messages-page {
    height: auto;
} 
}
.user-profile__phone {
  color: var(--colour-text-darker);
  font-size: 0.9rem;
}
.user-profile__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.user-profile__location {
  color: var(--colour-text-lighter);
  font-size: 0.9rem;
}
.user-profile__description {
  text-align: center;
}
.user-profile__description p {
  margin-top: 1.3rem;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
}
.user-profile__label {
  font-size: 0.9rem;
  font-weight: bold;
}
.user-profile__tags {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.user-profile__tags li {
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  font-size: 0.9rem;
}
.user-profile__tags a:hover {
  text-decoration: none;
}
.user-profile__tags--primary li {
  background-color: var(--colour-primary-lightest);
  color: var(--colour-primary-darker);
}
.user-profile__tags--primary a:hover {
  color: var(--colour-primary-darker);
}
.user-profile__tags--secondary li {
  background-color: var(--colour-third-lightest);
  color: var(--colour-third);
}
.user-profile__tags--secondary a:hover {
  color: var(--colour-third);
}
.user-profile--large {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10002;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out 0.1s;
  background-color: var(--bg-page);
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.06);
}
.user-profile--large.user-profile--show {
  display: block;
  transform: translateX(0%);
  border-radius: 0;
}

.user-status {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 1rem;
  height: 1rem;
  background-color: var(--colour-text-lighter);
  border: 3px solid white;
  border-radius: 50%;
}
.user-status--online {
  background-color: var(--colour-third);
}

.svg-icon {
  width: 70%;
}
.svg-icon path,
.svg-icon circle {
  fill: #000000;
}
.svg-icon--send {
  width: 60%;
}
.svg-icon--send path,
.svg-icon--send circle {
  fill: white;
}
.svg-icon--search {
  width: 40%;
}
.svg-icon--search path,
.svg-icon--search circle {
  fill: var(--bg-page-darkest);
}
.svg-icon--send-img {
  width: 55%;
}
.svg-icon--send-img path,
.svg-icon--send-img circle {
  fill: var(--bg-page-darkest);
}
.svg-icon--send-emoji {
  width: 60%;
}
.svg-icon--send-emoji path,
.svg-icon--send-emoji circle {
  fill: var(--bg-page-darkest);
}
.svg-icon--previous {
  width: 55%;
}
.svg-icon--dark-mode {
  width: 80%;
}
.svg-icon--dark-mode path,
.svg-icon--dark-mode circle {
  fill: var(--colour-primary);
}