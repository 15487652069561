[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
    -webkit-transition-duration: 50ms;
    transition-duration: 50ms
}

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
    -webkit-transition-delay: 50ms;
    transition-delay: 50ms
}

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
    -webkit-transition-duration: .1s;
    transition-duration: .1s
}

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
    -webkit-transition-duration: .15s;
    transition-duration: .15s
}

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
    -webkit-transition-delay: .15s;
    transition-delay: .15s
}

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
    -webkit-transition-duration: .2s;
    transition-duration: .2s
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
    -webkit-transition-delay: .2s;
    transition-delay: .2s
}

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
    -webkit-transition-duration: .25s;
    transition-duration: .25s
}

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
    -webkit-transition-delay: .25s;
    transition-delay: .25s
}

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
    -webkit-transition-delay: .3s;
    transition-delay: .3s
}

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
    -webkit-transition-duration: .35s;
    transition-duration: .35s
}

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
    -webkit-transition-delay: .35s;
    transition-delay: .35s
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
    -webkit-transition-duration: .4s;
    transition-duration: .4s
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
    -webkit-transition-delay: .4s;
    transition-delay: .4s
}

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
    -webkit-transition-duration: .45s;
    transition-duration: .45s
}

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
    -webkit-transition-delay: .45s;
    transition-delay: .45s
}

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
    -webkit-transition-duration: .5s;
    transition-duration: .5s
}

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
    -webkit-transition-delay: .5s;
    transition-delay: .5s
}

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
    -webkit-transition-duration: .55s;
    transition-duration: .55s
}

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
    -webkit-transition-delay: .55s;
    transition-delay: .55s
}

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
    -webkit-transition-duration: .6s;
    transition-duration: .6s
}

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
    -webkit-transition-delay: .6s;
    transition-delay: .6s
}

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
    -webkit-transition-duration: .65s;
    transition-duration: .65s
}

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
    -webkit-transition-delay: .65s;
    transition-delay: .65s
}

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
    -webkit-transition-duration: .7s;
    transition-duration: .7s
}

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
    -webkit-transition-delay: .7s;
    transition-delay: .7s
}

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
    -webkit-transition-duration: .75s;
    transition-duration: .75s
}

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
    -webkit-transition-delay: .75s;
    transition-delay: .75s
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
    -webkit-transition-duration: .8s;
    transition-duration: .8s
}

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
    -webkit-transition-delay: .8s;
    transition-delay: .8s
}

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
    -webkit-transition-duration: .85s;
    transition-duration: .85s
}

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
    -webkit-transition-delay: .85s;
    transition-delay: .85s
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
    -webkit-transition-duration: .9s;
    transition-duration: .9s
}

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
    -webkit-transition-delay: .9s;
    transition-delay: .9s
}

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
    -webkit-transition-duration: .95s;
    transition-duration: .95s
}

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
    -webkit-transition-delay: .95s;
    transition-delay: .95s
}

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
    -webkit-transition-duration: 1s;
    transition-duration: 1s
}

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
    -webkit-transition-delay: 1s;
    transition-delay: 1s
}

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
    -webkit-transition-duration: 1.05s;
    transition-duration: 1.05s
}

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.05s;
    transition-delay: 1.05s
}

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
    -webkit-transition-duration: 1.1s;
    transition-duration: 1.1s
}

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s
}

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
    -webkit-transition-duration: 1.15s;
    transition-duration: 1.15s
}

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.15s;
    transition-delay: 1.15s
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
    -webkit-transition-duration: 1.2s;
    transition-duration: 1.2s
}

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.2s;
    transition-delay: 1.2s
}

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
    -webkit-transition-duration: 1.25s;
    transition-duration: 1.25s
}

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.25s;
    transition-delay: 1.25s
}

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
    -webkit-transition-duration: 1.3s;
    transition-duration: 1.3s
}

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.3s;
    transition-delay: 1.3s
}

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
    -webkit-transition-duration: 1.35s;
    transition-duration: 1.35s
}

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.35s;
    transition-delay: 1.35s
}

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
    -webkit-transition-duration: 1.4s;
    transition-duration: 1.4s
}

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.4s;
    transition-delay: 1.4s
}

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
    -webkit-transition-duration: 1.45s;
    transition-duration: 1.45s
}

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.45s;
    transition-delay: 1.45s
}

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
    -webkit-transition-duration: 1.5s;
    transition-duration: 1.5s
}

[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.5s;
    transition-delay: 1.5s
}

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
    -webkit-transition-duration: 1.55s;
    transition-duration: 1.55s
}

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.55s;
    transition-delay: 1.55s
}

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s
}

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.6s;
    transition-delay: 1.6s
}

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
    -webkit-transition-duration: 1.65s;
    transition-duration: 1.65s
}

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.65s;
    transition-delay: 1.65s
}

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
    -webkit-transition-duration: 1.7s;
    transition-duration: 1.7s
}

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.7s;
    transition-delay: 1.7s
}

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
    -webkit-transition-duration: 1.75s;
    transition-duration: 1.75s
}

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.75s;
    transition-delay: 1.75s
}

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
    -webkit-transition-duration: 1.8s;
    transition-duration: 1.8s
}

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.8s;
    transition-delay: 1.8s
}

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
    -webkit-transition-duration: 1.85s;
    transition-duration: 1.85s
}

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.85s;
    transition-delay: 1.85s
}

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
    -webkit-transition-duration: 1.9s;
    transition-duration: 1.9s
}

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.9s;
    transition-delay: 1.9s
}

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
    -webkit-transition-duration: 1.95s;
    transition-duration: 1.95s
}

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
    -webkit-transition-delay: 1.95s;
    transition-delay: 1.95s
}

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
    -webkit-transition-duration: 2s;
    transition-duration: 2s
}

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
    -webkit-transition-delay: 2s;
    transition-delay: 2s
}

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
    -webkit-transition-duration: 2.05s;
    transition-duration: 2.05s
}

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.05s;
    transition-delay: 2.05s
}

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
    -webkit-transition-duration: 2.1s;
    transition-duration: 2.1s
}

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.1s;
    transition-delay: 2.1s
}

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
    -webkit-transition-duration: 2.15s;
    transition-duration: 2.15s
}

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.15s;
    transition-delay: 2.15s
}

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
    -webkit-transition-duration: 2.2s;
    transition-duration: 2.2s
}

[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.2s;
    transition-delay: 2.2s
}

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
    -webkit-transition-duration: 2.25s;
    transition-duration: 2.25s
}

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.25s;
    transition-delay: 2.25s
}

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
    -webkit-transition-duration: 2.3s;
    transition-duration: 2.3s
}

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.3s;
    transition-delay: 2.3s
}

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
    -webkit-transition-duration: 2.35s;
    transition-duration: 2.35s
}

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.35s;
    transition-delay: 2.35s
}

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
    -webkit-transition-duration: 2.4s;
    transition-duration: 2.4s
}

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.4s;
    transition-delay: 2.4s
}

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
    -webkit-transition-duration: 2.45s;
    transition-duration: 2.45s
}

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.45s;
    transition-delay: 2.45s
}

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
    -webkit-transition-duration: 2.5s;
    transition-duration: 2.5s
}

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.5s;
    transition-delay: 2.5s
}

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
    -webkit-transition-duration: 2.55s;
    transition-duration: 2.55s
}

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.55s;
    transition-delay: 2.55s
}

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
    -webkit-transition-duration: 2.6s;
    transition-duration: 2.6s
}

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.6s;
    transition-delay: 2.6s
}

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
    -webkit-transition-duration: 2.65s;
    transition-duration: 2.65s
}

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.65s;
    transition-delay: 2.65s
}

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
    -webkit-transition-duration: 2.7s;
    transition-duration: 2.7s
}

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.7s;
    transition-delay: 2.7s
}

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
    -webkit-transition-duration: 2.75s;
    transition-duration: 2.75s
}

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.75s;
    transition-delay: 2.75s
}

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
    -webkit-transition-duration: 2.8s;
    transition-duration: 2.8s
}

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.8s;
    transition-delay: 2.8s
}

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
    -webkit-transition-duration: 2.85s;
    transition-duration: 2.85s
}

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.85s;
    transition-delay: 2.85s
}

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
    -webkit-transition-duration: 2.9s;
    transition-duration: 2.9s
}

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.9s;
    transition-delay: 2.9s
}

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
    -webkit-transition-duration: 2.95s;
    transition-duration: 2.95s
}

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
    -webkit-transition-delay: 2.95s;
    transition-delay: 2.95s
}

[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
    -webkit-transition-duration: 3s;
    transition-duration: 3s
}

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
    -webkit-transition-delay: 0;
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
    -webkit-transition-delay: 3s;
    transition-delay: 3s
}

[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    transition-timing-function: cubic-bezier(.25, .25, .75, .75)
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease
}

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in
}

[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out
}

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out
}

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
    transition-timing-function: cubic-bezier(.6, -.28, .735, .045)
}

[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
    transition-timing-function: cubic-bezier(.175, .885, .32, 1.275)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
    transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55)
}

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.47, 0, .745, .715);
    transition-timing-function: cubic-bezier(.47, 0, .745, .715)
}

[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.39, .575, .565, 1);
    transition-timing-function: cubic-bezier(.39, .575, .565, 1)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.445, .05, .55, .95);
    transition-timing-function: cubic-bezier(.445, .05, .55, .95)
}

[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.55, .085, .68, .53);
    transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.25, .46, .45, .94);
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.455, .03, .515, .955);
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.55, .085, .68, .53);
    transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.25, .46, .45, .94);
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.455, .03, .515, .955);
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.55, .085, .68, .53);
    transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.25, .46, .45, .94);
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.455, .03, .515, .955);
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform
}

[data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

[data-aos=fade-up] {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0)
}

[data-aos=fade-down] {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0)
}

[data-aos=fade-right] {
    -webkit-transform: translate3d(-100px, 0, 0);
    transform: translate3d(-100px, 0, 0)
}

[data-aos=fade-left] {
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0)
}

[data-aos=fade-up-right] {
    -webkit-transform: translate3d(-100px, 100px, 0);
    transform: translate3d(-100px, 100px, 0)
}

[data-aos=fade-up-left] {
    -webkit-transform: translate3d(100px, 100px, 0);
    transform: translate3d(100px, 100px, 0)
}

[data-aos=fade-down-right] {
    -webkit-transform: translate3d(-100px, -100px, 0);
    transform: translate3d(-100px, -100px, 0)
}

[data-aos=fade-down-left] {
    -webkit-transform: translate3d(100px, -100px, 0);
    transform: translate3d(100px, -100px, 0)
}

[data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1)
}

[data-aos=zoom-in] {
    -webkit-transform: scale(.6);
    transform: scale(.6)
}

[data-aos=zoom-in-up] {
    -webkit-transform: translate3d(0, 100px, 0) scale(.6);
    transform: translate3d(0, 100px, 0) scale(.6)
}

[data-aos=zoom-in-down] {
    -webkit-transform: translate3d(0, -100px, 0) scale(.6);
    transform: translate3d(0, -100px, 0) scale(.6)
}

[data-aos=zoom-in-right] {
    -webkit-transform: translate3d(-100px, 0, 0) scale(.6);
    transform: translate3d(-100px, 0, 0) scale(.6)
}

[data-aos=zoom-in-left] {
    -webkit-transform: translate3d(100px, 0, 0) scale(.6);
    transform: translate3d(100px, 0, 0) scale(.6)
}

[data-aos=zoom-out] {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

[data-aos=zoom-out-up] {
    -webkit-transform: translate3d(0, 100px, 0) scale(1.2);
    transform: translate3d(0, 100px, 0) scale(1.2)
}

[data-aos=zoom-out-down] {
    -webkit-transform: translate3d(0, -100px, 0) scale(1.2);
    transform: translate3d(0, -100px, 0) scale(1.2)
}

[data-aos=zoom-out-right] {
    -webkit-transform: translate3d(-100px, 0, 0) scale(1.2);
    transform: translate3d(-100px, 0, 0) scale(1.2)
}

[data-aos=zoom-out-left] {
    -webkit-transform: translate3d(100px, 0, 0) scale(1.2);
    transform: translate3d(100px, 0, 0) scale(1.2)
}

[data-aos^=slide][data-aos^=slide] {
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform
}

[data-aos^=slide][data-aos^=slide].aos-animate {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

[data-aos=slide-up] {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
}

[data-aos=slide-down] {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
}

[data-aos=slide-right] {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

[data-aos=slide-left] {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

[data-aos^=flip][data-aos^=flip] {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform
}

[data-aos=flip-left] {
    -webkit-transform: perspective(2500px) rotateY(-100deg);
    transform: perspective(2500px) rotateY(-100deg)
}

[data-aos=flip-left].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0)
}

[data-aos=flip-right] {
    -webkit-transform: perspective(2500px) rotateY(100deg);
    transform: perspective(2500px) rotateY(100deg)
}

[data-aos=flip-right].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0)
}

[data-aos=flip-up] {
    -webkit-transform: perspective(2500px) rotateX(-100deg);
    transform: perspective(2500px) rotateX(-100deg)
}

[data-aos=flip-up].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0)
}

[data-aos=flip-down] {
    -webkit-transform: perspective(2500px) rotateX(100deg);
    transform: perspective(2500px) rotateX(100deg)
}

[data-aos=flip-down].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0)
}

body.compensate-for-scrollbar {
    overflow: hidden;
	font-family: 'Josefin Sans', sans-serif;
}

.fancybox-active {
    height: auto
}

.fancybox-is-hidden {
    left: -9999px;
    margin: 0;
    position: absolute !important;
    top: -9999px;
    visibility: hidden
}

.fancybox-container {
    -webkit-backface-visibility: hidden;
    height: 100%;
    left: 0;
    outline: none;
    position: fixed;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    width: 100%;
    z-index: 99992
}

.fancybox-container * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.fancybox-bg,
.fancybox-inner,
.fancybox-outer,
.fancybox-stage {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.fancybox-outer {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto
}

.fancybox-bg {
    background: #1e1e1e;
    opacity: 0;
    -webkit-transition-duration: inherit;
    transition-duration: inherit;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: cubic-bezier(.47, 0, .74, .71);
    transition-timing-function: cubic-bezier(.47, 0, .74, .71)
}

.fancybox-is-open .fancybox-bg {
    opacity: .9;
    -webkit-transition-timing-function: cubic-bezier(.22, .61, .36, 1);
    transition-timing-function: cubic-bezier(.22, .61, .36, 1)
}

.fancybox-caption,
.fancybox-infobar,
.fancybox-navigation .fancybox-button,
.fancybox-toolbar {
    direction: ltr;
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity .25s ease, visibility 0s ease .25s;
    transition: opacity .25s ease, visibility 0s ease .25s;
    visibility: hidden;
    z-index: 99997
}

.fancybox-show-caption .fancybox-caption,
.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-nav .fancybox-navigation .fancybox-button,
.fancybox-show-toolbar .fancybox-toolbar {
    opacity: 1;
    -webkit-transition: opacity .25s ease 0s, visibility 0s ease 0s;
    transition: opacity .25s ease 0s, visibility 0s ease 0s;
    visibility: visible
}

.fancybox-infobar {
    color: #ccc;
    font-size: 13px;
    -webkit-font-smoothing: subpixel-antialiased;
    height: 44px;
    left: 0;
    line-height: 44px;
    min-width: 44px;
    mix-blend-mode: difference;
    padding: 0 10px;
    pointer-events: none;
    top: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.fancybox-toolbar {
    right: 0;
    top: 0
}

.fancybox-stage {
    direction: ltr;
    overflow: visible;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 99994
}

.fancybox-is-open .fancybox-stage {
    overflow: hidden
}

.fancybox-slide {
    -webkit-backface-visibility: hidden;
    display: none;
    height: 100%;
    left: 0;
    outline: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 44px;
    position: absolute;
    text-align: center;
    top: 0;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    white-space: normal;
    width: 100%;
    z-index: 99994
}

.fancybox-slide:before {
    content: "";
    display: inline-block;
    font-size: 0;
    height: 100%;
    vertical-align: middle;
    width: 0
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--current,
.fancybox-slide--next,
.fancybox-slide--previous {
    display: block
}

.fancybox-slide--image {
    overflow: hidden;
    padding: 44px 0
}

.fancybox-slide--image:before {
    display: none
}

.fancybox-slide--html {
    padding: 6px
}

.fancybox-content {
    background: #fff;
    display: inline-block;
    margin: 0;
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 44px;
    position: relative;
    text-align: left;
    vertical-align: middle
}

.fancybox-slide--image .fancybox-content {
    -webkit-animation-timing-function: cubic-bezier(.5, 0, .14, 1);
    animation-timing-function: cubic-bezier(.5, 0, .14, 1);
    -webkit-backface-visibility: hidden;
    background: transparent;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 99995
}

.fancybox-can-zoomOut .fancybox-content {
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.fancybox-can-zoomIn .fancybox-content {
    cursor: -webkit-zoom-in;
    cursor: zoom-in
}

.fancybox-can-pan .fancybox-content,
.fancybox-can-swipe .fancybox-content {
    cursor: -webkit-grab;
    cursor: grab
}

.fancybox-is-grabbing .fancybox-content {
    cursor: -webkit-grabbing;
    cursor: grabbing
}

.fancybox-container [data-selectable=true] {
    cursor: text
}

.fancybox-image,
.fancybox-spaceball {
    background: transparent;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%
}

.fancybox-spaceball {
    z-index: 1
}

.fancybox-slide--iframe .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--video .fancybox-content {
    height: 100%;
    overflow: visible;
    padding: 0;
    width: 100%
}

.fancybox-slide--video .fancybox-content {
    background: #000
}

.fancybox-slide--map .fancybox-content {
    background: #e5e3df
}

.fancybox-slide--iframe .fancybox-content {
    background: #fff
}

.fancybox-iframe,
.fancybox-video {
    background: transparent;
    border: 0;
    display: block;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%
}

.fancybox-iframe {
    left: 0;
    position: absolute;
    top: 0
}

.fancybox-error {
    background: #fff;
    cursor: default;
    max-width: 400px;
    padding: 40px;
    width: 100%
}

.fancybox-error p {
    color: #444;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0
}

.fancybox-button {
    background: rgba(30, 30, 30, .6);
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    height: 44px;
    margin: 0;
    padding: 10px;
    position: relative;
    -webkit-transition: color .2s;
    transition: color .2s;
    vertical-align: top;
    visibility: inherit;
    width: 44px
}

.fancybox-button,
.fancybox-button:link,
.fancybox-button:visited {
    color: #ccc
}

.fancybox-button:hover {
    color: #fff
}

.fancybox-button:focus {
    outline: none
}

.fancybox-button.fancybox-focus {
    outline: 1px dotted
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
    color: #888;
    cursor: default;
    outline: none
}

.fancybox-button div {
    height: 100%
}

.fancybox-button svg {
    display: block;
    height: 100%;
    overflow: visible;
    position: relative;
    width: 100%
}

.fancybox-button svg path {
    fill: currentColor;
    stroke-width: 0
}

.fancybox-button--fsenter svg:nth-child(2),
.fancybox-button--fsexit svg:first-child,
.fancybox-button--pause svg:first-child,
.fancybox-button--play svg:nth-child(2) {
    display: none
}

.fancybox-progress {
    background: #ff5268;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0;
    transform-origin: 0;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    z-index: 99998
}

.fancybox-close-small {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #ccc;
    cursor: pointer;
    opacity: .8;
    padding: 8px;
    position: absolute;
    right: -12px;
    top: -44px;
    z-index: 401
}

.fancybox-close-small:hover {
    color: #fff;
    opacity: 1
}

.fancybox-slide--html .fancybox-close-small {
    color: currentColor;
    padding: 10px;
    right: 0;
    top: 0
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
    overflow: hidden
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
    display: none
}

.fancybox-navigation .fancybox-button {
    background-clip: content-box;
    height: 100px;
    opacity: 0;
    position: absolute;
    top: calc(50% - 50px);
    width: 70px
}

.fancybox-navigation .fancybox-button div {
    padding: 7px
}

.fancybox-navigation .fancybox-button--arrow_left {
    left: 0;
    left: env(safe-area-inset-left);
    padding: 31px 26px 31px 6px
}

.fancybox-navigation .fancybox-button--arrow_right {
    padding: 31px 6px 31px 26px;
    right: 0;
    right: env(safe-area-inset-right)
}

.fancybox-caption {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .85)), color-stop(50%, rgba(0, 0, 0, .3)), color-stop(65%, rgba(0, 0, 0, .15)), color-stop(75.5%, rgba(0, 0, 0, .075)), color-stop(82.85%, rgba(0, 0, 0, .037)), color-stop(88%, rgba(0, 0, 0, .019)), to(transparent));
    background: linear-gradient(0deg, rgba(0, 0, 0, .85) 0, rgba(0, 0, 0, .3) 50%, rgba(0, 0, 0, .15) 65%, rgba(0, 0, 0, .075) 75.5%, rgba(0, 0, 0, .037) 82.85%, rgba(0, 0, 0, .019) 88%, transparent);
    bottom: 0;
    color: #eee;
    font-size: 14px;
    font-weight: 400;
    left: 0;
    line-height: 1.5;
    padding: 75px 44px 25px;
    pointer-events: none;
    right: 0;
    text-align: center;
    z-index: 99996
}

@supports (padding:max(0px)) {
    .fancybox-caption {
        padding: 75px max(44px, env(safe-area-inset-right)) max(25px, env(safe-area-inset-bottom)) max(44px, env(safe-area-inset-left))
    }
}

.fancybox-caption--separate {
    margin-top: -50px
}

.fancybox-caption__body {
    max-height: 50vh;
    overflow: auto;
    pointer-events: all
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
    color: #ccc;
    text-decoration: none
}

.fancybox-caption a:hover {
    color: #fff;
    text-decoration: underline
}

.fancybox-loading {
    -webkit-animation: fancybox-rotate 1s linear infinite;
    animation: fancybox-rotate 1s linear infinite;
    background: transparent;
    border: 4px solid #888;
    border-bottom-color: #fff;
    border-radius: 50%;
    height: 50px;
    left: 50%;
    margin: -25px 0 0 -25px;
    opacity: .7;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 50px;
    z-index: 99999
}

@-webkit-keyframes fancybox-rotate {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes fancybox-rotate {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.fancybox-animated {
    -webkit-transition-timing-function: cubic-bezier(0, 0, .25, 1);
    transition-timing-function: cubic-bezier(0, 0, .25, 1)
}

.fancybox-fx-slide.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.fancybox-fx-slide.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.fancybox-fx-slide.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.fancybox-fx-fade.fancybox-slide--next,
.fancybox-fx-fade.fancybox-slide--previous {
    opacity: 0;
    -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
    transition-timing-function: cubic-bezier(.19, 1, .22, 1)
}

.fancybox-fx-fade.fancybox-slide--current {
    opacity: 1
}

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5)
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: scale3d(.5, .5, .5);
    transform: scale3d(.5, .5, .5)
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.fancybox-fx-rotate.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn)
}

.fancybox-fx-rotate.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
}

.fancybox-fx-rotate.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
}

.fancybox-fx-circular.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0)
}

.fancybox-fx-circular.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, 0, 0)
}

.fancybox-fx-circular.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: scaleX(1) translateZ(0);
    transform: scaleX(1) translateZ(0)
}

.fancybox-fx-tube.fancybox-slide--previous {
    -webkit-transform: translate3d(-100%, 0, 0) scale(.1) skew(-10deg);
    transform: translate3d(-100%, 0, 0) scale(.1) skew(-10deg)
}

.fancybox-fx-tube.fancybox-slide--next {
    -webkit-transform: translate3d(100%, 0, 0) scale(.1) skew(10deg);
    transform: translate3d(100%, 0, 0) scale(.1) skew(10deg)
}

.fancybox-fx-tube.fancybox-slide--current {
    -webkit-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1)
}

@media (max-height:576px) {
    .fancybox-slide {
        padding-left: 6px;
        padding-right: 6px
    }
    .fancybox-slide--image {
        padding: 6px 0
    }
    .fancybox-close-small {
        right: -6px
    }
    .fancybox-slide--image .fancybox-close-small {
        background: #4e4e4e;
        color: #f2f4f6;
        height: 36px;
        opacity: 1;
        padding: 6px;
        right: 0;
        top: 0;
        width: 36px
    }
    .fancybox-caption {
        padding-left: 12px;
        padding-right: 12px
    }
    @supports (padding:max(0px)) {
        .fancybox-caption {
            padding-left: max(12px, env(safe-area-inset-left));
            padding-right: max(12px, env(safe-area-inset-right))
        }
    }
}

.fancybox-share {
    background: #f4f4f4;
    border-radius: 3px;
    max-width: 90%;
    padding: 30px;
    text-align: center
}

.fancybox-share h1 {
    color: #222;
    font-size: 35px;
    font-weight: 700;
    margin: 0 0 20px
}

.fancybox-share p {
    margin: 0;
    padding: 0
}

.fancybox-share__button {
    border: 0;
    border-radius: 3px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    margin: 0 5px 10px;
    min-width: 130px;
    padding: 0 15px;
    text-decoration: none;
    -webkit-transition: all .2s;
    transition: all .2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap
}

.fancybox-share__button:link,
.fancybox-share__button:visited {
    color: #fff
}

.fancybox-share__button:hover {
    text-decoration: none
}

.fancybox-share__button--fb {
    background: #3b5998
}

.fancybox-share__button--fb:hover {
    background: #344e86
}

.fancybox-share__button--pt {
    background: #bd081d
}

.fancybox-share__button--pt:hover {
    background: #aa0719
}

.fancybox-share__button--tw {
    background: #1da1f2
}

.fancybox-share__button--tw:hover {
    background: #0d95e8
}

.fancybox-share__button svg {
    height: 25px;
    margin-right: 7px;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 25px
}

.fancybox-share__button svg path {
    fill: #fff
}

.fancybox-share__input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0;
    color: #5d5b5b;
    font-size: 14px;
    margin: 10px 0 0;
    outline: none;
    padding: 10px 15px;
    width: 100%
}

.fancybox-thumbs {
    background: #ddd;
    bottom: 0;
    display: none;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding: 2px 2px 4px;
    position: absolute;
    right: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 0;
    width: 212px;
    z-index: 99995
}

.fancybox-thumbs-x {
    overflow-x: auto;
    overflow-y: hidden
}

.fancybox-show-thumbs .fancybox-thumbs {
    display: block
}

.fancybox-show-thumbs .fancybox-inner {
    right: 212px
}

.fancybox-thumbs__list {
    font-size: 0;
    height: 100%;
    list-style: none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    position: relative;
    white-space: nowrap;
    width: 100%
}

.fancybox-thumbs-x .fancybox-thumbs__list {
    overflow: hidden
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
    width: 7px
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
    background: #2a2a2a;
    border-radius: 10px
}

.fancybox-thumbs__list a {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, .1);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    float: left;
    height: 75px;
    margin: 2px;
    max-height: calc(100% - 8px);
    max-width: calc(50% - 4px);
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    width: 100px
}

.fancybox-thumbs__list a:before {
    border: 6px solid #ff5268;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all .2s cubic-bezier(.25, .46, .45, .94);
    transition: all .2s cubic-bezier(.25, .46, .45, .94);
    z-index: 99991
}

.fancybox-thumbs__list a:focus:before {
    opacity: .5
}

.fancybox-thumbs__list a.fancybox-thumbs-active:before {
    opacity: 1
}

@media (max-width:576px) {
    .fancybox-thumbs {
        width: 110px
    }
    .fancybox-show-thumbs .fancybox-inner {
        right: 110px
    }
    .fancybox-thumbs__list a {
        max-width: calc(100% - 10px)
    }
}

.slick-slider {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list,
.slick-slider {
    position: relative;
    display: block
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: none
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 200px;
}

.bf-testimonial-slick .slick-track {
    height: 150px;
}


.slick-track:after,
.slick-track:before {
    display: table;
    content: ""
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.beer-slider {
    display: inline-block;
    overflow: hidden;
    position: relative
}

.beer-slider *,
.beer-slider:after,
.beer-slider :after,
.beer-slider:before,
.beer-slider :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.beer-slider img,
.beer-slider svg {
    vertical-align: bottom
}

.beer-slider>* {
    height: 100%
}

.beer-slider>img {
    height: auto;
    max-width: 100%
}

.beer-reveal {
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: 50%;
    top: 0;
    -webkit-transition: opacity .35s;
    transition: opacity .35s;
    z-index: 1
}

.beer-reveal>:first-child {
    height: 100%;
    max-width: none;
    width: 200%
}

.beer-reveal>img:first-child {
    height: auto
}

.beer-range {
    -moz-appearance: none;
    -ms-touch-action: auto;
    -webkit-appearance: slider-horizontal !important;
    bottom: 0;
    cursor: pointer;
    height: 100%;
    left: -1px;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    touch-action: auto;
    width: calc(100% + 2px);
    z-index: 2
}

.beer-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 300vh
}

.beer-range::-moz-range-thumb {
    -webkit-appearance: none;
    height: 300vh
}

.beer-range::-ms-tooltip {
    display: none
}

.beer-handle {
    background: hsla(0, 0%, 100%, .5);
    border-radius: 50%;
    -webkit-box-shadow: 0 0 6px transparent;
    box-shadow: 0 0 6px transparent;
    color: #000;
    height: 48px;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -webkit-transition: background .3s, opacity .5s .25s, -webkit-box-shadow .3s;
    transition: background .3s, opacity .5s .25s, -webkit-box-shadow .3s;
    transition: background .3s, box-shadow .3s, opacity .5s .25s;
    transition: background .3s, box-shadow .3s, opacity .5s .25s, -webkit-box-shadow .3s;
    width: 48px;
    z-index: 2
}

.beer-handle:after,
.beer-handle:before {
    border-left: 2px solid;
    border-top: 2px solid;
    content: "";
    height: 10px;
    position: absolute;
    top: 50%;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    width: 10px
}

.beer-handle:before {
    left: 10px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.beer-handle:after {
    right: 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
}

.beer-range:focus~.beer-handle {
    background: hsla(0, 0%, 100%, .85);
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .4);
    box-shadow: 0 0 3px rgba(0, 0, 0, .4)
}

.beer-reveal[data-beer-label]:after,
.beer-slider[data-beer-label]:after {
    background: hsla(0, 0%, 100%, .75);
    border-radius: .125rem;
    content: attr(data-beer-label);
    line-height: 1;
    padding: .5rem;
    position: absolute;
    top: 1.5rem
}

.beer-slider[data-beer-label]:after {
    right: 1.5rem
}

.beer-reveal[data-beer-label]:after {
    left: 1.5rem
}

.beer-reveal[data-beer-label=""]:after,
.beer-slider[data-beer-label=""]:after {
    content: none
}

.beer-ready .beer-handle,
.beer-ready .beer-reveal {
    opacity: 1
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: inherit;
    font: inherit;
    vertical-align: baseline;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

html {
    height: 100%;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

body {
    width: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
}

article,
aside,
blockquote,
details,
div,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block
}

button,
form,
input,
label,
select,
textarea {
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: 'Josefin Sans', sans-serif;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    background: transparent;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-clip: padding-box;
    border-radius: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

button:focus,
form:focus,
input:focus,
label:focus,
select:focus,
textarea:focus {
    outline: none
}

select::-ms-expand {
    display: none
}

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden] {
    display: none
}

abbr[title] {
    border-bottom: 1px dotted
}

dfn {
    font-style: italic
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0
}

mark {
    background: #ff0;
    color: #000
}

code,
kbd,
pre,
samp {
    font-family: 'Josefin Sans', sans-serif;
    _font-family: courier new, monospace;
    font-size: 1rem
}

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word
}

small {
    font-size: .8rem;
    line-height: inherit
}

small,
sub,
sup {
    font-style: inherit;
    font-weight: inherit;
    font-family: inherit
}

sub,
sup {
    font-size: .6em;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic
}

svg:not(:root) {
    overflow: hidden
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

ol,
ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.errors,
.flash {
    background: #ff95a2;
    color: #fff;
    display: block;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0 0 25px;
    padding: 15px;
    text-align: center;
    text-transform: none
}

form.inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

form.inline .fluid {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

#IDX-main #IDX-action-buttons form.inline #IDX-submitBtn,
#IDX-main #IDX-action-buttons form.inline .IDX-btn-default:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons form.inline .IDX-btn-primary,
#IDX-main .IDX-form-actions form.inline #IDX-submitBtn,
#IDX-main .IDX-form-actions form.inline .IDX-btn-default:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions form.inline .IDX-btn-primary,
#IDX-registrationSignup #IDX-action-buttons form.inline #IDX-submitBtn,
#IDX-registrationSignup #IDX-action-buttons form.inline .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons form.inline .IDX-btn-primary,
#IDX-registrationSignup .IDX-form-actions form.inline #IDX-submitBtn,
#IDX-registrationSignup .IDX-form-actions form.inline .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions form.inline .IDX-btn-primary,
.idx #IDX-resultsRow #IDX-resultsContent form.inline .IDX-resultsCellAction,
form.inline #IDX-main #IDX-action-buttons #IDX-submitBtn,
form.inline #IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
form.inline #IDX-main #IDX-action-buttons .IDX-btn-primary,
form.inline #IDX-main .IDX-form-actions #IDX-submitBtn,
form.inline #IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
form.inline #IDX-main .IDX-form-actions .IDX-btn-primary,
form.inline #IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn,
form.inline #IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
form.inline #IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary,
form.inline #IDX-registrationSignup .IDX-form-actions #IDX-submitBtn,
form.inline #IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
form.inline #IDX-registrationSignup .IDX-form-actions .IDX-btn-primary,
form.inline .button,
form.inline .idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction,
form.inline button {
    background: #ff95a2;
    padding: 20px 25px;
    white-space: nowrap
}

#IDX-main #IDX-action-buttons form.inline #IDX-submitBtn:focus,
#IDX-main #IDX-action-buttons form.inline #IDX-submitBtn:hover,
#IDX-main #IDX-action-buttons form.inline .IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons form.inline .IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons form.inline .IDX-btn-primary:focus,
#IDX-main #IDX-action-buttons form.inline .IDX-btn-primary:hover,
#IDX-main .IDX-form-actions form.inline #IDX-submitBtn:focus,
#IDX-main .IDX-form-actions form.inline #IDX-submitBtn:hover,
#IDX-main .IDX-form-actions form.inline .IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions form.inline .IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions form.inline .IDX-btn-primary:focus,
#IDX-main .IDX-form-actions form.inline .IDX-btn-primary:hover,
#IDX-registrationSignup #IDX-action-buttons form.inline #IDX-submitBtn:focus,
#IDX-registrationSignup #IDX-action-buttons form.inline #IDX-submitBtn:hover,
#IDX-registrationSignup #IDX-action-buttons form.inline .IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons form.inline .IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons form.inline .IDX-btn-primary:focus,
#IDX-registrationSignup #IDX-action-buttons form.inline .IDX-btn-primary:hover,
#IDX-registrationSignup .IDX-form-actions form.inline #IDX-submitBtn:focus,
#IDX-registrationSignup .IDX-form-actions form.inline #IDX-submitBtn:hover,
#IDX-registrationSignup .IDX-form-actions form.inline .IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions form.inline .IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions form.inline .IDX-btn-primary:focus,
#IDX-registrationSignup .IDX-form-actions form.inline .IDX-btn-primary:hover,
.idx #IDX-resultsRow #IDX-resultsContent form.inline .IDX-resultsCellAction:focus,
.idx #IDX-resultsRow #IDX-resultsContent form.inline .IDX-resultsCellAction:hover,
form.inline #IDX-main #IDX-action-buttons #IDX-submitBtn:focus,
form.inline #IDX-main #IDX-action-buttons #IDX-submitBtn:hover,
form.inline #IDX-main #IDX-action-buttons .IDX-btn-default:focus:not(#IDX-submitBtn),
form.inline #IDX-main #IDX-action-buttons .IDX-btn-default:hover:not(#IDX-submitBtn),
form.inline #IDX-main #IDX-action-buttons .IDX-btn-primary:focus,
form.inline #IDX-main #IDX-action-buttons .IDX-btn-primary:hover,
form.inline #IDX-main .IDX-form-actions #IDX-submitBtn:focus,
form.inline #IDX-main .IDX-form-actions #IDX-submitBtn:hover,
form.inline #IDX-main .IDX-form-actions .IDX-btn-default:focus:not(#IDX-submitBtn),
form.inline #IDX-main .IDX-form-actions .IDX-btn-default:hover:not(#IDX-submitBtn),
form.inline #IDX-main .IDX-form-actions .IDX-btn-primary:focus,
form.inline #IDX-main .IDX-form-actions .IDX-btn-primary:hover,
form.inline #IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:focus,
form.inline #IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:hover,
form.inline #IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:focus:not(#IDX-submitBtn),
form.inline #IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:hover:not(#IDX-submitBtn),
form.inline #IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:focus,
form.inline #IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:hover,
form.inline #IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:focus,
form.inline #IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:hover,
form.inline #IDX-registrationSignup .IDX-form-actions .IDX-btn-default:focus:not(#IDX-submitBtn),
form.inline #IDX-registrationSignup .IDX-form-actions .IDX-btn-default:hover:not(#IDX-submitBtn),
form.inline #IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:focus,
form.inline #IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:hover,
form.inline .button:focus,
form.inline .button:hover,
form.inline .idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:focus,
form.inline .idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:hover,
form.inline button:focus,
form.inline button:hover {
    background: #ff95a2
}

#IDX-main #IDX-action-buttons form.inline #IDX-submitBtn:after,
#IDX-main #IDX-action-buttons form.inline .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-main #IDX-action-buttons form.inline .IDX-btn-primary:after,
#IDX-main .IDX-form-actions form.inline #IDX-submitBtn:after,
#IDX-main .IDX-form-actions form.inline .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-main .IDX-form-actions form.inline .IDX-btn-primary:after,
#IDX-registrationSignup #IDX-action-buttons form.inline #IDX-submitBtn:after,
#IDX-registrationSignup #IDX-action-buttons form.inline .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-registrationSignup #IDX-action-buttons form.inline .IDX-btn-primary:after,
#IDX-registrationSignup .IDX-form-actions form.inline #IDX-submitBtn:after,
#IDX-registrationSignup .IDX-form-actions form.inline .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-registrationSignup .IDX-form-actions form.inline .IDX-btn-primary:after,
.idx #IDX-resultsRow #IDX-resultsContent form.inline .IDX-resultsCellAction:after,
form.inline #IDX-main #IDX-action-buttons #IDX-submitBtn:after,
form.inline #IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):after,
form.inline #IDX-main #IDX-action-buttons .IDX-btn-primary:after,
form.inline #IDX-main .IDX-form-actions #IDX-submitBtn:after,
form.inline #IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):after,
form.inline #IDX-main .IDX-form-actions .IDX-btn-primary:after,
form.inline #IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:after,
form.inline #IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):after,
form.inline #IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:after,
form.inline #IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:after,
form.inline #IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):after,
form.inline #IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:after,
form.inline .button:after,
form.inline .idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:after,
form.inline button:after {
    display: none
}

form.inline input {
    background: none;
    border: 4px solid #ff95a2;
    border-right: none;
    color: #fff;
    letter-spacing: 2px;
    text-transform: none;
}

form.inline input:focus {
    border-color: #ff95a2
}

form label {
    display: block;
    font-size: 16px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
        letter-spacing: normal;
    line-height: 20px;
    margin: 0 0 20px
}

form label.required:after {
    content: " (required)";
    font-style: italic;
    font-weight: 400
}

form label>span {
    color: #000;
    display: block;
    margin: 0 0 5px;
    text-transform: uppercase;letter-spacing: normal;
}

form label>span i {
    color: #807f7f;
    font-style: normal;
    letter-spacing: 0;
    text-transform: none
}

form .icon {
    background: #fff;
    position: relative;
    width: 100%
}

form .icon i {
    color: #ff95a2;
    font-size: 20px;
    line-height: 56px;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 50px
}

form .icon input,
form .icon textarea {
    margin: 0;
    padding-right: 50px
}

form input,
form select,
form textarea {
    background: #fff;
    border: 1px solid #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #000;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 13px;
    font-weight: 300;
    height: 60px;
    line-height: 20px;
    padding: 14px 15px;
    width: 100%
}

form input:disabled,
form select:disabled,
form textarea:disabled {
    opacity: .3
}

form input:focus,
form select:focus,
form textarea:focus {
    border-color: #000
}

.alt form input,
.alt form select,
.alt form textarea {
    background: #fff
}

form select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: auto;
    min-height: 50px
}

form textarea {
        height: 104px;
    resize: none;
}

form .checkboxes {
    margin: 20px 0 0
}

form .checkboxes label {
    cursor: pointer;
    display: block
}

form .checkboxes input {
    all: initial;
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px
}

form .checkboxes input+span {
    color: #807f7f;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-family: 'Josefin Sans', sans-serif;
    line-height: 20px;
    padding: 0 0 0 35px;
    position: relative
}

form .checkboxes input+span:before {
    content: "";
    display: block;
    background: #fff;
    border: 2px solid #807f7f;
    height: 20px;
    left: 0;
    position: absolute;
    top: -2px;
    width: 20px
}

form .checkboxes input+span:after {
    background: #ff95a2;
    content: "";
    display: none;
    height: 16px;
    left: 4px;
    position: absolute;
    top: 2px;
    width: 16px
}

form .checkboxes input:checked+span:after {
    display: block
}

form .checkboxes label {
    all: initial
}

form .radios {
    margin: 20px 0 0
}

form .radios label {
    cursor: pointer;
    display: block
}

form .radios input {
    all: initial;
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px
}

form .radios input+span {
    color: #807f7f;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-family: 'Josefin Sans', sans-serif;
    line-height: 20px;
    padding: 0 0 0 35px;
    position: relative;
    text-transform: none
}

form .radios input+span:before {
    content: "";
    display: block;
    background: #fff;
    border: 2px solid #807f7f;
    border-radius: 50%;
    height: 20px;
    left: 0;
    position: absolute;
    top: -2px;
    width: 20px
}

form .radios input+span:after {
    background: #ff95a2;
    border-radius: 50%;
    content: "";
    display: none;
    height: 16px;
    left: 4px;
    position: absolute;
    top: 2px;
    width: 16px
}

form .radios input:checked+span:after {
    display: block
}

form .radios label {
    all: initial
}

form .quantity {
    position: relative;
    text-align: left;
    width: 100px
}

form .quantity input[type=number]::-webkit-inner-spin-button,
form .quantity input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

form .quantity input[type=number] {
    -moz-appearance: textfield
}

form .quantity .nav {
    position: absolute;
    right: 0;
    top: 0
}

#IDX-main #IDX-action-buttons form .quantity .nav #IDX-submitBtn,
#IDX-main #IDX-action-buttons form .quantity .nav .IDX-btn-default:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons form .quantity .nav .IDX-btn-primary,
#IDX-main .IDX-form-actions form .quantity .nav #IDX-submitBtn,
#IDX-main .IDX-form-actions form .quantity .nav .IDX-btn-default:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions form .quantity .nav .IDX-btn-primary,
#IDX-registrationSignup #IDX-action-buttons form .quantity .nav #IDX-submitBtn,
#IDX-registrationSignup #IDX-action-buttons form .quantity .nav .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons form .quantity .nav .IDX-btn-primary,
#IDX-registrationSignup .IDX-form-actions form .quantity .nav #IDX-submitBtn,
#IDX-registrationSignup .IDX-form-actions form .quantity .nav .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions form .quantity .nav .IDX-btn-primary,
.idx #IDX-resultsRow #IDX-resultsContent form .quantity .nav .IDX-resultsCellAction,
form .quantity .nav #IDX-main #IDX-action-buttons #IDX-submitBtn,
form .quantity .nav #IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
form .quantity .nav #IDX-main #IDX-action-buttons .IDX-btn-primary,
form .quantity .nav #IDX-main .IDX-form-actions #IDX-submitBtn,
form .quantity .nav #IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
form .quantity .nav #IDX-main .IDX-form-actions .IDX-btn-primary,
form .quantity .nav #IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn,
form .quantity .nav #IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
form .quantity .nav #IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary,
form .quantity .nav #IDX-registrationSignup .IDX-form-actions #IDX-submitBtn,
form .quantity .nav #IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
form .quantity .nav #IDX-registrationSignup .IDX-form-actions .IDX-btn-primary,
form .quantity .nav .button,
form .quantity .nav .idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction {
    background: #807f7f;
    border: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #807f7f;
    cursor: pointer;
    display: block;
    font-size: 20px;
    font-weight: 700;
    height: 28px;
    line-height: 28px;
    margin: 0;
    padding: 0;
    text-align: center;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    width: 24px
}

#IDX-main #IDX-action-buttons form .quantity .nav .up#IDX-submitBtn,
#IDX-main #IDX-action-buttons form .quantity .nav .up.IDX-btn-default:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons form .quantity .nav .up.IDX-btn-primary,
#IDX-main .IDX-form-actions form .quantity .nav .up#IDX-submitBtn,
#IDX-main .IDX-form-actions form .quantity .nav .up.IDX-btn-default:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions form .quantity .nav .up.IDX-btn-primary,
#IDX-registrationSignup #IDX-action-buttons form .quantity .nav .up#IDX-submitBtn,
#IDX-registrationSignup #IDX-action-buttons form .quantity .nav .up.IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons form .quantity .nav .up.IDX-btn-primary,
#IDX-registrationSignup .IDX-form-actions form .quantity .nav .up#IDX-submitBtn,
#IDX-registrationSignup .IDX-form-actions form .quantity .nav .up.IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions form .quantity .nav .up.IDX-btn-primary,
.idx #IDX-resultsRow #IDX-resultsContent form .quantity .nav .up.IDX-resultsCellAction,
form .quantity .nav #IDX-main #IDX-action-buttons .up#IDX-submitBtn,
form .quantity .nav #IDX-main #IDX-action-buttons .up.IDX-btn-default:not(#IDX-submitBtn),
form .quantity .nav #IDX-main #IDX-action-buttons .up.IDX-btn-primary,
form .quantity .nav #IDX-main .IDX-form-actions .up#IDX-submitBtn,
form .quantity .nav #IDX-main .IDX-form-actions .up.IDX-btn-default:not(#IDX-submitBtn),
form .quantity .nav #IDX-main .IDX-form-actions .up.IDX-btn-primary,
form .quantity .nav #IDX-registrationSignup #IDX-action-buttons .up#IDX-submitBtn,
form .quantity .nav #IDX-registrationSignup #IDX-action-buttons .up.IDX-btn-default:not(#IDX-submitBtn),
form .quantity .nav #IDX-registrationSignup #IDX-action-buttons .up.IDX-btn-primary,
form .quantity .nav #IDX-registrationSignup .IDX-form-actions .up#IDX-submitBtn,
form .quantity .nav #IDX-registrationSignup .IDX-form-actions .up.IDX-btn-default:not(#IDX-submitBtn),
form .quantity .nav #IDX-registrationSignup .IDX-form-actions .up.IDX-btn-primary,
form .quantity .nav .button.up,
form .quantity .nav .idx #IDX-resultsRow #IDX-resultsContent .up.IDX-resultsCellAction {
    height: 21px
}

form .checkbox {
    display: block;
    margin: 0 15px 0 0;
    position: relative
}

form .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

form .checkbox input:checked+label:after {
    color: #ff95a2;
    content: "\F00C";
    display: block;
    font-family: Font Awesome\ 5 Pro;
    font-size: 22px;
    font-weight: 700;
    height: 26px;
    left: 0;
    line-height: 28px;
    position: absolute;
    text-align: center;
    top: -2px;
    width: 28px
}

form .checkbox input+label {
    display: block;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
    padding: 0 0 0 38px;
    position: relative
}

form .checkbox input+label:before {
    background: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 28px;
    left: 0;
    position: absolute;
    top: -2px;
    width: 28px
}

form .file {
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1
}

form .file+label {
    border: 6px solid #000;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 20px;
    max-width: 80%;
    overflow: hidden;
    padding: 14px 24px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap
}

form .file+label:focus,
form .file+label:hover {
    background: #000
}

form .file+label:focus span,
form .file+label:hover span {
    color: #fff
}

form .file+label i {
    display: inline-block;
    font-size: 20px;
    line-height: 20px
}

form .file+label span {
    display: inline-block;
    margin: 0
}

:focus {
    outline: 0
}

body {
    background: #fff;
    padding-top: 100px
}

body * {
    font-size: auto
}

@media (max-width:1259px) {
    body {
        padding-top: 70px
    }
}

body.home {
    padding-top: 0
}

@media (max-width:1259px) {
    body.home {
        padding-top: 0px
    }
}

.invisible {
    visibility: hidden !important
}

.hidden {
    display: none !important
}

@media (max-width:1439px) {
    .hidden-xl {
        display: none !important
    }
}

@media (max-width:1259px) {
    .hidden-lg {
        display: none !important
    }
}

@media (max-width:959px) {
    .hidden-md {
        display: none !important
    }
}

@media (max-width:767px) {
    .hidden-sm {
        display: none !important
    }
}

.visible {
    display: block !important
}

@media (max-width:1439px) {
    .visible-xl {
        display: initial !important
    }
}

@media (max-width:1259px) {
    .visible-lg {
        display: initial !important
    }
}

@media (max-width:959px) {
    .visible-md {
        display: initial !important
    }
}

@media (max-width:767px) {
    .visible-sm {
        display: initial !important
    }
}

.float-left {
    float: left
}

.float-right {
    float: right
}

.text-inline {
    display: inline-block
}

.text-center {
    text-align: center
}

.text-vertical-center {
    vertical-align: middle
}

.text-left {
    text-align: left
}

@media (max-width:767px) {
    .text-left-sm {
        text-align: left !important
    }
}

@media (max-width:959px) {
    .text-left-md {
        text-align: left !important
    }
}

@media (max-width:1259px) {
    .text-left-lg {
        text-align: left !important
    }
}

@media (max-width:767px) {
    .text-center-sm {
        text-align: center !important
    }
}

@media (max-width:959px) {
    .text-center-md {
        text-align: center !important
    }
}

@media (max-width:1259px) {
    .text-center-lg {
        text-align: center !important
    }
}

.text-right {
    text-align: right
}

.text-uppercase {
    text-transform: uppercase
}

.text-transform-none {
    text-transform: none
}

.text-normal {
    font-weight: 400
}

.margin-none {
    margin: 0 !important
}

.margin-xs {
    margin-bottom: 10px !important;
    margin-top: 10px !important
}

.margin-sm {
    margin-bottom: 20px !important;
    margin-top: 20px !important
}

.margin-lg {
    margin-bottom: 80px !important;
    margin-top: 80px !important
}

.margin-bottom {
    margin-bottom: 40px !important
}

.margin-bottom-xs {
    margin-bottom: 10px !important
}

.margin-bottom-sm {
    margin-bottom: 20px !important
}

.margin-bottom-md {
    margin-bottom: 60px !important
}

.margin-bottom-lg {
    margin-bottom: 80px !important
}

.margin-bottom-xl {
    margin-bottom: 160px !important
}

.margin-top {
    margin-top: 40px !important
}

.margin-top-xs {
    margin-top: 10px !important
}

.margin-top-sm {
    margin-top: 20px !important
}

@media (max-width:767px) {
    .margin-top-sm-mobile {
        margin-top: 13.33333333px !important
    }
}

.margin-top-lg {
    margin-top: 80px !important
}

.margin-top-xl {
    margin-top: 160px !important
}

.margin {
    margin-bottom: 40px !important;
    margin-top: 40px !important
}

.margin-left {
    margin-left: 20px !important
}

.margin-right {
    margin-right: 20px !important
}

.padding-none {
    padding: 0 !important
}

.padding-sm {
    padding-bottom: 20px !important;
    padding-top: 20px !important
}

.padding-lg {
    padding-bottom: 80px !important;
    padding-top: 80px !important
}

.padding-bottom {
    padding-bottom: 40px !important
}

.padding-bottom-xs {
    padding-bottom: 10px !important
}

.padding-bottom-sm {
    padding-bottom: 20px !important
}

.padding-bottom-lg {
    padding-bottom: 80px !important
}

.padding-bottom-xl {
    padding-bottom: 160px !important
}

.padding-top {
    padding-top: 40px !important
}

.padding-top-sm {
    padding-top: 20px !important
}

.padding-top-lg {
    padding-top: 80px !important
}

.padding-top-xl {
    padding-top: 160px !important
}

#IDX-main #IDX-action-buttons #IDX-submitBtn,
#IDX-main #IDX-action-buttons #IDX-submitBtn:after,
#IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-main #IDX-action-buttons .IDX-btn-primary,
#IDX-main #IDX-action-buttons .IDX-btn-primary:after,
#IDX-main #IDX-action-buttons .outline#IDX-submitBtn i,
#IDX-main #IDX-action-buttons .outline.IDX-btn-default:not(#IDX-submitBtn) i,
#IDX-main #IDX-action-buttons .outline.IDX-btn-primary i,
#IDX-main .IDX-form-actions #IDX-submitBtn,
#IDX-main .IDX-form-actions #IDX-submitBtn:after,
#IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-main .IDX-form-actions .IDX-btn-primary,
#IDX-main .IDX-form-actions .IDX-btn-primary:after,
#IDX-main .IDX-form-actions .outline#IDX-submitBtn i,
#IDX-main .IDX-form-actions .outline.IDX-btn-default:not(#IDX-submitBtn) i,
#IDX-main .IDX-form-actions .outline.IDX-btn-primary i,
#IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn,
#IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:after,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:after,
#IDX-registrationSignup #IDX-action-buttons .outline#IDX-submitBtn i,
#IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-default:not(#IDX-submitBtn) i,
#IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-primary i,
#IDX-registrationSignup .IDX-form-actions #IDX-submitBtn,
#IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:after,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-primary,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:after,
#IDX-registrationSignup .IDX-form-actions .outline#IDX-submitBtn i,
#IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-default:not(#IDX-submitBtn) i,
#IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-primary i,
.accordion-section .accordions section .close,
.accordion-section .hero.full article,
.action .content,
.action .content .inline-button,
.button,
.button.outline i,
.button:after,
.card,
.card .content i,
.card:before,
.chip .icon,
.hover-transition,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:after,
.idx #IDX-resultsRow #IDX-resultsContent .outline.IDX-resultsCellAction i,
.image-slider .control,
.inline-button,
.inline-button i,
.section.red:after,
.services-card,
.services-card .image div h5 i,
.services-card:before,
.tile,
.tile-wrapper:before,
.tile .content,
.tile .content h5 i,
.tile:before,
a,
body:after,
button,
button.outline i,
button:after,
form .file+label,
form .file+label span,
header,
header .hamburger span,
header .hamburger span:after,
header .hamburger span:before,
header .primary,
header .primary>ul>li .dropdown,
header .primary>ul>li .dropdown:after,
header .primary>ul>li .toggle,
header .primary>ul>li:last-of-type a:after,
header .primary>ul>li:last-of-type a i,
header .primary>ul>li>a,
section.red:after {
    -webkit-transition: all .2s cubic-bezier(.645, .045, .355, 1);
    transition: all .2s cubic-bezier(.645, .045, .355, 1)
}

.line-transition {
    -webkit-transition: all .8s cubic-bezier(.645, .045, .355, 1);
    transition: all .8s cubic-bezier(.645, .045, .355, 1)
}

.line-transition-delay,
.line-transition-delay-long {
    -webkit-transition: all .8s cubic-bezier(.645, .045, .355, 1);
    transition: all .8s cubic-bezier(.645, .045, .355, 1);
    -webkit-transition-delay: .2s;
    transition-delay: .2s
}

.align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
}

img {
    display: inline-block;
    height: auto;
    max-width: 100%
}

img.full {
    width: 90%
}

img.block {
    display: block
}

img.shadow {
    -webkit-box-shadow: -30px -30px 0 #ff95a2;
    box-shadow: -30px -30px 0 #ff95a2;
    margin: 30px 0 0
}

@media (max-width:767px) {
    img.shadow {
        -webkit-box-shadow: -10px -10px 0 #ff95a2;
        box-shadow: -10px -10px 0 #ff95a2;
        margin: 10px 0 0
    }
}

img.rounded {
    border-radius: 0 50px 0 50px
}

img.circle {
    border-radius: 50%
}

a:focus .image-hover:before,
a:hover .image-hover:before {
    opacity: .75
}

a:focus .image-hover .overlay,
a:hover .image-hover .overlay {
    border-bottom: 5px solid #fff;
    border-top: 5px solid #fff;
    opacity: 1;
    padding: 10px 15px
}

iframe {
    width: 100%
}

::selection {
    background: rgba(255, 66, 42, .5)
}

::-moz-selection {
    background: rgba(255, 66, 42, .5)
}

body {
    color: #000;
    font-family: 'Josefin Sans', sans-serif !important;
}

b,
strong {
    font-weight: 700
}

em,
i {
    font-style: italic
}

a {
    color: #000;
    overflow-wrap: break-word;
    text-decoration: underline;
    -webkit-text-decoration-color: #ff95a2;
    text-decoration-color: #ff95a2;
    text-underline-position: under;
    word-wrap: break-word
}

a:focus,
a:hover {
    text-decoration: none;
    -webkit-text-decoration-color: #000;
    text-decoration-color: #000
}

a.inverse-small {
    color: #807f7f;
    display: inline-block;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 3px;
    line-height: 24px;
    text-decoration: none;
    text-transform: uppercase
}

a.inverse-small:focus,
a.inverse-small:hover {
    color: #fff
}

.h1,
.idx #IDX-Subheader h1,
h1 {
    font-size: 90px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 100px;
    margin: 0 0 30px;
    padding: 0;
    text-transform: none
}

@media (max-width:767px) {
    .h1,
    .idx #IDX-Subheader h1,
    h1 {
        font-size: 50px;
        line-height: 60px
    }
}

.h1:before,
.idx #IDX-Subheader h1:before,
h1:before {
    display: none
}

.h2,
h2 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 65px;
    margin: 0 0 30px;
    padding: 0;
    text-transform: none;
}

@media (max-width:767px) {
    .h2,
    h2 {
        font-size: 40px;
        line-height: 50px
    }
}

.h2.text-left em,
.h2.text-left i,
h2.text-left em,
h2.text-left i {
    text-align: right
}

.h2:before,
h2:before {
    display: none
}

.h2 b,
.h2 strong,
h2 b,
h2 strong {
    color: #ff95a2
}

.h2 em,
.h2 i,
h2 em,
h2 i {
    display: block;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 3px;
    line-height: 24px;
    margin: 0 0 20px;
    position: relative;
    text-transform: uppercase
}

.h2 em:before,
.h2 i:before,
h2 em:before,
h2 i:before {
    background: none;
    color: #ff95a2;
    content: "\F067";
    display: inline-block;
    font-family: Font Awesome\ 5 Pro;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    margin: 0 6px 0 0;
    position: relative;
    top: -3px
}

#IDX-main .IDX-pageHeader,
#IDX-main .IDX-propertyTypeHeader,
#IDX-registrationSignup .IDX-pageHeader,
#IDX-registrationSignup .IDX-propertyTypeHeader,
.h3,
h3 {
        font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 30px;
    margin: 0 0 20px;
    padding: 0;
    text-transform: none;
}

@media (max-width:767px) {
    #IDX-main .IDX-pageHeader,
    #IDX-main .IDX-propertyTypeHeader,
    #IDX-registrationSignup .IDX-pageHeader,
    #IDX-registrationSignup .IDX-propertyTypeHeader,
    .h3,
    h3 {
        font-size: 30px;
        line-height: 40px
    }
}

#IDX-main .IDX-pageHeader:before,
#IDX-main .IDX-propertyTypeHeader:before,
#IDX-registrationSignup .IDX-pageHeader:before,
#IDX-registrationSignup .IDX-propertyTypeHeader:before,
.h3:before,
h3:before {
    display: none
}

.h4,
h4 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 3px;
    line-height: 24px;
    margin: 0 0 20px;
    padding: 0 0 0 20px;
    position: relative;
    text-transform: uppercase
}

.h4:before,
h4:before {
    background: #ff95a2;
    content: "";
    display: block;
    height: 8px;
    left: 0;
    position: absolute;
    top: 8px;
    width: 8px
}

.h5,
h5 {
        font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 25px;
    margin: 0 0 10px;
    padding: 0;
    text-transform: none;
}

.h5:before,
h5:before {
    display: none
}

.h5.bold-highlight b,
.h5.bold-highlight strong,
h5.bold-highlight b,
h5.bold-highlight strong {
    color: #ff95a2;
    font-weight: 600
}

.h6,
h6 {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 3px;
    line-height: 24px;
    margin: 0 0 10px;
    padding: 0;
    text-transform: uppercase
}

.h6:before,
h6:before {
    display: none
}

blockquote p {
    font-size: 32px;
    line-height: 56px;
    margin: 0 0 60px
}

@media (max-width:767px) {
    blockquote p {
        font-size: 24px;
        line-height: 40px
    }
}

cite {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 24px;
    padding: 0 0 0 20px;
    position: relative;
    text-transform: uppercase
}

cite:before {
    background: #ff95a2;
    content: "";
    display: block;
    height: 8px;
    left: 0;
    position: absolute;
    top: 5px;
    width: 8px
}

p {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 30px;
}

p.alt {
    font-family: nobel, sans-serif
}

ol {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    line-height: 36px;
    list-style-type: decimal;
    margin: 0 0 30px
}

ol li {
    margin: 0 0 5px 20px
}

ol ol,
ol ul {
    margin: 0
}

ul {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    line-height: 36px;
    list-style-type: disc;
    margin: 0 0 30px
}

ul.text-highlights {
    color: #000
}

ul.text-highlights li {
    display: block;
    font-family: 'Josefin Sans', sans-serif;
    margin: 30px 0;
    padding: 0 0 0 40px;
    position: relative
}

ul.text-highlights li span {
    background: #ff95a2;
    border-radius: 50%;
    color: #fff;
    display: block;
    font-size: 15px;
    height: 30px;
    left: 0;
    line-height: 30px;
    margin-top: -15px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 30px
}

ul li {
    margin: 0 0 5px 20px
}

ul ol,
ul ul {
    margin: 0
}

.icon {
    font-family: Font Awesome\ 5 Pro;
    font-weight: 400
}

.text-red {
    color: #ff95a2
}

.callout {
        border-right: 10px solid #000;
    margin: 0 0 0 auto;
    overflow: hidden;
    padding: 10px 10px 10px 0;
    text-align: right;
    width: 100%;
}

@media (max-width:767px) {
    .callout {
        width: 100%
    }
}

.black .callout {
    border-color: #fff
}

.callout h2 {
    color: #ff95a2;
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 0px;
    font-weight: bold;
}

.action {
    display: block;
    position: relative
}

.action:focus .content,
.action:hover .content {
    opacity: 1
}

.action:focus .content .inline-button,
.action:hover .content .inline-button {
    margin-top: 0
}

#IDX-main #IDX-action-buttons .action #IDX-submitBtn,
#IDX-main #IDX-action-buttons .action .IDX-btn-default:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .action .IDX-btn-primary,
#IDX-main .IDX-form-actions .action #IDX-submitBtn,
#IDX-main .IDX-form-actions .action .IDX-btn-default:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .action .IDX-btn-primary,
#IDX-registrationSignup #IDX-action-buttons .action #IDX-submitBtn,
#IDX-registrationSignup #IDX-action-buttons .action .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .action .IDX-btn-primary,
#IDX-registrationSignup .IDX-form-actions .action #IDX-submitBtn,
#IDX-registrationSignup .IDX-form-actions .action .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .action .IDX-btn-primary,
.action #IDX-main #IDX-action-buttons #IDX-submitBtn,
.action #IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
.action #IDX-main #IDX-action-buttons .IDX-btn-primary,
.action #IDX-main .IDX-form-actions #IDX-submitBtn,
.action #IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
.action #IDX-main .IDX-form-actions .IDX-btn-primary,
.action #IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn,
.action #IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
.action #IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary,
.action #IDX-registrationSignup .IDX-form-actions #IDX-submitBtn,
.action #IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
.action #IDX-registrationSignup .IDX-form-actions .IDX-btn-primary,
.action .button,
.action .idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction,
.idx #IDX-resultsRow #IDX-resultsContent .action .IDX-resultsCellAction {
    position: absolute;
    right: -40px;
    top: -40px;
    z-index: 1
}

@media (max-width:767px) {
    #IDX-main #IDX-action-buttons .action #IDX-submitBtn,
    #IDX-main #IDX-action-buttons .action .IDX-btn-default:not(#IDX-submitBtn),
    #IDX-main #IDX-action-buttons .action .IDX-btn-primary,
    #IDX-main .IDX-form-actions .action #IDX-submitBtn,
    #IDX-main .IDX-form-actions .action .IDX-btn-default:not(#IDX-submitBtn),
    #IDX-main .IDX-form-actions .action .IDX-btn-primary,
    #IDX-registrationSignup #IDX-action-buttons .action #IDX-submitBtn,
    #IDX-registrationSignup #IDX-action-buttons .action .IDX-btn-default:not(#IDX-submitBtn),
    #IDX-registrationSignup #IDX-action-buttons .action .IDX-btn-primary,
    #IDX-registrationSignup .IDX-form-actions .action #IDX-submitBtn,
    #IDX-registrationSignup .IDX-form-actions .action .IDX-btn-default:not(#IDX-submitBtn),
    #IDX-registrationSignup .IDX-form-actions .action .IDX-btn-primary,
    .action #IDX-main #IDX-action-buttons #IDX-submitBtn,
    .action #IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
    .action #IDX-main #IDX-action-buttons .IDX-btn-primary,
    .action #IDX-main .IDX-form-actions #IDX-submitBtn,
    .action #IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
    .action #IDX-main .IDX-form-actions .IDX-btn-primary,
    .action #IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn,
    .action #IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
    .action #IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary,
    .action #IDX-registrationSignup .IDX-form-actions #IDX-submitBtn,
    .action #IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
    .action #IDX-registrationSignup .IDX-form-actions .IDX-btn-primary,
    .action .button,
    .action .idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction,
    .idx #IDX-resultsRow #IDX-resultsContent .action .IDX-resultsCellAction {
        right: 20px;
        top: -20px
    }
}

.action .content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: rgba(0, 0, 0, .6);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.action .content .inline-button {
    color: #fff;
    margin-top: 40px
}

.text-border-left {
    border-left: 10px solid #000;
    color: #ff95a2;
    padding: 10px 0 10px 40px
}

.text-border-right {
    border-right: 10px solid #000;
    color: #ff95a2;
    padding: 10px 40px 10px 0
}

.text-inset {
    margin: 0 0 -25px;
    padding-right: 90px;
    position: relative;
    z-index: 1
}

.other {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 60px;
    -moz-column-gap: 60px;
    column-gap: 60px
}

@media (max-width:767px) {
    .other {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1
    }
}

.other p {
    margin: 0
}

.flourish {
    display: block;
    margin: 20px 0 0;
    position: relative
}

@media (max-width:959px) {
    .flourish {
        margin-left: 20px
    }
}

.flourish.bottom-left {
    margin: 0 0 10px
}

.flourish.bottom-left .flourish-square {
    bottom: -30px;
    top: auto;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
}

@media (max-width:959px) {
    .flourish.bottom-left .flourish-square {
        bottom: -10px
    }
}

.flourish.bottom {
    margin: 0 0 10px
}

.flourish.bottom .flourish-square {
    bottom: -30px;
    left: auto;
    right: -35px;
    top: auto;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

@media (max-width:959px) {
    .flourish.bottom .flourish-square {
        bottom: -10px;
        right: -15px
    }
}

@media (max-width:959px) {
    .flourish.right {
        margin-left: 0;
        margin-right: 20px
    }
}

.flourish.right .flourish-square {
    left: auto;
    right: -35px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1)
}

@media (max-width:959px) {
    .flourish.right .flourish-square {
        right: -15px
    }
}

.flourish.text {
    display: inline-block
}

.flourish.text .flourish-square {
    left: -55px;
    top: -55px
}

@media (max-width:959px) {
    .flourish.text .flourish-square {
        left: -25px;
        top: -15px
    }
}

.flourish img {
    position: relative;
    z-index: 1
}

.flourish .flourish-square {
    display: block;
    height: 90px;
    left: -35px;
    position: absolute;
    top: -35px;
    width: 90px;
    z-index: -1
}

@media (max-width:959px) {
    .flourish .flourish-square {
        height: 45px;
        left: -15px;
        width: 45px;
        top: -15px
    }
}

.flourish .flourish-square svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.loading {
    /* background: url(./../img/loading.svg); */
    background-size: cover;
    display: inline-block;
    height: 60px;
    width: 60px
}

#IDX-main #IDX-action-buttons #IDX-submitBtn,
#IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .IDX-btn-primary,
#IDX-main .IDX-form-actions #IDX-submitBtn,
#IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .IDX-btn-primary,
#IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary,
#IDX-registrationSignup .IDX-form-actions #IDX-submitBtn,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .IDX-btn-primary,
.button,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction,
button {
    background: #ff95a2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px !important;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 16px;
    padding: 10px 30px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase !important;
    letter-spacing: normal;
    padding-bottom: 10px;
}

#IDX-main #IDX-action-buttons #IDX-submitBtn:after,
#IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-main #IDX-action-buttons .IDX-btn-primary:after,
#IDX-main .IDX-form-actions #IDX-submitBtn:after,
#IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-main .IDX-form-actions .IDX-btn-primary:after,
#IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:after,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:after,
#IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:after,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:after,
.button:after,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:after,
button:after {
    background: #e78293;
    -webkit-clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

#IDX-main #IDX-action-buttons #IDX-submitBtn span,
#IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn) span,
#IDX-main #IDX-action-buttons .IDX-btn-primary span,
#IDX-main .IDX-form-actions #IDX-submitBtn span,
#IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn) span,
#IDX-main .IDX-form-actions .IDX-btn-primary span,
#IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn span,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn) span,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary span,
#IDX-registrationSignup .IDX-form-actions #IDX-submitBtn span,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn) span,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-primary span,
.button span,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction span,
button span {
    position: relative;
    z-index: 1
}

#IDX-main #IDX-action-buttons #IDX-submitBtn:focus,
#IDX-main #IDX-action-buttons #IDX-submitBtn:hover,
#IDX-main #IDX-action-buttons .IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .IDX-btn-primary:focus,
#IDX-main #IDX-action-buttons .IDX-btn-primary:hover,
#IDX-main .IDX-form-actions #IDX-submitBtn:focus,
#IDX-main .IDX-form-actions #IDX-submitBtn:hover,
#IDX-main .IDX-form-actions .IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .IDX-btn-primary:focus,
#IDX-main .IDX-form-actions .IDX-btn-primary:hover,
#IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:focus,
#IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:hover,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:focus,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:hover,
#IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:focus,
#IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:hover,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:focus,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:hover,
.button:focus,
.button:hover,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:focus,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:hover,
button:focus,
button:hover {
    text-decoration: none !important
}

#IDX-main #IDX-action-buttons #IDX-submitBtn:focus:after,
#IDX-main #IDX-action-buttons #IDX-submitBtn:hover:after,
#IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):focus:after,
#IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):hover:after,
#IDX-main #IDX-action-buttons .IDX-btn-primary:focus:after,
#IDX-main #IDX-action-buttons .IDX-btn-primary:hover:after,
#IDX-main .IDX-form-actions #IDX-submitBtn:focus:after,
#IDX-main .IDX-form-actions #IDX-submitBtn:hover:after,
#IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):focus:after,
#IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):hover:after,
#IDX-main .IDX-form-actions .IDX-btn-primary:focus:after,
#IDX-main .IDX-form-actions .IDX-btn-primary:hover:after,
#IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:focus:after,
#IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:hover:after,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):focus:after,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):hover:after,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:focus:after,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:hover:after,
#IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:focus:after,
#IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:hover:after,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):focus:after,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):hover:after,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:focus:after,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:hover:after,
.button:focus:after,
.button:hover:after,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:focus:after,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:hover:after,
button:focus:after,
button:hover:after {
    -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%)
}

#IDX-main #IDX-action-buttons #IDX-submitBtn:first-child,
#IDX-main #IDX-action-buttons .IDX-btn-default:first-child:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .IDX-btn-primary:first-child,
#IDX-main .IDX-form-actions #IDX-submitBtn:first-child,
#IDX-main .IDX-form-actions .IDX-btn-default:first-child:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .IDX-btn-primary:first-child,
#IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:first-child,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:first-child:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:first-child,
#IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:first-child,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:first-child:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:first-child,
.button:first-child,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:first-child,
button:first-child {
    margin-right: 10px
}

@media (max-width:767px) {
    #IDX-main #IDX-action-buttons #IDX-submitBtn:first-child,
    #IDX-main #IDX-action-buttons .IDX-btn-default:first-child:not(#IDX-submitBtn),
    #IDX-main #IDX-action-buttons .IDX-btn-primary:first-child,
    #IDX-main .IDX-form-actions #IDX-submitBtn:first-child,
    #IDX-main .IDX-form-actions .IDX-btn-default:first-child:not(#IDX-submitBtn),
    #IDX-main .IDX-form-actions .IDX-btn-primary:first-child,
    #IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:first-child,
    #IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:first-child:not(#IDX-submitBtn),
    #IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:first-child,
    #IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:first-child,
    #IDX-registrationSignup .IDX-form-actions .IDX-btn-default:first-child:not(#IDX-submitBtn),
    #IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:first-child,
    .button:first-child,
    .idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:first-child,
    button:first-child {
        margin-right: 0;
        margin-bottom: 10px
    }
}

#IDX-main #IDX-action-buttons #IDX-submitBtn:last-child,
#IDX-main #IDX-action-buttons .IDX-btn-default:last-child:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .IDX-btn-primary:last-child,
#IDX-main .IDX-form-actions #IDX-submitBtn:last-child,
#IDX-main .IDX-form-actions .IDX-btn-default:last-child:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .IDX-btn-primary:last-child,
#IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:last-child,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:last-child:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:last-child,
#IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:last-child,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:last-child:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:last-child,
.button:last-child,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:last-child,
button:last-child {
    margin-bottom: 0;
    margin-right: 0
}

#IDX-main #IDX-action-buttons .square#IDX-submitBtn,
#IDX-main #IDX-action-buttons .square.IDX-btn-default:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .square.IDX-btn-primary,
#IDX-main .IDX-form-actions .square#IDX-submitBtn,
#IDX-main .IDX-form-actions .square.IDX-btn-default:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .square.IDX-btn-primary,
#IDX-registrationSignup #IDX-action-buttons .square#IDX-submitBtn,
#IDX-registrationSignup #IDX-action-buttons .square.IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .square.IDX-btn-primary,
#IDX-registrationSignup .IDX-form-actions .square#IDX-submitBtn,
#IDX-registrationSignup .IDX-form-actions .square.IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .square.IDX-btn-primary,
.button.square,
.idx #IDX-resultsRow #IDX-resultsContent .square.IDX-resultsCellAction,
button.square {
    font-size: 25px;
    line-height: 90px;
    padding: 0;
    height: 90px;
    text-align: center;
    width: 90px
}

#IDX-main #IDX-action-buttons .square#IDX-submitBtn:after,
#IDX-main #IDX-action-buttons .square.IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-main #IDX-action-buttons .square.IDX-btn-primary:after,
#IDX-main .IDX-form-actions .square#IDX-submitBtn:after,
#IDX-main .IDX-form-actions .square.IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-main .IDX-form-actions .square.IDX-btn-primary:after,
#IDX-registrationSignup #IDX-action-buttons .square#IDX-submitBtn:after,
#IDX-registrationSignup #IDX-action-buttons .square.IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-registrationSignup #IDX-action-buttons .square.IDX-btn-primary:after,
#IDX-registrationSignup .IDX-form-actions .square#IDX-submitBtn:after,
#IDX-registrationSignup .IDX-form-actions .square.IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-registrationSignup .IDX-form-actions .square.IDX-btn-primary:after,
.button.square:after,
.idx #IDX-resultsRow #IDX-resultsContent .square.IDX-resultsCellAction:after,
button.square:after {
    display: none
}

#IDX-main #IDX-action-buttons .square#IDX-submitBtn:focus,
#IDX-main #IDX-action-buttons .square#IDX-submitBtn:hover,
#IDX-main #IDX-action-buttons .square.IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .square.IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .square.IDX-btn-primary:focus,
#IDX-main #IDX-action-buttons .square.IDX-btn-primary:hover,
#IDX-main .IDX-form-actions .square#IDX-submitBtn:focus,
#IDX-main .IDX-form-actions .square#IDX-submitBtn:hover,
#IDX-main .IDX-form-actions .square.IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .square.IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .square.IDX-btn-primary:focus,
#IDX-main .IDX-form-actions .square.IDX-btn-primary:hover,
#IDX-registrationSignup #IDX-action-buttons .square#IDX-submitBtn:focus,
#IDX-registrationSignup #IDX-action-buttons .square#IDX-submitBtn:hover,
#IDX-registrationSignup #IDX-action-buttons .square.IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .square.IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .square.IDX-btn-primary:focus,
#IDX-registrationSignup #IDX-action-buttons .square.IDX-btn-primary:hover,
#IDX-registrationSignup .IDX-form-actions .square#IDX-submitBtn:focus,
#IDX-registrationSignup .IDX-form-actions .square#IDX-submitBtn:hover,
#IDX-registrationSignup .IDX-form-actions .square.IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .square.IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .square.IDX-btn-primary:focus,
#IDX-registrationSignup .IDX-form-actions .square.IDX-btn-primary:hover,
.button.square:focus,
.button.square:hover,
.idx #IDX-resultsRow #IDX-resultsContent .square.IDX-resultsCellAction:focus,
.idx #IDX-resultsRow #IDX-resultsContent .square.IDX-resultsCellAction:hover,
button.square:focus,
button.square:hover {
    background: #ff95a2;
    color: #fff
}

#IDX-main #IDX-action-buttons .outline#IDX-submitBtn,
#IDX-main #IDX-action-buttons .outline.IDX-btn-default:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .outline.IDX-btn-primary,
#IDX-main .IDX-form-actions .outline#IDX-submitBtn,
#IDX-main .IDX-form-actions .outline.IDX-btn-default:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .outline.IDX-btn-primary,
#IDX-registrationSignup #IDX-action-buttons .outline#IDX-submitBtn,
#IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-primary,
#IDX-registrationSignup .IDX-form-actions .outline#IDX-submitBtn,
#IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-primary,
.button.outline,
.idx #IDX-resultsRow #IDX-resultsContent .outline.IDX-resultsCellAction,
button.outline {
    background: none;
    border: 6px solid #ff95a2;
    color: #ff95a2;
    padding: 14px 24px
}

#IDX-main #IDX-action-buttons .outline#IDX-submitBtn:after,
#IDX-main #IDX-action-buttons .outline.IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-main #IDX-action-buttons .outline.IDX-btn-primary:after,
#IDX-main .IDX-form-actions .outline#IDX-submitBtn:after,
#IDX-main .IDX-form-actions .outline.IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-main .IDX-form-actions .outline.IDX-btn-primary:after,
#IDX-registrationSignup #IDX-action-buttons .outline#IDX-submitBtn:after,
#IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-primary:after,
#IDX-registrationSignup .IDX-form-actions .outline#IDX-submitBtn:after,
#IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-primary:after,
.button.outline:after,
.idx #IDX-resultsRow #IDX-resultsContent .outline.IDX-resultsCellAction:after,
button.outline:after {
    display: none
}

#IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):hover,
#IDX-main #IDX-action-buttons .outline#IDX-submitBtn:focus,
#IDX-main #IDX-action-buttons .outline#IDX-submitBtn:hover,
#IDX-main #IDX-action-buttons .outline.IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .outline.IDX-btn-primary:focus,
#IDX-main #IDX-action-buttons .outline.IDX-btn-primary:hover,
#IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):hover,
#IDX-main .IDX-form-actions .outline#IDX-submitBtn:focus,
#IDX-main .IDX-form-actions .outline#IDX-submitBtn:hover,
#IDX-main .IDX-form-actions .outline.IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .outline.IDX-btn-primary:focus,
#IDX-main .IDX-form-actions .outline.IDX-btn-primary:hover,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):hover,
#IDX-registrationSignup #IDX-action-buttons .outline#IDX-submitBtn:focus,
#IDX-registrationSignup #IDX-action-buttons .outline#IDX-submitBtn:hover,
#IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-primary:focus,
#IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-primary:hover,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):hover,
#IDX-registrationSignup .IDX-form-actions .outline#IDX-submitBtn:focus,
#IDX-registrationSignup .IDX-form-actions .outline#IDX-submitBtn:hover,
#IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-primary:focus,
#IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-primary:hover,
.button.outline:focus,
.button.outline:hover,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:hover,
.idx #IDX-resultsRow #IDX-resultsContent .outline.IDX-resultsCellAction:focus,
button.outline:focus,
button.outline:hover {
    background: #ff95a2
}

#IDX-main #IDX-action-buttons .hero .outline#IDX-submitBtn,
#IDX-main #IDX-action-buttons .hero .outline.IDX-btn-default:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .hero .outline.IDX-btn-primary,
#IDX-main #IDX-action-buttons .red .outline#IDX-submitBtn,
#IDX-main #IDX-action-buttons .red .outline.IDX-btn-default:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .red .outline.IDX-btn-primary,
#IDX-main .IDX-form-actions .hero .outline#IDX-submitBtn,
#IDX-main .IDX-form-actions .hero .outline.IDX-btn-default:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .hero .outline.IDX-btn-primary,
#IDX-main .IDX-form-actions .red .outline#IDX-submitBtn,
#IDX-main .IDX-form-actions .red .outline.IDX-btn-default:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .red .outline.IDX-btn-primary,
#IDX-registrationSignup #IDX-action-buttons .hero .outline#IDX-submitBtn,
#IDX-registrationSignup #IDX-action-buttons .hero .outline.IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .hero .outline.IDX-btn-primary,
#IDX-registrationSignup #IDX-action-buttons .red .outline#IDX-submitBtn,
#IDX-registrationSignup #IDX-action-buttons .red .outline.IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .red .outline.IDX-btn-primary,
#IDX-registrationSignup .IDX-form-actions .hero .outline#IDX-submitBtn,
#IDX-registrationSignup .IDX-form-actions .hero .outline.IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .hero .outline.IDX-btn-primary,
#IDX-registrationSignup .IDX-form-actions .red .outline#IDX-submitBtn,
#IDX-registrationSignup .IDX-form-actions .red .outline.IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .red .outline.IDX-btn-primary,
.hero #IDX-main #IDX-action-buttons .outline#IDX-submitBtn,
.hero #IDX-main #IDX-action-buttons .outline.IDX-btn-default:not(#IDX-submitBtn),
.hero #IDX-main #IDX-action-buttons .outline.IDX-btn-primary,
.hero #IDX-main .IDX-form-actions .outline#IDX-submitBtn,
.hero #IDX-main .IDX-form-actions .outline.IDX-btn-default:not(#IDX-submitBtn),
.hero #IDX-main .IDX-form-actions .outline.IDX-btn-primary,
.hero #IDX-registrationSignup #IDX-action-buttons .outline#IDX-submitBtn,
.hero #IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-default:not(#IDX-submitBtn),
.hero #IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-primary,
.hero #IDX-registrationSignup .IDX-form-actions .outline#IDX-submitBtn,
.hero #IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-default:not(#IDX-submitBtn),
.hero #IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-primary,
.hero .button.outline,
.hero .idx #IDX-resultsRow #IDX-resultsContent .outline.IDX-resultsCellAction,
.hero button.outline,
.idx #IDX-resultsRow #IDX-resultsContent .hero .outline.IDX-resultsCellAction,
.idx #IDX-resultsRow #IDX-resultsContent .red .outline.IDX-resultsCellAction,
.red #IDX-main #IDX-action-buttons .outline#IDX-submitBtn,
.red #IDX-main #IDX-action-buttons .outline.IDX-btn-default:not(#IDX-submitBtn),
.red #IDX-main #IDX-action-buttons .outline.IDX-btn-primary,
.red #IDX-main .IDX-form-actions .outline#IDX-submitBtn,
.red #IDX-main .IDX-form-actions .outline.IDX-btn-default:not(#IDX-submitBtn),
.red #IDX-main .IDX-form-actions .outline.IDX-btn-primary,
.red #IDX-registrationSignup #IDX-action-buttons .outline#IDX-submitBtn,
.red #IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-default:not(#IDX-submitBtn),
.red #IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-primary,
.red #IDX-registrationSignup .IDX-form-actions .outline#IDX-submitBtn,
.red #IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-default:not(#IDX-submitBtn),
.red #IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-primary,
.red .button.outline,
.red .idx #IDX-resultsRow #IDX-resultsContent .outline.IDX-resultsCellAction,
.red button.outline {
    color: #fff
}

#IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .outline.inverse#IDX-submitBtn,
#IDX-main #IDX-action-buttons .outline.inverse.IDX-btn-primary,
#IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .outline.inverse#IDX-submitBtn,
#IDX-main .IDX-form-actions .outline.inverse.IDX-btn-primary,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .outline.inverse#IDX-submitBtn,
#IDX-registrationSignup #IDX-action-buttons .outline.inverse.IDX-btn-primary,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .outline.inverse#IDX-submitBtn,
#IDX-registrationSignup .IDX-form-actions .outline.inverse.IDX-btn-primary,
.button.outline.inverse,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction,
button.outline.inverse {
    border-color: #000
}

#IDX-main #IDX-action-buttons .IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-main #IDX-action-buttons .outline.inverse#IDX-submitBtn:focus,
#IDX-main #IDX-action-buttons .outline.inverse#IDX-submitBtn:hover,
#IDX-main #IDX-action-buttons .outline.inverse.IDX-btn-primary:focus,
#IDX-main #IDX-action-buttons .outline.inverse.IDX-btn-primary:hover,
#IDX-main .IDX-form-actions .IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .outline.inverse#IDX-submitBtn:focus,
#IDX-main .IDX-form-actions .outline.inverse#IDX-submitBtn:hover,
#IDX-main .IDX-form-actions .outline.inverse.IDX-btn-primary:focus,
#IDX-main .IDX-form-actions .outline.inverse.IDX-btn-primary:hover,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .outline.inverse#IDX-submitBtn:focus,
#IDX-registrationSignup #IDX-action-buttons .outline.inverse#IDX-submitBtn:hover,
#IDX-registrationSignup #IDX-action-buttons .outline.inverse.IDX-btn-primary:focus,
#IDX-registrationSignup #IDX-action-buttons .outline.inverse.IDX-btn-primary:hover,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:focus:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:hover:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .outline.inverse#IDX-submitBtn:focus,
#IDX-registrationSignup .IDX-form-actions .outline.inverse#IDX-submitBtn:hover,
#IDX-registrationSignup .IDX-form-actions .outline.inverse.IDX-btn-primary:focus,
#IDX-registrationSignup .IDX-form-actions .outline.inverse.IDX-btn-primary:hover,
.button.outline.inverse:focus,
.button.outline.inverse:hover,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:focus,
.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:hover,
button.outline.inverse:focus,
button.outline.inverse:hover {
    background: #000
}

#IDX-main #IDX-action-buttons .outline#IDX-submitBtn i,
#IDX-main #IDX-action-buttons .outline.IDX-btn-default:not(#IDX-submitBtn) i,
#IDX-main #IDX-action-buttons .outline.IDX-btn-primary i,
#IDX-main .IDX-form-actions .outline#IDX-submitBtn i,
#IDX-main .IDX-form-actions .outline.IDX-btn-default:not(#IDX-submitBtn) i,
#IDX-main .IDX-form-actions .outline.IDX-btn-primary i,
#IDX-registrationSignup #IDX-action-buttons .outline#IDX-submitBtn i,
#IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-default:not(#IDX-submitBtn) i,
#IDX-registrationSignup #IDX-action-buttons .outline.IDX-btn-primary i,
#IDX-registrationSignup .IDX-form-actions .outline#IDX-submitBtn i,
#IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-default:not(#IDX-submitBtn) i,
#IDX-registrationSignup .IDX-form-actions .outline.IDX-btn-primary i,
.button.outline i,
.idx #IDX-resultsRow #IDX-resultsContent .outline.IDX-resultsCellAction i,
button.outline i {
    padding-left: 5px
}

.inline-button {
    color: #fff;
    display: inline-block;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
        letter-spacing: normal;
    line-height: 24px;
    text-decoration: none;
    text-transform: uppercase
}

.inline-button:first-child {
    margin-right: 40px
}

@media (max-width:767px) {
    .inline-button:first-child {
        margin-right: 0;
        margin-bottom: 10px
    }
}

.inline-button:last-child {
    margin-bottom: 0;
    margin-right: 0
}

.inline-button.alt {
    color: #000
}

.black .inline-button.alt,
.inline-button.alt.inverse {
    color: #fff
}

.inline-button.alt i {
    color: #ff95a2
}

.inline-button:focus,
.inline-button:hover {
    text-decoration: none !important
}

.inline-button:focus i,
.inline-button:hover i {
    right: -5px
}

.inline-button i {
    color: #000;
    position: relative;
    right: 0
}

.load-buttons {
    /* background: url(./../img/loading.svg) no-repeat -9999px -9999px */
}

.unit-1-1 {
    width: 100%
}

.unit-0-1 {
    width: 0
}

.unit-1-2,
.unit-2-4,
.unit-3-6,
.unit-4-8,
.unit-5-10,
.unit-6-12,
.unit-8-16 {
    width: 49%
}

.unit-1-3,
.unit-2-6,
.unit-3-9,
.unit-4-12 {
    width: 32.33333333%
}

.unit-2-3,
.unit-4-6,
.unit-6-9,
.unit-8-12 {
    width: 66.66666667%
}

.unit-1-4,
.unit-2-8,
.unit-3-12,
.unit-4-16 {
    width: 25%
}

.unit-3-4,
.unit-6-8,
.unit-9-12,
.unit-12-16 {
    width: 75%
}

.unit-1-5,
.unit-2-10 {
    width: 20%
}

.unit-2-5,
.unit-4-10 {
    width: 39%
}

.unit-3-5,
.unit-6-10 {
    width: 60%
}

.unit-4-5,
.unit-8-10 {
    width: 80%
}

.unit-1-6,
.unit-2-12 {
    width: 16.66666667%
}

.unit-5-6,
.unit-10-12 {
    width: 83.33333333%
}

.unit-1-8,
.unit-2-16 {
    width: 12.5%
}

.unit-3-8,
.unit-6-16 {
    width: 37.5%
}

.unit-5-8,
.unit-10-16 {
    width: 62.5%
}

.unit-7-8,
.unit-14-16 {
    width: 87.5%
}

.unit-1-9 {
    width: 11.11111111%
}

.unit-2-9 {
    width: 22.22222222%
}

.unit-4-9 {
    width: 44.44444444%
}

.unit-5-9 {
    width: 55.55555556%
}

.unit-7-9 {
    width: 77.77777778%
}

.unit-8-9 {
    width: 88.88888889%
}

.unit-1-10 {
    width: 10%
}

.unit-3-10 {
    width: 30%
}

.unit-7-10 {
    width: 70%
}

.unit-9-10 {
    width: 90%
}

.unit-1-12 {
    width: 8.33333333%
}

.unit-5-12 {
    width: 41.66666667%
}

.unit-7-12 {
    width: 58.33333333%
}

.unit-11-12 {
    width: 91.66666667%
}

.unit-1-16 {
    width: 6.25%
}

.unit-3-16 {
    width: 18.75%
}

.unit-5-16 {
    width: 31.25%
}

.unit-7-16 {
    width: 43.75%
}

.unit-9-16 {
    width: 56.25%
}

.unit-11-16 {
    width: 68.75%
}

.unit-13-16 {
    width: 81.25%
}

.unit-15-16 {
    width: 93.75%
}

@media (max-width:1439px) {
    .unit-1-1-xl {
        width: 100%
    }
    .unit-0-1-xl {
        width: 0
    }
    .unit-1-2-xl,
    .unit-2-4-xl,
    .unit-3-6-xl,
    .unit-4-8-xl,
    .unit-5-10-xl,
    .unit-6-12-xl,
    .unit-8-16-xl {
        width: 50%
    }
    .unit-1-3-xl,
    .unit-2-6-xl,
    .unit-3-9-xl,
    .unit-4-12-xl {
        width: 33.33333333%
    }
    .unit-2-3-xl,
    .unit-4-6-xl,
    .unit-6-9-xl,
    .unit-8-12-xl {
        width: 66.66666667%
    }
    .unit-1-4-xl,
    .unit-2-8-xl,
    .unit-3-12-xl,
    .unit-4-16-xl {
        width: 25%
    }
    .unit-3-4-xl,
    .unit-6-8-xl,
    .unit-9-12-xl,
    .unit-12-16-xl {
        width: 75%
    }
    .unit-1-5-xl,
    .unit-2-10-xl {
        width: 20%
    }
    .unit-2-5-xl,
    .unit-4-10-xl {
        width: 40%
    }
    .unit-3-5-xl,
    .unit-6-10-xl {
        width: 60%
    }
    .unit-4-5-xl,
    .unit-8-10-xl {
        width: 80%
    }
    .unit-1-6-xl,
    .unit-2-12-xl {
        width: 16.66666667%
    }
    .unit-5-6-xl,
    .unit-10-12-xl {
        width: 83.33333333%
    }
    .unit-1-8-xl,
    .unit-2-16-xl {
        width: 12.5%
    }
    .unit-3-8-xl,
    .unit-6-16-xl {
        width: 37.5%
    }
    .unit-5-8-xl,
    .unit-10-16-xl {
        width: 62.5%
    }
    .unit-7-8-xl,
    .unit-14-16-xl {
        width: 87.5%
    }
    .unit-1-9-xl {
        width: 11.11111111%
    }
    .unit-2-9-xl {
        width: 22.22222222%
    }
    .unit-4-9-xl {
        width: 44.44444444%
    }
    .unit-5-9-xl {
        width: 55.55555556%
    }
    .unit-7-9-xl {
        width: 77.77777778%
    }
    .unit-8-9-xl {
        width: 88.88888889%
    }
    .unit-1-10-xl {
        width: 10%
    }
    .unit-3-10-xl {
        width: 30%
    }
    .unit-7-10-xl {
        width: 70%
    }
    .unit-9-10-xl {
        width: 90%
    }
    .unit-1-12-xl {
        width: 8.33333333%
    }
    .unit-5-12-xl {
        width: 41.66666667%
    }
    .unit-7-12-xl {
        width: 58.33333333%
    }
    .unit-11-12-xl {
        width: 91.66666667%
    }
    .unit-1-16-xl {
        width: 6.25%
    }
    .unit-3-16-xl {
        width: 18.75%
    }
    .unit-5-16-xl {
        width: 31.25%
    }
    .unit-7-16-xl {
        width: 43.75%
    }
    .unit-9-16-xl {
        width: 56.25%
    }
    .unit-11-16-xl {
        width: 68.75%
    }
    .unit-13-16-xl {
        width: 81.25%
    }
    .unit-15-16-xl {
        width: 93.75%
    }
}

@media (max-width:1259px) {
    .unit-1-1-lg {
        width: 100%
    }
    .unit-0-1-lg {
        width: 0
    }
    .unit-1-2-lg,
    .unit-2-4-lg,
    .unit-3-6-lg,
    .unit-4-8-lg,
    .unit-5-10-lg,
    .unit-6-12-lg,
    .unit-8-16-lg {
        width: 50%
    }
    .unit-1-3-lg,
    .unit-2-6-lg,
    .unit-3-9-lg,
    .unit-4-12-lg {
        width: 33.33333333%
    }
    .unit-2-3-lg,
    .unit-4-6-lg,
    .unit-6-9-lg,
    .unit-8-12-lg {
        width: 66.66666667%
    }
    .unit-1-4-lg,
    .unit-2-8-lg,
    .unit-3-12-lg,
    .unit-4-16-lg {
        width: 25%
    }
    .unit-3-4-lg,
    .unit-6-8-lg,
    .unit-9-12-lg,
    .unit-12-16-lg {
        width: 75%
    }
    .unit-1-5-lg,
    .unit-2-10-lg {
        width: 20%
    }
    .unit-2-5-lg,
    .unit-4-10-lg {
        width: 40%
    }
    .unit-3-5-lg,
    .unit-6-10-lg {
        width: 60%
    }
    .unit-4-5-lg,
    .unit-8-10-lg {
        width: 80%
    }
    .unit-1-6-lg,
    .unit-2-12-lg {
        width: 16.66666667%
    }
    .unit-5-6-lg,
    .unit-10-12-lg {
        width: 83.33333333%
    }
    .unit-1-8-lg,
    .unit-2-16-lg {
        width: 12.5%
    }
    .unit-3-8-lg,
    .unit-6-16-lg {
        width: 37.5%
    }
    .unit-5-8-lg,
    .unit-10-16-lg {
        width: 62.5%
    }
    .unit-7-8-lg,
    .unit-14-16-lg {
        width: 87.5%
    }
    .unit-1-9-lg {
        width: 11.11111111%
    }
    .unit-2-9-lg {
        width: 22.22222222%
    }
    .unit-4-9-lg {
        width: 44.44444444%
    }
    .unit-5-9-lg {
        width: 55.55555556%
    }
    .unit-7-9-lg {
        width: 77.77777778%
    }
    .unit-8-9-lg {
        width: 88.88888889%
    }
    .unit-1-10-lg {
        width: 10%
    }
    .unit-3-10-lg {
        width: 30%
    }
    .unit-7-10-lg {
        width: 70%
    }
    .unit-9-10-lg {
        width: 90%
    }
    .unit-1-12-lg {
        width: 8.33333333%
    }
    .unit-5-12-lg {
        width: 41.66666667%
    }
    .unit-7-12-lg {
        width: 58.33333333%
    }
    .unit-11-12-lg {
        width: 91.66666667%
    }
    .unit-1-16-lg {
        width: 6.25%
    }
    .unit-3-16-lg {
        width: 18.75%
    }
    .unit-5-16-lg {
        width: 31.25%
    }
    .unit-7-16-lg {
        width: 43.75%
    }
    .unit-9-16-lg {
        width: 56.25%
    }
    .unit-11-16-lg {
        width: 68.75%
    }
    .unit-13-16-lg {
        width: 81.25%
    }
    .unit-15-16-lg {
        width: 93.75%
    }
}

@media (max-width:959px) {
    .unit-1-1-md {
        width: 100%
    }
    .unit-0-1-md {
        width: 0
    }
    .unit-1-2-md,
    .unit-2-4-md,
    .unit-3-6-md,
    .unit-4-8-md,
    .unit-5-10-md,
    .unit-6-12-md,
    .unit-8-16-md {
        width: 50%
    }
    .unit-1-3-md,
    .unit-2-6-md,
    .unit-3-9-md,
    .unit-4-12-md {
        width: 33.33333333%
    }
    .unit-2-3-md,
    .unit-4-6-md,
    .unit-6-9-md,
    .unit-8-12-md {
        width: 66.66666667%
    }
    .unit-1-4-md,
    .unit-2-8-md,
    .unit-3-12-md,
    .unit-4-16-md {
        width: 25%
    }
    .unit-3-4-md,
    .unit-6-8-md,
    .unit-9-12-md,
    .unit-12-16-md {
        width: 75%
    }
    .unit-1-5-md,
    .unit-2-10-md {
        width: 20%
    }
    .unit-2-5-md,
    .unit-4-10-md {
        width: 40%
    }
    .unit-3-5-md,
    .unit-6-10-md {
        width: 60%
    }
    .unit-4-5-md,
    .unit-8-10-md {
        width: 80%
    }
    .unit-1-6-md,
    .unit-2-12-md {
        width: 16.66666667%
    }
    .unit-5-6-md,
    .unit-10-12-md {
        width: 83.33333333%
    }
    .unit-1-8-md,
    .unit-2-16-md {
        width: 12.5%
    }
    .unit-3-8-md,
    .unit-6-16-md {
        width: 37.5%
    }
    .unit-5-8-md,
    .unit-10-16-md {
        width: 62.5%
    }
    .unit-7-8-md,
    .unit-14-16-md {
        width: 87.5%
    }
    .unit-1-9-md {
        width: 11.11111111%
    }
    .unit-2-9-md {
        width: 22.22222222%
    }
    .unit-4-9-md {
        width: 44.44444444%
    }
    .unit-5-9-md {
        width: 55.55555556%
    }
    .unit-7-9-md {
        width: 77.77777778%
    }
    .unit-8-9-md {
        width: 88.88888889%
    }
    .unit-1-10-md {
        width: 10%
    }
    .unit-3-10-md {
        width: 30%
    }
    .unit-7-10-md {
        width: 70%
    }
    .unit-9-10-md {
        width: 90%
    }
    .unit-1-12-md {
        width: 8.33333333%
    }
    .unit-5-12-md {
        width: 41.66666667%
    }
    .unit-7-12-md {
        width: 58.33333333%
    }
    .unit-11-12-md {
        width: 91.66666667%
    }
    .unit-1-16-md {
        width: 6.25%
    }
    .unit-3-16-md {
        width: 18.75%
    }
    .unit-5-16-md {
        width: 31.25%
    }
    .unit-7-16-md {
        width: 43.75%
    }
    .unit-9-16-md {
        width: 56.25%
    }
    .unit-11-16-md {
        width: 68.75%
    }
    .unit-13-16-md {
        width: 81.25%
    }
    .unit-15-16-md {
        width: 93.75%
    }
}

@media (max-width:767px) {
    .unit-1-1-sm {
        width: 100%
    }
    .unit-0-1-sm {
        width: 0
    }
    .unit-1-2-sm,
    .unit-2-4-sm,
    .unit-3-6-sm,
    .unit-4-8-sm,
    .unit-5-10-sm,
    .unit-6-12-sm,
    .unit-8-16-sm {
        width: 50%
    }
    .unit-1-3-sm,
    .unit-2-6-sm,
    .unit-3-9-sm,
    .unit-4-12-sm {
        width: 33.33333333%
    }
    .unit-2-3-sm,
    .unit-4-6-sm,
    .unit-6-9-sm,
    .unit-8-12-sm {
        width: 66.66666667%
    }
    .unit-1-4-sm,
    .unit-2-8-sm,
    .unit-3-12-sm,
    .unit-4-16-sm {
        width: 25%
    }
    .unit-3-4-sm,
    .unit-6-8-sm,
    .unit-9-12-sm,
    .unit-12-16-sm {
        width: 75%
    }
    .unit-1-5-sm,
    .unit-2-10-sm {
        width: 20%
    }
    .unit-2-5-sm,
    .unit-4-10-sm {
        width: 40%
    }
    .unit-3-5-sm,
    .unit-6-10-sm {
        width: 60%
    }
    .unit-4-5-sm,
    .unit-8-10-sm {
        width: 80%
    }
    .unit-1-6-sm,
    .unit-2-12-sm {
        width: 16.66666667%
    }
    .unit-5-6-sm,
    .unit-10-12-sm {
        width: 83.33333333%
    }
    .unit-1-8-sm,
    .unit-2-16-sm {
        width: 12.5%
    }
    .unit-3-8-sm,
    .unit-6-16-sm {
        width: 37.5%
    }
    .unit-5-8-sm,
    .unit-10-16-sm {
        width: 62.5%
    }
    .unit-7-8-sm,
    .unit-14-16-sm {
        width: 87.5%
    }
    .unit-1-9-sm {
        width: 11.11111111%
    }
    .unit-2-9-sm {
        width: 22.22222222%
    }
    .unit-4-9-sm {
        width: 44.44444444%
    }
    .unit-5-9-sm {
        width: 55.55555556%
    }
    .unit-7-9-sm {
        width: 77.77777778%
    }
    .unit-8-9-sm {
        width: 88.88888889%
    }
    .unit-1-10-sm {
        width: 10%
    }
    .unit-3-10-sm {
        width: 30%
    }
    .unit-7-10-sm {
        width: 70%
    }
    .unit-9-10-sm {
        width: 90%
    }
    .unit-1-12-sm {
        width: 8.33333333%
    }
    .unit-5-12-sm {
        width: 41.66666667%
    }
    .unit-7-12-sm {
        width: 58.33333333%
    }
    .unit-11-12-sm {
        width: 91.66666667%
    }
    .unit-1-16-sm {
        width: 6.25%
    }
    .unit-3-16-sm {
        width: 18.75%
    }
    .unit-5-16-sm {
        width: 31.25%
    }
    .unit-7-16-sm {
        width: 43.75%
    }
    .unit-9-16-sm {
        width: 56.25%
    }
    .unit-11-16-sm {
        width: 68.75%
    }
    .unit-13-16-sm {
        width: 81.25%
    }
    .unit-15-16-sm {
        width: 93.75%
    }
}

.grid {
    display: block;
    padding: 0;
    margin-left: 0px;
    margin-right: 0px;
    text-align: left;
    letter-spacing: -.31em;
    text-rendering: optimizespeed
}

.grid.grid-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.grid.grid-xs {
    margin-left: 0;
    margin-right: 0
}

.grid.grid-sm {
    margin-left: -10px;
    margin-right: -10px
}

.grid.grid-lg {
    margin-left: -40px;
    margin-right: -40px
}

@media (max-width:767px) {
    .grid.grid-lg {
        margin-left: -20px;
        margin-right: -20px
    }
}

.grid,
.opera:-o-prefocus {
    word-spacing: -.43em
}

.grid>.grid {
    overflow: hidden;
    margin-right: 0;
    margin-left: 0
}

.grid>[class*=unit-] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
    padding: 0 10px;
    text-align: left;
    vertical-align: top;
    letter-spacing: normal;
    word-spacing: normal;
    text-rendering: auto
}

.grid.grid-sm>[class*=unit-] {
    padding: 0 10px
}

.grid.grid-xs>[class*=unit-] {
    padding: 0
}

.grid.grid-lg>[class*=unit-] {
    padding: 0 15px
}

@media (max-width:767px) {
    .grid.grid-lg>[class*=unit-] {
        padding: 0 20px
    }
}

.grid-center {
    text-align: center
}

.grid-left {
    text-align: left
}

.grid-right {
    text-align: right
}

.grid-mirror {
    direction: rtl
}

.grid-mirror>div {
    direction: ltr
}

.grid .unit-center {
    display: block;
    margin: 0 auto
}

.grid .unit-vertical-center {
    vertical-align: middle
}

.grid .unit-vertical-bottom {
    vertical-align: bottom
}

.grid.grid-flex .unit-vertical-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.grid.grid-flex .unit-vertical-bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.unit-left {
    display: block;
    margin-right: auto
}

.unit-right {
    display: block;
    margin-left: auto;
}

.accordion-section {
    /*height: calc(100% - 100px);*/
    position: relative;
}

.accordion-section.has-active .hero article {
    opacity: 1 !important;
}

@media (max-width:1259px) {
    .accordion-section.has-active .hero article {
        opacity: 1 !important
    }
}

.accordion-section.has-active .accordions {
    width: 100%
}

.accordion-section .hero.full {
    height: 100vh;
}
.accordion-section .hero.full article {
    opacity: 1
}

.accordion-section .accordions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    height: 100%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2;
}

@media (max-width:1259px) {
    .accordion-section .accordions {
        display: block;
        position: static
    }
}

.accordion-section .accordions section {
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(190deg 5% 47% / 86%);
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 60px;
    min-width: 60px;
    overflow: hidden;
    padding: 0;
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
    font-family: 'Josefin Sans', sans-serif;
}

@media (max-width:1259px) {
    .accordion-section .accordions section {
        -webkit-transition: none;
        transition: none;
        height: 60px;
        max-height: 60px;
        max-width: 100%;
        width: 100%
    }
}

.accordion-section .accordions section:nth-of-type(2) {
    background-color: hsla(193, 9%, 71%, .85)
}

.accordion-section .accordions section:nth-of-type(3) {
    background-color: rgba(218, 228, 230, .85)
}

.accordion-section .accordions section:nth-of-type(4) {
    background-color: rgba(235, 240, 242, .95)
}

.accordion-section .accordions section.active {
    cursor: default;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    width: 9999px;
    background: hsl(193deg 9% 71%);
}

@media (max-width:1259px) {
    .accordion-section .accordions section.active {
        height: auto;
        max-height: 9999px
    }
}

.accordion-section .accordions section.active h5 {
    display: none
}

@media (max-width:1259px) {
    .accordion-section .accordions section.active h5 {
        display: block
    }
}

.accordion-section .accordions section.active .close {
    opacity: 1
}

@media (max-width:1259px) {
    .accordion-section .accordions section.active .close i:first-of-type {
        display: block
    }
}





.accordion-section .accordions section.active article {
    display: block
}

.accordion-section .accordions section .close {
    color: #ff95a2;
    cursor: pointer;
    font-size: 30px;
    line-height: 30px;
    opacity: 0;
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 1
}

@media (max-width:1259px) {
    .accordion-section .accordions section .close {
        font-size: 24px;
        opacity: 1;
        right: 20px;
        top: 18px
    }
}

@media (max-width:1259px) {
    .accordion-section .accordions section .close i:first-of-type {
        display: none
    }
}


@media (max-width:1259px) {
    .accordion-section .accordions section .close i:last-of-type {
        display: block
    }
}

.accordion-section .accordions section h5 {
    color: #000;
    font-size: 18px;
    left: 50%;
    line-height: 24px;
    pointer-events: none;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    white-space: nowrap
}

@media (max-width:1259px) {
    .accordion-section .accordions section h5 {
        left: 20px;
        top: 20px;
        -webkit-transform: none;
        transform: none
    }
}

.accordion-section .accordions section h5 i {
    display: block
}

.accordion-section .accordions section article {
    display: none;
    min-width: 60vw;
    padding: 100px 0
}

.card {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 25px;
    position: relative;
    text-decoration: none
}

@media (max-width:767px) {
    .card {
        display: block;
        margin: 0 auto;
        max-width: 90%
    }
}

.card:focus,
.card:hover {
    text-decoration: none
}

.card:focus:before,
.card:hover:before {
    border-width: 8px;
    height: calc(100% + 40px);
    left: -20px;
    top: -20px;
    width: calc(100% + 40px)
}

.card:focus .content i,
.card:hover .content i {
    padding-left: 10px
}

.card:before {
    background: #dae4e6;
    border: 0 solid #000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%;
    z-index: -1
}

.card .image {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    position: relative
}

.card .image .icon {
    background: #ff95a2;
    color: #fff;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 40px
}

.card .content {
    color: #000;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    font-family: 'Josefin Sans', sans-serif;
    padding-left: 20px
}

@media (max-width:767px) {
    .card .content {
        padding: 0
    }
}

.card .content p {
    font-family: 'Josefin Sans', sans-serif;
    margin: 0
}

.card .content i {
    color: #ff95a2;
    font-size: 14px;
    padding-left: 5px;
    white-space: nowrap
}

.carousel {
    overflow: hidden;
    position: relative
}

.carousel .carousel-container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 40px 4vw;
    overflow-x: auto;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    position: relative;
    scroll-padding: 0 0 0 5vw;
    -webkit-scroll-padding: 0 0 0 5vw;
    scroll-snap-stop: always;
    -webkit-scroll-snap-stop: always;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    -webkit-scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none
}

.carousel .carousel-container::-webkit-scrollbar {
    display: none;
    width: 0 !important
}

.carousel .carousel-container .carousel-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin: 0 30px;
    position: relative;
    scroll-snap-align: start;
    -webkit-scroll-snap-align: start;
    width: 30vw
}

@media (max-width:959px) {
    .carousel .carousel-container .carousel-item {
        width: 40vw
    }
}

@media (max-width:767px) {
    .carousel .carousel-container .carousel-item {
        width: 60vw
    }
}

.carousel .carousel-container .carousel-item:last-of-type:after {
    display: none
}

.carousel .carousel-container .carousel-item:before {
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    right: -4vw;
    top: 0;
    width: 4vw;
    z-index: -1
}

.carousel .carousel-container .carousel-item:after {
    background: #000;
    content: "";
    display: block;
    height: 8px;
    left: 100%;
    margin: -4px 0 0;
    position: absolute;
    top: 50%;
    width: 60px;
    z-index: -2
}

.carousel .carousel-container .carousel-item .card {
    max-width: 100%
}

.chip {
    color: #000;
    display: block
}

.chip,
.chip:focus,
.chip:hover {
    text-decoration: none
}

.chip:focus .icon,
.chip:hover .icon {
    color: #ff95a2;
    top: -5px
}

.chip .icon {
    position: relative;
    top: 0
}

.collage .flourish {
    margin-left: 60px
}

@media (max-width:767px) {
    .collage .flourish {
        margin-right: 30px
    }
}

.collage .lift {
    margin-top: -160px
}

@media (max-width:1259px) {
    .collage .lift {
        margin-top: -80px
    }
}

main {
    background: url(./../img/dots.png);
    background-size: 11px 11px;
    overflow: hidden
}

.section,
section {
    background-position: 50%;
    background-size: cover;
    display: block;
    padding: 100px 0;
    position: relative;
}

@media (max-width:959px) {
    .section,
    section {
        padding: 75px 0
    }
}

@media (max-width:767px) {
    .section,
    section {
        padding: 50px 0
    }
}

.section.red, section.red {
    background-color: rgb(255 149 162);
    background-image: linear-gradient(357deg, rgb(255 111 129) 0%, #ffc9d0 74%);
    color: #fff;
    text-decoration: none;
}

.section.red:after,
section.red:after {
    background: #ff95a2;
    -webkit-clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    content: "";
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.section.red:focus:after,
.section.red:hover:after,
section.red:focus:after,
section.red:hover:after {
    -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%)
}

.section.red article,
section.red article {
    position: relative;
    z-index: 1
}

.section.red .h2 b,
.section.red .h2 strong,
.section.red h2 b,
.section.red h2 strong,
section.red .h2 b,
section.red .h2 strong,
section.red h2 b,
section.red h2 strong {
    color: #000
}

.section.work,
section.work {
    /* background: url(./../img/illustration.png) no-repeat 0; */
    background-attachment: fixed;
    background-size: auto 100%
}

@media (max-width:959px) {
    .section.work,
    section.work {
        background-attachment: scroll
    }
}

.section.quote,
section.quote {
    /* background: url(./../img/illustration-inverse.png) no-repeat 50%; */
    background-attachment: fixed;
    background-size: cover
}

@media (max-width:959px) {
    .section.quote,
    section.quote {
        background-attachment: scroll
    }
}

.section.black,
section.black {
    background-color: #000;
    color: #fff
}

.section.white,
section.white {
    background-color: #fff
}

.section.slate,
section.slate {
    background-color: #dae4e6
}

.section.slate .content-box,
section.slate .content-box {
    background-color: #fff
}

.section.slate .content-box.pattern,
section.slate .content-box.pattern {
    background-image: url(./../img/dots.png);
    background-size: 11px 11px
}

.section.padding-lg,
section.padding-lg {
    padding: 200px 0 !important
}

@media (max-width:767px) {
    .section.padding-lg,
    section.padding-lg {
        padding: 100px 0 !important
    }
}

.section.padding-top-lg,
section.padding-top-lg {
    padding-top: 200px !important
}

@media (max-width:767px) {
    .section.padding-top-lg,
    section.padding-top-lg {
        padding-top: 150px !important
    }
}

.section.padding-bottom-lg,
section.padding-bottom-lg {
    padding-bottom: 200px !important
}

@media (max-width:767px) {
    .section.padding-bottom-lg,
    section.padding-bottom-lg {
        padding-bottom: 150px !important
    }
}

.section.padding-sm,
section.padding-sm {
    padding: 50px 0 !important
}

.section.padding-top-sm,
section.padding-top-sm {
    padding-top: 50px !important
}

.section.padding-bottom-sm,
section.padding-bottom-sm {
    padding-bottom: 50px !important
}

.section.padding-none,
section.padding-none {
    padding: 0 !important
}

.section.padding-bottom-none,
section.padding-bottom-none {
    padding-bottom: 0 !important
}

.section.padding-top-none,
section.padding-top-none {
    padding-top: 0 !important
}

.section article,
section article {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1140px;
    position: relative;
    width: 92%
}

.section article.narrow,
section article.narrow {
    max-width: 860px
}

.section .drop,
section .drop {
    bottom: -300px;
    margin-top: -300px;
    position: relative
}

.section .drop-container,
section .drop-container {
    padding-top: 300px
}

.section .content-box,
section .content-box {
    background-color: #dae4e6;
    padding: 60px
}

@media (max-width:767px) {
    .section .content-box,
    section .content-box {
        padding: 30px
    }
}

.section .content-box.black,
section .content-box.black {
    background: #000;
    color: #fff
}

.section .fill-container,
section .fill-container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 100%
}

.section .fill-container.text-right,
section .fill-container.text-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

#map {
    height: 550px
}

#map button:after {
    display: none
}

#map .gm-style .gm-style-iw-c {
    border-radius: 0 !important;
    max-width: 350px !important;
    padding: 20px !important
}

#map .gm-style .gm-style-iw-c button {
    right: 0 !important;
    top: 0 !important
}

.content {
    background-position: top;
    background-size: 100% auto
}

footer {
    background: #242424;
    color: #fff;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

footer .container {
        background-size: auto 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1200px;
    padding: 30px 0;
    position: relative;
    width: 92%;
    padding-bottom: 0px;
}

@media (max-width:959px) {
    footer .container {
        background-position: 50%
    }
}

@media (max-width:767px) {
    footer .container {
		background-position: bottom;
		background-size: 100% auto;
		padding-bottom: 30px;
	}
}

footer .container .logo {
    display: block;
    margin: 0 0 50px
}
footer .container .logo img {
	width:250px;
}

footer .container .columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 0px;
    -moz-column-gap: 0px;
    column-gap: 0px
}

@media (max-width:767px) {
    footer .container .columns {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        margin: 0 0 0px
    }
	footer .grid>[class*=unit-] {
		padding: 0 0px;
	}
}

footer .container .columns a {
    border-bottom: none;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px;
    text-decoration: none;
    text-transform: uppercase
}

footer .container .columns a:focus,
footer .container .columns a:hover {
    border-color:inherit;
}

footer .container .columns .social a {
    display: inline-block;
    line-height: 40px;
    margin: 0 0px 0 0
}

footer .container h5 {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin: 0 0 20px
}

footer .container h5 b {
    color: #ff95a2
}

footer .container .social a:focus,
footer .container .social a:hover {
    border: none;
}

footer .legal {
    background: #000;
    padding: 20px 3.5%;
}

footer .legal nav,
footer .legal p {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin: 0
}

footer .legal nav a,
footer .legal p a {
    border-bottom: 1px solid transparent;
    color: #807f7f;
    text-decoration: none
}

footer .legal nav a:focus,
footer .legal nav a:hover,
footer .legal p a:focus,
footer .legal p a:hover {
    border-color: #807f7f
}

footer .legal nav span,
footer .legal p span {
    color: #ff95a2;
    display: inline-block;
    margin: 0 2px 0 5px
}

footer .legal nav span a,
footer .legal p span a {
    color: #ff95a2
}

footer .legal nav span a:focus,
footer .legal nav span a:hover,
footer .legal p span a:focus,
footer .legal p span a:hover {
    border-color: #ff95a2
}

footer .legal nav {
    color: #807f7f;
    text-align: right
}

#newsletter button {
	padding:15px 25px;
}

@media (max-width:767px) {
    footer .legal nav {
        text-align: left
    }
}

.menumega.home header {
    position: -webkit-sticky;
    position: sticky;        display: flex;
	box-shadow: 0px 2px 10px #0000002e;
}

@media (max-width:1259px) {
    .menumega.home header,
    .menumega:after {
        position: fixed
    }
    .menumega:after {
        background: #000;
        content: "";
        height: 100vh;
        left: 0;
        opacity: 0;
        pointer-events: none;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}

@media (max-width:1259px) {
    .menumega.open:after {
        opacity: .5
    }
}

@media (max-width:1259px) {
    .menumega.open {
        overflow: hidden
    }
}

@media (max-width:1259px) {
    .menumega.open header {
        height: 151px
    }
}

.menumega.open header .hamburger {
    position: fixed
}

.menumega.open header .hamburger span {
    background: none
}

.menumega.open header .hamburger span:before {
    top: 0;
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg)
}

.menumega.open header .hamburger span:after {
    bottom: 0;
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg)
}

@media (max-width:1259px) {
    .menumega.open header .primary {
        margin: 0;
        opacity: 1;
        pointer-events: auto
    }
}

header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    left: 0;
    opacity: 1;
    padding: 0 0 0 3.5%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
}

@media (max-width:1259px) {
    header {
        display: block;
        height: 70px
    }
}

header .hamburger {
    cursor: pointer;
    display: none;
    height: 20px;
    margin: -10px 0 0;
    position: absolute;
    right: 4%;
    top: 35px;
    width: 30px;
    z-index: 901
}

@media (max-width:1259px) {
    header .hamburger {
        display: block
    }
}

header .hamburger span {
    top: 10px
}

header .hamburger span,
header .hamburger span:before {
    background: #ff95a2;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    width: 30px
}

header .hamburger span:before {
    top: -10px
}

header .hamburger span:after {
    background: #ff95a2;
    bottom: -10px;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    width: 30px
}

header .logo {
    padding: 0 0px 0 0
}

@media (max-width:1259px) {
    header .logo {
        position: absolute;
        top: 0px;
    }
}

header .logo:hover img {
    opacity: .75
}

header .logo img {
    height: 60px;
    padding: 5px 0px;
}

@media (max-width:1259px) {
    header .logo img {
        height: 60px
    }
}

header .primary {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: initial;
}

@media (max-width:1259px) {
    header .primary {
        background: #dae4e6;
        left: 5%;
        margin: 40px 0 0;
        max-height: calc(95% - 70px);
        opacity: 0;
        padding: 0;
        pointer-events: none;
        position: fixed;
        top: 70px;
        width: 90%;
        z-index: -1
    }
}

header .primary>ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: 'Josefin Sans', sans-serif;
    margin: 0;
    padding: 0
}

@media (max-width:1259px) {
    header .primary>ul {
        display: block
    }
}

header .primary>ul>li {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    margin: 0 0px;
    padding: 0;
    text-align: center
}

@media (max-width:1259px) {
    header .primary>ul>li {
        border-bottom: 1px solid #fff;
        display: block;
        margin: 0;
        position: relative;
        text-align: left
    }
}

header .primary>ul>li:focus .dropdown,
header .primary>ul>li:hover .dropdown {
    margin: 0;
    opacity: 1;
    pointer-events: auto
}

header .primary>ul>li:focus .dropdown:after,
header .primary>ul>li:hover .dropdown:after {
    height: calc(100vh - 100px);
    opacity: .5
}

header .primary>ul>li:last-of-type {
    margin-right: 0;
    max-width: fit-content;
}

@media (max-width:1259px) {
    header .primary>ul>li:last-of-type {
        border: none;
        max-width: 9999px
    }
}

header .primary>ul>li:last-of-type a {
    background: #ff95a2;
    color: #fff;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    font-weight: 700;
    padding: 0;
    position: relative;
        text-align: center;
}

@media (max-width:1259px) {
    header .primary>ul>li:last-of-type a {
        padding: 15px;
        width: auto
    }
}

header .primary>ul>li:last-of-type a:after {
    background: #e78293;
    -webkit-clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

header .primary>ul>li:last-of-type a span {
    display: block;
    padding: 25px 10px;
    position: relative;
    width: 220px;
    z-index: 1;
}

@media (max-width:1259px) {
    header .primary>ul>li:last-of-type a span {
        padding: 0;
        width: auto
    }
}

header .primary>ul>li:last-of-type a:focus,
header .primary>ul>li:last-of-type a:hover {
    color: #fff
}

header .primary>ul>li:last-of-type a:focus:after,
header .primary>ul>li:last-of-type a:hover:after {
    -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%)
}

header .primary>ul>li:last-of-type a:focus i,
header .primary>ul>li:last-of-type a:hover i {
    opacity: 1
}

header .primary>ul>li:last-of-type a i {
    color: #fff;
    font-size: 12px;
    opacity: 1;
    position: absolute;
    right: 15px;
    z-index: 1;
    top: 48%;
    transform: translateY(-50%);
}

@media (max-width:1259px) {
    header .primary>ul>li:last-of-type a i {
        right: 15px
    }
}

header .primary>ul>li.active-subnav .toggle i:first-of-type {
    display: none
}

header .primary>ul>li.active-subnav .dropdown,
header .primary>ul>li.active-subnav .toggle i:last-of-type {
    display: block
}

header .primary>ul>li .toggle {
    display: none
}

@media (max-width:1259px) {
   header .primary>ul>li .toggle {
    color: #000;
    display: block;
    font-size: 20px;
    padding: 10px 15px;
    position: absolute;
    right: 10px;
    top: 0px;
}
}
.fa-plus:before {
    content: "\254b";
}

.fa-minus:before {
    content: "\f147";
}

header .primary>ul>li .toggle i:last-of-type {
    display: none
}

header .primary>ul>li>a {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
        font-size: 14px;
    letter-spacing: 0px;
    line-height: 10px;
    padding: 15px 10px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
}

@media (max-width:1259px) {
    header .primary>ul>li>a {
        display: block;
        padding: 15px
    }
}

header .primary>ul>li>a.active,
header .primary>ul>li>a:focus,
header .primary>ul>li>a:hover {
    color: #ff95a2;
    text-decoration: none
}

header .primary>ul>li .dropdown {
        -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #dae4e6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    left: 0;
    margin-top: 40px;
    opacity: 0;
    pointer-events: none;
    padding: 20px 4%;
    position: absolute;
    top: 100%;
    width: 100%;
}

@media (max-width:1259px) {
    header .primary>ul>li .dropdown {
        background: none;
        display: none;
        margin: 0;
        padding: 0;
        opacity: 1;
        position: static
    }
}

header .primary>ul>li .dropdown:after {
    background: #000;
    content: "";
    height: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 10
}

@media (max-width:1259px) {
    header .primary>ul>li .dropdown:after {
        display: none
    }
}

header .primary>ul>li .dropdown ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-family: 'Josefin Sans', sans-serif;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

@media (max-width:1259px) {
    header .primary>ul>li .dropdown ul {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 20px;
        -moz-column-gap: 20px;
        column-gap: 20px;
        display: block;
        padding: 0 30px 20px
    }
}

@media (max-width:767px) {
    header .primary>ul>li .dropdown ul {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        display: block
    }
}

header .primary>ul>li .dropdown ul li {
    display: block;
    flex: 1 1;
    margin: 0 0 0px;
    max-width: 33%;
    min-width: 33%;
    text-align: left;
}
@media (max-width:1259px) {
    header .primary>ul>li .dropdown ul li {
        max-width: 50%;
        min-width: 50%
    }
}

@media (max-width:767px) {
    header .primary>ul>li .dropdown ul li {
        max-width: 100%;
        min-width: 100%
    }
}

header .primary>ul>li .dropdown ul li a {
    color: #000;
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 0 0 20px;
    position: relative;
    text-decoration: none
}

@media (max-width:767px) {
    header .primary>ul>li .dropdown ul li a {
        display: block;
        width: 100%
    }
}

header .primary>ul>li .dropdown ul li a:before {
    background: #ff95a2;
    content: "";
    display: block;
    height: 8px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 8px
}

header .primary>ul>li .dropdown ul li a.active,
header .primary>ul>li .dropdown ul li a:focus,
header .primary>ul>li .dropdown ul li a:hover {
    color: #ff95a2;
    text-decoration: none
}

header .primary>ul>li .dropdown nav {
    white-space: nowrap
}

@media (max-width:1259px) {
    header .primary>ul>li .dropdown nav {
        display: none
    }
}

.mls-header {
    padding: 0
}

.mls-header .back-to-tdp {
    height: 100px;
    width: 63px;
    background-color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    position: absolute;
    z-index: 2
}

@media (max-width:767px) {
    .mls-header .back-to-tdp {
        width: 50px
    }
}

.mls-header .back-to-tdp:hover {
    width: 341px
}

@media (max-width:767px) {
    .mls-header .back-to-tdp:hover {
        width: 100%
    }
}

.mls-header .back-to-tdp:hover .back-text {
    letter-spacing: 2.93px;
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    width: 100%
}

@media (max-width:767px) {
    .mls-header .back-to-tdp:hover .back-text {
        width: 250px
    }
}

@media (max-width:1259px) {
    .mls-header .back-to-tdp {
        height: 70px;
        padding: 0 10px;
        top: 0
    }
}

.mls-header .back-to-tdp .fas {
    margin: 0 0 0 20px;
    color: #fff;
    font-size: 24px
}

@media (max-width:1259px) {
    .mls-header .back-to-tdp .fas {
        font-size: 20px
    }
}

@media (max-width:767px) {
    .mls-header .back-to-tdp .fas {
        margin: 0 0 0 8px
    }
}

.mls-header .back-to-tdp .back-text {
    display: inline-block;
    width: 20px;
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -12px;
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -webkit-transition: -webkit-transform 175ms ease;
    transition: -webkit-transform 175ms ease;
    transition: transform 175ms ease;
    transition: transform 175ms ease, -webkit-transform 175ms ease
}

.mls-header .logo {
    font-size: 20px;
    font-weight: 700;
    padding: 0 40px 0 90px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width:767px) {
    .mls-header .logo {
        padding: 0 40px 0 70px
    }
}

.mls-header .logo:hover {
    color: #ff95a2
}

.mls-header .logo img {
    margin-right: 30px
}

@media (max-width:767px) {
    .mls-header .logo img {
        margin-right: 15px
    }
}

.mls-header .primary>ul>li {
    margin: 0 0 0 20px
}

@media (max-width:1259px) {
    .mls-header .primary>ul>li:last-of-type {
        margin: 0
    }
}

@-webkit-keyframes scroll {
    0% {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(30px);
        transform: translateY(30px)
    }
}

@keyframes scroll {
    0% {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(30px);
        transform: translateY(30px)
    }
}

.hero {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-position: 50% 20%;
    background-size: cover;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 60vh;
    min-height: 600px;
    overflow: hidden;
    position: relative;
    z-index: 1
}
.hero h1 {
    font-size: 34px;    line-height: 50px;
}
.hero .h2class {
    font-size: 38px;
}

@media (max-width:767px) {
    .hero {
        min-height: 30vh;
        padding: 70px 0 0
    }
}

.hero:after {
    background: #000;
    content: "";
    height: 100vh;
    left: 0;
    opacity: .6;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%
}

.hero.clean {
    height: auto;
    min-height: 0
}

.hero.medium {
    min-height: 500px
}

.hero.large {
    height: calc(100vh - 100px);
    min-height: 600px;
    max-height: 800px
}

.hero.full {
    height: auto;
    min-height: calc(100vh - 50px)
}

@media (max-width:767px) {
    .hero.full {
        padding: 70px 0
    }
}

.hero.mls {
    height: 400px
}

@media (max-width:767px) {
    .hero.mls {
        height: 200px
    }
}

@media (max-width:959px) {
    .hero.home {
        min-height: 50vh
    }
}

.hero.home:after {
    z-index: 2
}

.hero.home>svg {
    height: 8000px !important;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
    max-width: 100% !important;
    z-index: 4
}

@media (max-width:959px) {
    .hero.home>svg {
        left: -20%;
        min-width: 140% !important;
        max-width: 140% !important
    }
}

.hero.home>img {
    max-width: 80%;
    z-index: 3
}

.hero.home>img,
.hero.home iframe {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute
}

.hero.home iframe {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 56.25vw;
    min-height: 100%;
    min-width: 100%;
    pointer-events: none;
    width: 177.77777778vh
}

.hero article {
    z-index: 2
}

.hero article .inline-button,
.hero article .inline-button.alt {
    color: #fff
}

.hero article a {
    color: #ff95a2;
    text-decoration: none
}

.hero article a:focus,
.hero article a:hover {
    text-decoration: underline
}

.hero .icon-scroll {
    border-radius: 15px;
    bottom: 45px;
    -webkit-box-shadow: inset 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff;
    height: 50px;
    margin-top: -15px;
    position: absolute;
    right: 10%;
    top: 50%;
    width: 30px;
    z-index: 4
}

@media (max-width:959px) {
    .hero .icon-scroll {
        bottom: 20px;
        left: 50%;
        margin-left: -15px;
        margin-top: 0;
        right: auto;
        top: auto;
        display: none
    }
}

.hero .icon-scroll:before {
    position: absolute;
    left: 50%;
    content: "";
    width: 8px;
    height: 8px;
    background: #fff;
    margin-left: -4px;
    top: 8px;
    border-radius: 4px;
    -webkit-animation: scroll 1.5s infinite;
    animation: scroll 1.5s infinite
}

.highlights {
    position: relative
}

.highlights:before {
    background: #000;
    content: "";
    display: block;
    height: 100%;
    left: 50%;
    margin: 0 0 0 -4px;
    position: absolute;
    top: 0;
    width: 8px;
    z-index: 1
}

@media (max-width:767px) {
    .highlights:before {
        left: 0;
        margin: 0
    }
}

.highlights .highlight {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media (max-width:767px) {
    .highlights .highlight {
        display: block
    }
}

.highlights .highlight:nth-of-type(2n) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.highlights .highlight:nth-of-type(2n) .content .content-container {
    text-align: right
}

@media (max-width:767px) {
    .highlights .highlight:nth-of-type(2n) .content .content-container {
        text-align: left
    }
}

.highlights .highlight .image {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    height: auto;
    overflow: hidden
}

.highlights .highlight .image img {
    display: block
}

.highlights .highlight .content {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%
}

@media (max-width:767px) {
    .highlights .highlight .content {
        padding: 30px 0 20px
    }
}

.highlights .highlight .content .content-container {
    padding: 0 60px
}

.image-content {
    background: #dae4e6;
    margin-bottom: 100px;
    margin-left: 200px;
    padding: 60px
}

@media (max-width:959px) {
    .image-content {
        margin: 0;
        padding: 30px
    }
}

.slate .image-content {
    background: #fff
}

.image-content.mirror {
    margin-left: 0;
    margin-right: 200px
}

@media (max-width:959px) {
    .image-content.mirror {
        margin: 0
    }
}

.image-content.mirror img {
    margin: 0 -260px -140px 0
}

@media (max-width:959px) {
    .image-content.mirror img {
        margin: 0 0 20px -40px
    }
}

.image-content img {
        margin: 0 0 -110px -245px;
    max-width: calc(100% + 220px);
}

@media (max-width:959px) {
    .image-content img {
        margin: 0 0 20px -40px;
        max-width: calc(100% + 40px);
        -webkit-transform: none !important;
        transform: none !important
    }
}

.image-slider .control {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #ff95a2;
    cursor: pointer;
    display: block;
    font-size: 20px;
    height: 50px;
    line-height: 48px;
    position: absolute;
    text-align: center;
    width: 50px;
    z-index: 10
}

@media (max-width:767px) {
    .image-slider .control {
        display: none !important
    }
}

.image-slider .control.slick-disabled {
    display: none !important
}

.image-slider .control:focus,
.image-slider .control:hover {
    color: #ff95a2
}

.image-slider .control.prev {
    left: 2%
}

.image-slider .control.next {
    right: 2%
}

.image-slider .slick-dots {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    font-size: 0;
    line-height: 0;
    margin: 30px 0 0;
    text-align: center;
    width: 100%
}

.image-slider .slick-dots li {
    display: inline-block;
    margin: 0 8px
}

.image-slider .slick-dots li.slick-active button {
    background: #ff95a2
}

.image-slider .slick-dots li button {
    background: #fff;
    display: block;
    font-size: 0;
    height: 14px;
    margin: 0;
    padding: 0;
    width: 14px
}

.image-slider .slick-dots li button:after {
    display: none
}

@media (max-width:767px) {
    .image-slider .slick-dots li button {
        height: 12px;
        width: 12px
    }
}

.services-card {
    display: block;
    overflow: hidden;
    padding: 10px;
    position: relative
}

@media (max-width:767px) {
    .services-card {
        margin: 0 auto;
        max-width: 90%
    }
}

.services-card:before {
    border: 0 solid #000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%
}

.services-card .image {
    background-position: 50%;
    background-size: cover;
    margin: 0 0 20px;
    position: relative
}

.services-card .image:before {
    content: "";
    display: block;
    padding-bottom: 100%
}

.services-card .image:after {
    background: rgba(0, 0, 0, .8);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.services-card .image div {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
    position: absolute;
    text-align: center;
    z-index: 1;
    width: 80%
}

.services-card .image div img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    height: 80px;
    margin: 0 0 20px
}

.services-card .image div h5 {
    color: #fff;
    font-size: 30px
}

.services-card .image div h5 span {
    display: none
}

.services-card .image div h5 i {
    color: #ff95a2;
    font-size: 14px;
    padding-left: 5px;
    white-space: nowrap
}

a.services-card {
    text-decoration: none
}

a.services-card:focus,
a.services-card:hover {
    overflow: visible
}

a.services-card:focus:before,
a.services-card:hover:before {
    border-width: 8px;
    height: calc(100% + 40px);
    left: -20px;
    top: -20px;
    width: calc(100% + 40px)
}

a.services-card:focus .image div h5 span,
a.services-card:hover .image div h5 span {
    display: inline
}

.social {
    display: block;
    margin: -5px 0 0
}

@media (max-width:959px) {
    .social {
        margin-top: 0
    }
}

.social span {
    font-size: 16px;
    letter-spacing: 3px;
    text-transform: uppercase
}

.social a,
.social span {
    display: inline-block;
    line-height: 30px;
    margin: 0 5px 0 0
}

.social a {
    background: #242424;
    border-radius: 50%;
    color: #fff;
    font-size: 15px;
    height: 40px;
    text-align: center;
    width: 40px
}

.social a:focus,
.social a:hover {
    background: #242424;
}

.tile-wrapper {
    background: #000;
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    text-decoration: none
}

@media (max-width:767px) {
    .tile-wrapper {
        margin: 0 auto;
        max-width: 90%
    }
}

@media (max-width:767px) {
    .tile-wrapper .tile {
        max-width: 100%
    }
}

.tile-wrapper:focus,
.tile-wrapper:hover {
    overflow: visible
}

.tile-wrapper:focus:before,
.tile-wrapper:hover:before {
    border-width: 8px;
    height: calc(100% + 40px);
    left: -20px;
    top: -20px;
    width: calc(100% + 40px)
}

.tile-wrapper:focus .tile .content,
.tile-wrapper:hover .tile .content {
    margin: 0;
    opacity: 1
}

@media (max-width:767px) {
    .tile-wrapper:focus .tile .content,
    .tile-wrapper:hover .tile .content {
        opacity: 0
    }
}

.tile-wrapper:focus .tile .content h5 i,
.tile-wrapper:hover .tile .content h5 i {
    padding-left: 5px
}

.tile-wrapper:before {
    border: 0 solid #000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%
}

.black .tile-wrapper:before {
    border-color: #fff
}

.tile-wrapper .label {
    color: #fff;
    padding: 10px 20px;
    text-decoration: none
}

.tile-wrapper .label .red {
    color: #ff95a2
}

.tile {
    background-position: 50%;
    background-size: cover;
    display: block;
    overflow: hidden;
    position: relative
}

@media (max-width:767px) {
    .tile {
        margin: 0 auto;
        max-width: 90%
    }
}

.tile.rectangle:after {
    padding-bottom: 120%
}

.tile:after {
    content: "";
    display: block;
    padding: 0 0 100%
}

.tile:before {
    border: 0 solid #ff95a2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%
}

.black .tile:before {
    border-color: #fff
}

.tile .content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: rgba(57, 42, 41, .8);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    margin: 40px 0 0;
    opacity: 0;
       padding: 10px;
    text-align: center;
    width: 100%
}

@media (max-width:767px) {
    .tile .content {
        margin: 0;
        opacity: 0
    }
    .tile .content h5 i {
        padding-left: 5px
    }
}

.tile .content h5 i {
    color: #ff95a2;
    font-size: 14px;
    padding-left: 5px;
    white-space: nowrap
}

a.tile:focus,
a.tile:hover {
    overflow: visible
}

a.tile:focus:before,
a.tile:hover:before {
    border-width: 4px;
    height: calc(100% + 0px);
    left: 10px;
    top: 10px;
    width: calc(100% + 0px);
}

a.tile:focus .content,
a.tile:hover .content {
    margin: 0;
    opacity: 1
}

@media (max-width:767px) {
    a.tile:focus .content,
    a.tile:hover .content {
        opacity: 0
    }
}

a.tile:focus .content h5 i,
a.tile:hover .content h5 i {
    padding-left: 5px
}

.IDX-showcaseTable {
    display: block;
    font-size: 20px !important;
    line-height: 30px;
    margin: 0 -20px;
    width: calc(100% + 40px)
}

.IDX-showcaseTable .IDX-showcaseRow {
    display: block
}

.IDX-showcaseTable .IDX-showcaseRow .IDX-showcaseCell {
    display: inline-block !important;
    margin-bottom: 30px;
    min-width: 0;
    padding: 0 20px;
    vertical-align: top;
    width: 33.333333% !important
}

@media (max-width:959px) {
    .IDX-showcaseTable .IDX-showcaseRow .IDX-showcaseCell {
        width: 50% !important
    }
}

@media (max-width:767px) {
    .IDX-showcaseTable .IDX-showcaseRow .IDX-showcaseCell {
        width: 100% !important
    }
}

.IDX-showcaseTable .IDX-showcaseRow .IDX-showcaseCell .IDX-showcaseContainer {
    height: auto !important
}

.IDX-showcaseTable .IDX-showcaseRow .IDX-showcaseCell .IDX-showcaseContainer .IDX-showcasePrice {
    color: #ff95a2;
    font-size: 20px !important
}

#IDX-main,
#IDX-registrationSignup {
    margin-bottom: -20px
}

#IDX-main a,
#IDX-registrationSignup a {
    color: #000 !important
}

#IDX-main a:hover,
#IDX-registrationSignup a:hover {
    color: #000 !important;
    text-decoration: none !important
}

#IDX-main h4,
#IDX-registrationSignup h4 {
    padding-left: 0
}

#IDX-main button:after,
#IDX-main h4:before,
#IDX-registrationSignup button:after,
#IDX-registrationSignup h4:before {
    display: none
}

#IDX-main .IDX-pageContainer,
#IDX-registrationSignup .IDX-pageContainer {
    padding: 50px 0
}

#IDX-main #IDX-similar-listings-result,
#IDX-main #IDX-similar-listings-title,
#IDX-main #IDX-Subheader,
#IDX-main .IDX-pageContainer>*,
#IDX-registrationSignup #IDX-similar-listings-result,
#IDX-registrationSignup #IDX-similar-listings-title,
#IDX-registrationSignup #IDX-Subheader,
#IDX-registrationSignup .IDX-pageContainer>* {
    width: 92%;
    max-width: 1260px;
    margin: 0 auto
}

#IDX-main .IDX-resultsCellsContainer,
#IDX-registrationSignup .IDX-resultsCellsContainer {
    display: block;
    margin: 0 -20px;
    width: calc(100% + 40px)
}

#IDX-main .IDX-resultsCellsContainer .IDX-resultsCell,
#IDX-registrationSignup .IDX-resultsCellsContainer .IDX-resultsCell {
    border: none !important;
    display: inline-block !important;
    margin-bottom: 40px !important;
    min-width: 0;
    padding: 0 20px;
    vertical-align: top;
    width: 33.333333% !important
}

@media (max-width:959px) {
    #IDX-main .IDX-resultsCellsContainer .IDX-resultsCell,
    #IDX-registrationSignup .IDX-resultsCellsContainer .IDX-resultsCell {
        width: 50% !important
    }
}

@media (max-width:767px) {
    #IDX-main .IDX-resultsCellsContainer .IDX-resultsCell,
    #IDX-registrationSignup .IDX-resultsCellsContainer .IDX-resultsCell {
        width: 100% !important
    }
}

#IDX-main .IDX-resultsCellsContainer .IDX-resultsCell .IDX-resultsAddress,
#IDX-main .IDX-resultsCellsContainer .IDX-resultsCell .IDX-resultsMainInfo,
#IDX-main .IDX-resultsCellsContainer .IDX-resultsCell .IDX-resultsPhoto,
#IDX-registrationSignup .IDX-resultsCellsContainer .IDX-resultsCell .IDX-resultsAddress,
#IDX-registrationSignup .IDX-resultsCellsContainer .IDX-resultsCell .IDX-resultsMainInfo,
#IDX-registrationSignup .IDX-resultsCellsContainer .IDX-resultsCell .IDX-resultsPhoto {
    padding-left: 0 !important;
    width: 100% !important
}

#IDX-main .IDX-resultsCellsContainer .IDX-resultsCell .IDX-MLSLogo,
#IDX-registrationSignup .IDX-resultsCellsContainer .IDX-resultsCell .IDX-MLSLogo {
    display: none !important
}

#IDX-main .IDX-resultsCellsContainer .IDX-resultsCell .IDX-resultsCellActions,
#IDX-registrationSignup .IDX-resultsCellsContainer .IDX-resultsCell .IDX-resultsCellActions {
    margin-top: 10px !important
}

#IDX-main #IDX-searchPageWrapper,
#IDX-registrationSignup #IDX-searchPageWrapper {
    background: #dae4e6
}

#IDX-main .select2-container,
#IDX-main input,
#IDX-main select,
#IDX-registrationSignup .select2-container,
#IDX-registrationSignup input,
#IDX-registrationSignup select {
    border: none !important
}

#IDX-main .select2-container a,
#IDX-main input a,
#IDX-main select a,
#IDX-registrationSignup .select2-container a,
#IDX-registrationSignup input a,
#IDX-registrationSignup select a {
    color: #000 !important
}

#IDX-main .select2-container a:hover,
#IDX-main input a:hover,
#IDX-main select a:hover,
#IDX-registrationSignup .select2-container a:hover,
#IDX-registrationSignup input a:hover,
#IDX-registrationSignup select a:hover {
    text-decoration: none
}

#IDX-main #IDX-resultsPager-header,
#IDX-main .IDX-contentFooter,
#IDX-registrationSignup #IDX-resultsPager-header,
#IDX-registrationSignup .IDX-contentFooter {
    background: #dae4e6;
    margin-bottom: 30px
}

#IDX-main .IDX-similar-listings--info img,
#IDX-registrationSignup .IDX-similar-listings--info img {
    display: none
}

#IDX-main .IDX-pagination-action a,
#IDX-registrationSignup .IDX-pagination-action a {
    background: none !important;
    border: none;
    font-size: 30px;
    line-height: 30px
}

#IDX-main .IDX-pagination-action .IDX-select,
#IDX-registrationSignup .IDX-pagination-action .IDX-select {
    background: none !important
}

#IDX-main .IDX-pagination-action .IDX-select a,
#IDX-registrationSignup .IDX-pagination-action .IDX-select a {
    font-size: 20px
}

#IDX-main .IDX-pagination-action .select2-arrow,
#IDX-registrationSignup .IDX-pagination-action .select2-arrow {
    background: none !important
}

#IDX-main #IDX-photoGallery,
#IDX-main #IDX-previousPage,
#IDX-main .IDX-row-content,
#IDX-registrationSignup #IDX-photoGallery,
#IDX-registrationSignup #IDX-previousPage,
#IDX-registrationSignup .IDX-row-content {
    display: block !important
}

#IDX-main .IDX-hide,
#IDX-registrationSignup .IDX-hide {
    display: none !important
}

#IDX-main .IDX-navbar-default,
#IDX-registrationSignup .IDX-navbar-default {
    background: none !important;
    border: none !important;
    font-family: nobel, sans-serif !important;
    margin-bottom: 20px !important
}

#IDX-main .IDX-navbar-default .IDX-navbar-nav li,
#IDX-registrationSignup .IDX-navbar-default .IDX-navbar-nav li {
    font-family: nobel, sans-serif !important;
    margin: 0 !important
}

#IDX-main .IDX-navbar-default .IDX-navbar-nav li.IDX-active a,
#IDX-registrationSignup .IDX-navbar-default .IDX-navbar-nav li.IDX-active a {
    background: #717b7d !important;
    color: #fff !important
}

#IDX-main .IDX-navbar-default .IDX-navbar-nav li a,
#IDX-registrationSignup .IDX-navbar-default .IDX-navbar-nav li a {
    color: #000 !important;
    font-family: nobel, sans-serif !important
}

#IDX-main .IDX-navbar-default .IDX-navbar-nav li a:hover,
#IDX-registrationSignup .IDX-navbar-default .IDX-navbar-nav li a:hover {
    background: #adb8bb !important
}

#IDX-main .IDX-control-group:nth-of-type(odd),
#IDX-registrationSignup .IDX-control-group:nth-of-type(odd) {
    border-right: none !important
}

#IDX-main .IDX-resultsSeparator,
#IDX-main .IDX-resultsSeparatorText,
#IDX-main hr,
#IDX-registrationSignup .IDX-resultsSeparator,
#IDX-registrationSignup .IDX-resultsSeparatorText,
#IDX-registrationSignup hr {
    display: none
}

#IDX-main .IDX-pageHeader,
#IDX-main .IDX-propertyTypeHeader,
#IDX-registrationSignup .IDX-pageHeader,
#IDX-registrationSignup .IDX-propertyTypeHeader {
    border: none;
    border-left: 10px solid #ff95a2;
    color: #000;
    margin: 0 20px 40px;
    padding: 5px 0 5px 20px
}

#IDX-main .IDX-pageHeader,
#IDX-registrationSignup .IDX-pageHeader {
    margin: 20px auto
}

#IDX-main #IDX-Subheader h1,
#IDX-registrationSignup #IDX-Subheader h1 {
    margin-top: 0 !important;
    font-size: 38px
}

#IDX-main #IDX-Subheader h2,
#IDX-registrationSignup #IDX-Subheader h2 {
    font-size: 20px;
    letter-spacing: 2px;
    text-transform: uppercase
}

#IDX-main #IDX-Subheader h2:before,
#IDX-registrationSignup #IDX-Subheader h2:before {
    content: "";
    display: inline-block !important;
    width: 10px;
    height: 10px;
    background: red;
    margin-top: -4px;
    margin-right: 12px
}

#IDX-main #IDX-action-buttons,
#IDX-main .IDX-form-actions,
#IDX-registrationSignup #IDX-action-buttons,
#IDX-registrationSignup .IDX-form-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
    padding: 0 15px !important
}

#IDX-main #IDX-action-buttons button,
#IDX-main .IDX-form-actions button,
#IDX-registrationSignup #IDX-action-buttons button,
#IDX-registrationSignup .IDX-form-actions button {
    width: auto !important
}

#IDX-main #IDX-action-buttons #IDX-submitBtn,
#IDX-main #IDX-action-buttons .IDX-btn-primary,
#IDX-main .IDX-form-actions #IDX-submitBtn,
#IDX-main .IDX-form-actions .IDX-btn-primary,
#IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary,
#IDX-registrationSignup .IDX-form-actions #IDX-submitBtn,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-primary {
    border: none !important
}

#IDX-main #IDX-action-buttons #IDX-submitBtn:after,
#IDX-main #IDX-action-buttons .IDX-btn-primary:after,
#IDX-main .IDX-form-actions #IDX-submitBtn:after,
#IDX-main .IDX-form-actions .IDX-btn-primary:after,
#IDX-registrationSignup #IDX-action-buttons #IDX-submitBtn:after,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-primary:after,
#IDX-registrationSignup .IDX-form-actions #IDX-submitBtn:after,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-primary:after {
    display: none
}

#IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
#IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn),
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn) {
    background: none !important;
    border: 6px solid #000 !important;
    color: #000 !important;
    margin-left: 0;
    padding: 14px 30px !important
}

#IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):after,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):after {
    display: none
}

#IDX-main #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):hover,
#IDX-main .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):hover,
#IDX-registrationSignup #IDX-action-buttons .IDX-btn-default:not(#IDX-submitBtn):hover,
#IDX-registrationSignup .IDX-form-actions .IDX-btn-default:not(#IDX-submitBtn):hover {
    background: #000 !important;
    border: 6px solid #000 !important;
    color: #fff !important
}

#IDX-main .IDX-form-actions,
#IDX-registrationSignup .IDX-form-actions {
    padding: 0 !important
}

#IDX-ccz-group input,
#IDX-searchRefinement-group input {
    font-family: nobel, sans-serif !important;
    font-size: 16px !important
}

#IDX-Subheader-Category {
    padding-top: 60px
}

#IDX-similar-listings-title {
    margin-top: 20px !important
}

#IDX-detailsHead {
    background-color: #fff;
    padding: 30px
}

#IDX-detailsContactForm {
    width: 100%;
    margin: 0 auto
}

#IDX-advancedSearchFields {
    clear: both;
    padding-top: 20px
}

.IDX-wrapper-standard i {
    color: #fff !important
}

.IDX-wrapper-standard .IDX-primaryPhoto,
.IDX-wrapper-standard .IDX-thumbnail {
    border: none
}

.IDX-wrapper-standard .IDX-resultsCellActions {
    float: none !important;
    margin: auto;
    width: 100%
}

.IDX-wrapper-standard .IDX-resultsCellActions .IDX-resultsDetailsLink,
.IDX-wrapper-standard .IDX-resultsCellActions .IDX-resultsOpenHouse {
    width: 100%
}

.IDX-wrapper-standard .IDX-field {
    margin-bottom: 10px
}

.IDX-wrapper-standard .IDX-detailsHotAction,
.IDX-wrapper-standard .IDX-topAction {
    display: none
}

.IDX-wrapper-standard .IDX-form-control {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, .1) !important;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .1) !important
}

.IDX-wrapper-standard .IDX #IDX-detailsGalleryAction {
    display: none
}

.IDX-btn-primary {
    background-color: #ff95a2 !important
}

.IDX-btn-primary:hover {
    background-color: #ff95a2 !important
}

.IDX-resultsAddressLink {
    text-align: center
}

.IDX-resultsPhotogallery,
.IDX-resultsSaveProperty {
    display: none !important
}

.IDX-page-listing {
    background-color: #ebf0f2
}

.IDX-resultsMainInfo.IDX-panel.IDX-panel-default {
    display: none
}

.IDX-resultsDescription,
.IDX-resultsVirtualTour {
    display: none !important
}

.IDX-panel-title {
    padding-left: 20px !important
}

.IDX-panel-title:before {
    content: "";
    display: block !important;
    width: 10px;
    height: 10px;
    background: red;
    margin-top: -4px
}

.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction {
    background: none !important;
    border: 6px solid #000 !important;
    color: #ff95a2 !important;
    font-weight: 900 !important;
    margin-left: 0;
    padding: 14px 30px !important
}

.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:after {
    display: none
}

.idx #IDX-resultsRow #IDX-resultsContent .IDX-resultsCellAction:hover {
    background: #000 !important;
    border: 6px solid #000 !important;
    color: #fff !important
}

.idx #IDX-Subheader {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 500px;
    left: 50%;
    padding-top: 100px;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    z-index: 10
}

@media (max-width:1439px) {
    .idx #IDX-Subheader {
        padding: 70px 60px 0
    }
}

@media (max-width:1259px) {
    .idx #IDX-Subheader {
        padding: 70px 60px 0
    }
}

@media (max-width:767px) {
    .idx #IDX-Subheader {
        height: 270px
    }
}

.idx #IDX-Subheader h1 {
    font-weight: 900;
    font-size: 77px !important;
    line-height: 67px;
    color: #fff;
    margin-top: 75px
}

@media (max-width:767px) {
    .idx #IDX-Subheader h1 {
        font-size: 50px !important;
        line-height: 60px
    }
}

.idx .select2-search-choice-close {
    padding-left: 0 !important
}


.beer-slider .beer-reveal {
    border-right: 4px solid #fff;
    -webkit-box-shadow: 0 0 0 4px #fff;
    box-shadow: 0 0 0 4px #fff
}

.beer-slider .beer-handle {
    background: #fff;
    color: #ff95a2
}



.container{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto}
	@media (min-width:576px){.container{max-width:540px}}
	@media (min-width:768px){.container{max-width:820px}}
	@media (min-width:992px){.container{max-width:960px}}
	@media (min-width:1200px){.container{max-width:1140px}
}

.d-flex { display:flex;}
.justify-content-center {
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
}
.align-items-center {
    -ms-flex-align: center!important;
    align-items: center!important;
}
.row{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-15px;margin-left:-15px}.no-gutters{margin-right:0;margin-left:0}.no-gutters>.col,.no-gutters>[class*=col-]{padding-right:0;padding-left:0}
.col,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-auto,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-auto,.col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-auto,.col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-auto{position:relative;width:100%;padding-right:15px;padding-left:15px}.col{-ms-flex-preferred-size:0;flex-basis:0;-ms-flex-positive:1;flex-grow:1;min-width:0;max-width:100%}

.col-1{-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}.col-2{-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}.col-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}.col-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}.col-5{-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}.col-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}.col-7{-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}.col-8{-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}.col-9{-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}.col-10{-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}.col-11{-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}.col-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}

@media (min-width:576px){
	.col-sm-1{-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}.col-sm-2{-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}.col-sm-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}.col-sm-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}.col-sm-5{-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}.col-sm-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}.col-sm-7{-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}.col-sm-8{-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}.col-sm-9{-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}.col-sm-10{-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}.col-sm-11{-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}.col-sm-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
}

@media (min-width:768px){
   .col-md-1{-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}.col-md-2{-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}.col-md-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}.col-md-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}.col-md-5{-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}.col-md-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}.col-md-7{-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}.col-md-8{-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}.col-md-9{-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}.col-md-10{-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}.col-md-11{-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}.col-md-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
}


@media (min-width:992px){
	.col-lg{-ms-flex-preferred-size:0;flex-basis:0;-ms-flex-positive:1;flex-grow:1;min-width:0;max-width:100%}.row-cols-lg-1>*{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}.row-cols-lg-2>*{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}.row-cols-lg-3>*{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}.row-cols-lg-4>*{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}.row-cols-lg-5>*{-ms-flex:0 0 20%;flex:0 0 20%;max-width:20%}.row-cols-lg-6>*{-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}.col-lg-auto{-ms-flex:0 0 auto;flex:0 0 auto;width:auto;max-width:100%}.col-lg-1{-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}.col-lg-2{-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}.col-lg-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}.col-lg-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}.col-lg-5{-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}.col-lg-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}.col-lg-7{-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}.col-lg-8{-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}.col-lg-9{-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}.col-lg-10{-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}.col-lg-11{-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}.col-lg-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
}

@media (min-width:1200px){	
   .col-xl-1{-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}.col-xl-2{-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}.col-xl-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}.col-xl-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}.col-xl-5{-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}.col-xl-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}.col-xl-7{-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}.col-xl-8{-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}.col-xl-9{-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}.col-xl-10{-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}.col-xl-11{-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}.col-xl-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
}


.AsyncSelect svg {
    display: none !important;
}


.calloutCities {
    border-left: 10px solid #000;
    margin: 0 0 0 auto;
    overflow: hidden;
    padding: 10px 10px 10px 0;
    text-align: right;
    width: 100%;
}
.calloutCities h2 {
    color: #ff95a2;
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: left;
    padding-left: 20px;
}
.calloutCities p {
    text-align: left;
    padding-left: 20px;
}

.paddFifty {
    padding: 50px 0px;
}
.bgColorCities {
    background-color: #ff9ca824 !important;
}


.backtoHome {
    position: relative;
    top: 0px;
    right: 0px;
    background: #ff95a2;
    color: #fff;
    padding: 10px 20px;
    display: block;
    text-align: center;
    margin: 0px auto;
}
.backtoHome:hover {
    color: #fff;
    text-decoration: none;
}
.backtoHome span {
    position: relative;
    z-index: 1;
}
.backtoHome:after {
    background: #e78293;
    -webkit-clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.backtoHome:focus:after {
    -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.backtoHome:hover:after {
    -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
        transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.mblshow {
    display: none !important;
}
.imgIconThankyou {
        width: 150px;
        display: block;
        margin: 0px auto;
}
.thankYouSection {
    padding: 80px 0px;
    text-align: center;
    margin-top: 0px;
    position: relative;
    z-index: 1;
    padding-bottom: 80px;
}
.thanksTxtPraNew {
    text-align: center;
    margin-top: 0px;
    font-weight: 500;
    color: rgb(231 130 147);
    font-size: 16px;
    padding: 0px 80px;
}