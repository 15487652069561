
@import url("https://p.typekit.net/p.css?s=1&k=yqo5vxu&ht=tk&f=25682.25683.25684.25685.31227.31228.31229.31230.31231.31232&a=1910479&app=typekit&e=css");

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

a {
    color: #ff95a2;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #000;
    text-decoration: none;
}


.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    letter-spacing: normal;
    font-family: 'Josefin Sans', sans-serif;
    text-transform: none;
}

.f-46 { font-size: 46px !important; font-weight:bold !important;}
.f-36 { font-size:36px;}
.f-26 { font-size: 26px; font-weight: 800;}
.f-22 { font-size: 22px; font-weight:600;}


input[type="date"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control, input[type="time"].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 50px;
    font-size: 14px;
}

.contact-wrapper {
    background: #fff;
    box-shadow: none;
    border-radius: 0.75em;
    padding: 0em;
    width: 100%;
    margin: 0px auto;
}

.login-cta > h2 {
      color: #ff95a2;
    font-size: 30px;
    text-align: center;
    margin-bottom: 0px;
}

.socials-wrapper > .s-account > h2 {
  background: #fff;
  color: #3e3f5e;
  font-size: 1rem;
  position: relative;
  text-align: center;
  z-index: 10;margin-bottom: 0px;
}
.socials-wrapper > .s-account > h2:after, .socials-wrapper > .s-account > h2:before {
  background: #d3d3e2;
  content: "";
  display: block;
  height: 0.1em;
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  width: 17.5%;
}
.socials-wrapper > .s-account {
  display: block;
}

.socials-wrapper > .s-account > h2:after {
  right: 0;
}
.socials-wrapper > .s-account > h2:before {
  left: 0;
}
.socials-wrapper > ul {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 0;    margin-bottom: 10px;
}
.socials-wrapper > ul > li {
  margin: 0.5em;
}
.socials-wrapper > ul > li > a {
  align-items: center;
  border-radius: 0.5em;
  color: #fff;
  display: flex;
  height: 2em;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  transition: all 300ms ease;
  width: 2em;
}
.socials-wrapper > ul > li > a.facebook {
  background-color: #3763d2;
}
.socials-wrapper > ul > li > a.twitter {
  background-color: #ea4335;
}
.socials-wrapper > ul > li > a:hover {
  transform: translatey(-0.25em);
}

.login-page {
  margin: 1em 0;
}
.login-page > .form-row {
  display: flex;
  margin: 0.75em 0;
  position: relative;
}
.login-page .form-row i {
	position: absolute;
    top: 15px;
    left: 30px;
    color: #ff95a2;
}

.field-icon {
    position: absolute;
    right: 0px;
    z-index: 2;
    top: 16px;
    right: 15px;
    color: #b9b9b9;
}
.mtb-0 {
	margin:0px;
}
.login-page > .form-row > span {
  background: #fff;
    color: #adafca;
    display: inline-block;
    font-weight: 400;
    left: 45px;
    padding: 0 0.5em;
    position: absolute;
    pointer-events: none;
    transform: translatey(-50%);
    top: 50%;
    transition: all 300ms ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
}
.login-page > .form-row > input, .login-page > .form-row > button {
        height: 50px;
    border-radius: 0px;
    font-family: 'Josefin Sans', sans-serif;
    padding: 15px 45px;
    width: 100%;
    margin: 0px auto;
}
.login-page > .form-row > input {
  font-weight: 400;
  transition: 100ms ease all;
  width: 100%;border: 0.075em solid #ddd;
}
.login-page > .form-row > input[type=text]{
  border: 0.075em solid #ddd;
}
.login-page > .form-row > input[type=password] {
  border: 0.075em solid #ddd;
  font-size: 20px;
}

.login-page > .form-row > select {
      font-weight: 400;
    appearance: auto;
    transition: 100ms ease all;
    width: 100%;
    border: 0.075em solid #ddd;
    padding: 15px 45px;
    position: relative;
    height: 50px;
}
.forgot-password a {
    color: #ff95a2;
}
.forgot-password a:hover {
    color: #ff95a2;
}
.login-page > .form-row > input[type=email]{
  border: 0.075em solid #ddd;
}
.login-page > .form-row > input:valid + span {
  top: 0;
  font-size: 0.9rem;
}
.login-page > .form-row > input:invalid + span {
  top: 50%;
}
.login-page > .form-row > input:focus + span {
  top: 0;
}
.login-page > .form-row > input:required {
  box-shadow: none;
}
.login-page > .form-row > input:focus {
  border-color: #ff95a2;
  outline: none;
}
.login-page > .form-row > input:focus:invalid {
  box-shadow: none;
  top: 50%;
}
.login-page > .form-row > input:focus:valid {
  top: 0;
}
.login-page > .form-row > button {
  background-color: #ff95a2;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  transition: all 300ms ease;margin-top: 10px;
}

.login-page > .form-row > button:focus {
  outline: none;
}
.login-page > .form-row > button:hover {
  background-color: #ff95a2;
}
.title {
  margin-bottom: 30px;
}
.title p {
  color: #666;
  line-height: 1;
}


.login-page .checkbox {
  width: 100%;
    margin-bottom: 10px;
    display: flex !important;    margin-top: 20px;
}

.login-page .checkbox__system-checkbox {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.login-page .checkbox__custom-checkbox {
        display: inline-block;
    vertical-align: middle;
    position: relative;
    box-sizing: border-box;
    width: 60px;
    height: 30px;
    border: 1px solid #ff95a2;
    border-radius: 15px;
    box-shadow: none;
    background-color: #cdcdcd;
    transition: background-color 0.2s ease-in;
    margin-right: 15px;
}

.login-page .checkbox__custom-checkbox::after {
  content: "";
  box-sizing: border-box;

  width: 28px; 
  height: 28px;

  position: absolute; 
  top: 0;
  left: 0;

  border: 1px solid white; 
  border-radius: 50%;
  background-color: white;
  transition: left 0.2s ease-in;
}
.login-page .checkbox__system-checkbox:checked + .checkbox__custom-checkbox::after {
  left: 25px;
}
.login-page .checkbox__system-checkbox:checked + .checkbox__custom-checkbox {
  background-color: #ff95a2;
}
.login-page .checkbox__system-checkbox:disabled + .checkbox__custom-checkbox {
  opacity: 0.3;
}
.login-page label>span {
        color: #000;
    display: block;
    margin: 0 0 5px;
    text-transform: none;
    font-size: 14px;
    letter-spacing: normal;
}
.login-right-img {
	border-radius: 14px;
    height: 550px;
    object-fit: cover;
}

.customer-loginhead .avatar-upload .avatar-preview {
    width: 125px;
    height: 125px;
}
.customer-loginhead .avatar-upload {
    position: relative;
    max-width: 150px !important;
}



/*form styles*/
#msform {
	text-align: left;
	position: relative;
}
#msform fieldset {
	background: white;
	border: 0 none;
	box-sizing: border-box;
	width: 100%;
	position: relative;
}
/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
	display: none;
}

#msform .login-page > .form-row > button {
  background-color: #ff95a2;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  transition: all 300ms ease;
}

#msform .login-page > .form-row > button:focus {
  outline: none;
}
#msform .login-page > .form-row > button:hover {
  background-color: #ff95a2;
}
#msform .login-page select {
        appearance: auto;
    height: auto;
    height: 60px;
    border: 0.075em solid #ddd !important;
    padding: 15px 10px !important;
    font-size: 15px;
    color: #000;
}
.action-button {
	    background: #ff95a2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 20px;
    padding: 20px 30px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;transition: all .2s cubic-bezier(.645,.045,.355,1);
}
.action-button span {
    position: relative;
    z-index: 1;
}
.action-button::after {
	 background: #d43517;
    -webkit-clip-path: polygon(100% 0,0 100%,0 100%,100% 100%);
    clip-path: polygon(100% 0,0 100%,0 100%,100% 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}


.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}
.avatar-upload .avatar-edit {
        position: absolute;
    right: -10px;
    z-index: 1;
    top: 50px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.title-bnner {
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 50px;
    margin: 0 0 0px;
    padding: 0;
    text-transform: none;
    display: block;
    width: 100%;
    text-align: center;
}
.title-bnner small {
	    position: relative;
    top: -20px;
    left: -5px;
}
.dont-acct {
	    letter-spacing: normal;
    text-transform: none;
    font-weight: 500;
    color: #242424;
}
.dont-acct a {
    color: #ff95a2;
}
.dont-acct a:hover {
    color: #ff95a2;
}
.padd-50 {
	padding:50px;
}

.w-250 {
    width: 160px;
    max-width: 160px;
}
.txt-20 {
	    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
}
.carousel{
  width:90%;
  margin:0px auto;
}
.slick-slide{
        margin: 0 0px !important;
    /* width: 370px !important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0px 10px;
}

.slick-slide img{
  width:100%;
}
.slick-prev, .slick-next{
  background: #000;
  border-radius: 15px;
  border-color: transparent;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #ff95a2;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 14px;
    line-height: 0px;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li button:after {
	display:none;
}
.slick-slider {
    margin-bottom: 0px;
    margin-top: 25px;
}
.carousel {
    width: 100%;
    margin: 0px auto;
    overflow: visible;
}
.slick-dots {
    position: absolute;
    bottom: -60px !important;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 15px !important;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #ff95a2;
}
.slick-prev:after, .slick-next:after {
	display:none;
}
.servicebox {
   border: 1px solid #ddd;
   border-top-left-radius: 0px;
   border-top-right-radius: 0px;
}
.servicebox .card-header img {
    border-top-left-radius: 0px;
   border-top-right-radius: 0px;
}
.servicebox .card-header {
    padding: 0px;
}
.card-body {
    background: #fff;
    width: 100%;
    vertical-align: top;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    height: 130px;
}
.servicebox .card-body {
    padding: 10px;
}
.card-content{
    text-align: left;
    color: #333;
    padding: 0px;
    letter-spacing: normal;
}
.card-title {
	font-size:20px;
	font-weight:700;margin-bottom: 0px !important;    line-height: 25px;
}
.card-text p {
      font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 10px;
}

.lpage .login-page .form-row i {
    position: absolute;
    top: 15px;
    left: 15px;
    color: #ff95a2;
}
.lpage .login-page > .form-row > span {
    left: 35px;
}
#otp-model .modal-title:before {
	display:none;
}
#otp-model .modal-header button:after {
	display:none;
}
#otp-model .modal-header button:focus {
	outline:none;
}
#otp-model .modal-title {
    padding-left: 0px;
    letter-spacing: normal;
}
#otp-model .modal-dialog {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    margin: 0px auto;
}
#otp-model .modal-content {
    position: absolute;
    background-color: #f8f8f8;
    background-clip: padding-box;
        border-radius: 5px;
    outline: 0;
    border: none;
    box-shadow: 0 4px 10px rgb(0 0 0 / 50%);
    display: flex;
    flex-direction: column;
    left: 50%;
    top: 50%;
    right: auto;
    min-height: 300px;
    max-height: 84vh;
    width: 90%;
    max-width: 600px;
    transform: translate(-50%, -50%);
}
#otp-model .modal-header {
    background: #ff95a2;
	color:#fff;
}
#otp-model .close {
    color: #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: 1;
}
#otp-model .login-page .form-row i {
    left: 15px;
    top: 15px;
}
#otp-model .login-page > .form-row > span {
    left: 40px;    top: 25px;
}
#otp-model .login-page > .form-row > input {
    padding: 15px 40px;
}
.f-14 {font-size:14px;}
.f-16 { font-size:16px;}



/* bf-testimonial-slick */
.client-padd {
       padding: 80px 0 !important;
    PADDING-BOTTOM: 60px !important;
}
.bf-testimonial-slick-single{
	color:#fff;
	text-align: center;
	border-radius: 10px;
      background-image: linear-gradient(291deg, #ffffff -14%, #ffffff 121%);
	padding: 20px;
	height: 240px;
}
.slick-slide {
	margin: 0 15px;
}
@media only screen and (min-width: 992px){
	.slick-slide {
		margin: 0 10px;
	}
}

.bf-testimonial-icon i{
		font-size: 20px;
		letter-spacing: 5.4px;
		color: #f8c51c;
}
.bf-testimonial-title h3{
		font-size: 18px;
		font-weight: 900;
		line-height: 1.5em;
		text-align: center;
		color: #ff95a2;
}
.bf-testimonial-title p{
		font-size: 13px;
		font-weight: 500;
		line-height: 1.5em;
		text-align: center;
		color: #484848;
		letter-spacing:normal;
}
.bf-testimonial-slick .slick-dots {
    position: absolute;
    bottom: -60px !important;
}
.bf-testimonial-author-info{}
.bf-testimonial-author-info .author-name h4{
		    opacity: 1;
    font-size: 13px;
    font-weight: 600;
    line-height: 1em;
    text-align: center;
    color: #000000;
}
.bf-testimonial-author-info .author-designation h5{
	opacity: 0.3;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 15px;
  line-height: 1em;
  color: #ffffff;
}
.author-name h4:before {
	display:none;
}
.bf-testimonial-slick .slick-list {
    height: 260px;
}


.detailsservice {
     padding: 25px 25px 30px;
    background-color: rgb(255 149 162);
    background-image: linear-gradient(357deg, rgb(255 111 129) 0%, #ffc9d0 74%);
    position: relative;
    z-index: 999;
    margin: -30px 20px 0;
    box-shadow: 0 10px 20px rgb(0 0 0 / 14%);
    min-height: 135px;
    padding-bottom: 0px;
}
.img-holder {
    border: none;
    box-shadow: none;
    position: relative;
    overflow: hidden;
}
.img-holder img {
    width: 100%;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -ms-transition: all .5s;
    transition: all .5s;
}
.our-services .serv:hover .img-holder img {
    -webkit-transform: scale(1.2,1.2);
    -ms-transform: scale(1.2,1.2);
    transform: scale(1.2,1.2);
    -webkit-filter: blur(2.5px);
    filter: blur(2.5px);
}


.icon-ser {
    margin-bottom: 5px;
}
.icon-ser:after {
    position: absolute;
    top: 20px;
    left: 20px;
    content: "";
    width: 60px;
    height: 60px;
    background: rgb(247 23 53 / 12%);
    border-radius: 30% 70% 50% 50%/30% 41% 59% 70%;
    display: none;
}

.cspt-ihbox-style-5.cspt-ihbox {
    text-align: center;
    position: relative;
}
.cspt-steps-counter .vc_column_container:nth-child(1) .cspt-ihbox-style-5:before {
    content: '';
    top: 30px;
    right: -27%;
    z-index: 99;
    width: 170px;
    height: 50px;
    background: url(../img/arrow.png) no-repeat 0 0;
    display: block;
    position: absolute;
}
.cspt-ihbox-style-5 .cspt-ihbox-icon-wrapper {
    font-size: 40px;
    margin-bottom: 35px;
    width: 100px;
    height: 100px;
    color: #ff95a2;
    line-height: 95px;
    display: inline-block;
    border: 2px dashed #ff95a2;
    border-radius: 50%;
    position: relative;
}
.cspt-ihbox-style-5.cspt-ihbox h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
}
.vc_custom_heading {
    margin-bottom: 10px;
}
.cspt-ihbox-style-5 .cspt-ihbox-content {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 30px;
    line-height: normal;
    letter-spacing: normal;
}
.cspt-ihbox-icon-wrapper small {
    font-size: 18px;
    letter-spacing: normal;
    display: block;
}
.cspt-steps-counter .cspt-ihbox-style-5 .cspt-ihbox-icon-wrapper .numberstep {
    content: counter(csptbox1, decimal-leading-zero) " ";
    counter-increment: csptbox1;
    color: #fff;
    height: 40px;
    width: 40px;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    font-weight: 500;
    top: 0;
    right: 0;     letter-spacing: normal;
    z-index: 99;       background-color: #ff95a2;
}
.cspt-steps-counter .vc_column_container:nth-child(2) .cspt-ihbox-style-5:before {
    background: url(../img/arrow-down.png) no-repeat 0 0;
    top: 60px;
}




.cspt-row-inner {
    position: relative;
    z-index: 2;
}
.cspt-zindex-zero {
    z-index: unset;position: relative;
}
.vc_column_container {
    padding-left: 0;
    padding-right: 0;
}
.vc_column_container>.vc_column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.cspt-bg-color-yes.vc_column_container .vc_column-inner:before, .vc_row.cspt-bg-color-yes:not(.vc_column_container):before, .cspt-col-stretched-yes .cspt-stretched-div:before {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: "";
    display: block;
    z-index: 1;
}
.cspt-bg-color-yes.vc_column_container .wpb_wrapper {
    height: 100%;
}
.cspt-bg-color-yes.vc_column_container .wpb_wrapper, .vc_column_container.cspt-bg-color-yes .vc_column-inner, .cspt-row-inner {
    position: relative;
    z-index: 2;
}
.cspt-ihbox-style-5.cspt-ihbox {
    text-align: center;
    position: relative;
}
.cspt-steps-counter .vc_column_container:nth-child(2) .cspt-ihbox-style-5:before, .cspt-steps-counter .vc_column_container:nth-child(1) .cspt-ihbox-style-5:before {
    content: '';
    top: 30px;
    right: -27%;
    z-index: 99;
    width: 170px;
    height: 50px;
    background: url(../img/arrow.png) no-repeat 0 0;
    display: block;
    position: absolute;
}
.cspt-steps-counter .vc_column_container:nth-child(2) .cspt-ihbox-style-5:before {
    background: url(../img/arrow-down.png) no-repeat 0 0;
    top: 60px;
}
.image-content a:hover {
    color: #ff95a2;
    text-decoration: underline;
}

.themestek-ihbox-inner {
    position: relative;
    z-index: 2;
}
.themestek-ihbox-icon {
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
    color: #ff95a2;
	    display: table-cell;
    vertical-align: top;
}
.themestek-ihbox-contents {
    display: table-cell;
    vertical-align: top;
}
.themestek-ihbox-contents {
    padding-left: 20px;
}
.themestek-vc_general {
     background-color: transparent;
     border-color: transparent;
	 padding: 0;
	 border-radius: 5px;
	 border: 1px solid transparent;
    font-size: 1em;
    padding: 0px;
    word-wrap: break-word;
}
.themestek-custom-heading {
    font-size: 20px;
    line-height: 30px;
}
.themestek-vc_btn3-container {
    margin-top: 15px;
	display: inline-block;
    vertical-align: top;
	margin-bottom: 21.74px;
    max-width: 100%;
}

.vc_custom_banner {
    padding-top: 220px !important;
    padding-bottom: 220px !important;
    background-image: url(../img/video-bg-img.jpg) !important;
    background-position: 100% 60%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
}
.vc_custom_banner:after {
    background: #0000004f;
    content: "";
    height: 100%;
    left: 0;
    /* opacity: .6; */
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    overflow: hidden;
}

.video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  /* background: #fa183d; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ff95a2;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ff95a2;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: darken(#ff95a2, 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
	border-top: 22px solid transparent;
	border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.cardpcg {
	border:none !important;
	text-align:center;padding: 0px;
}
.cardpcg:focus:before, .cardpcg:hover:before {
    display:none;
}

.percent {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #fff;
    z-index: 1000;
    margin: 0px auto;
}
.percent .number {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.percent .number h2 {
  color: #000;
  font-weight: 700;
  font-size: 40px;
  transition: 0.5s;
}


.percent .number h2 span {
  font-size: 24px;
  color: #000;
}

.text {
    position: relative;
    color: #000;
    margin-top: 20px;
    font-weight: 900;
    font-size: 24px;
    letter-spacing: normal;
    text-transform: none;
    transition: 0.5s;
    line-height: 28px;
}

svg {
  position: relative;
  width: 150px;
  height: 150px;
  z-index: 1000;
}

svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #dfdfdf;
  stroke-width: 10;
  stroke-linecap: round;
  transform: translate(5px, 5px);
}

svg circle:nth-child(2) {
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
}

.cardpcg:nth-child(1) svg circle:nth-child(2) {
  stroke-dashoffset: calc(440 - (440 * 80) / 100);
  stroke: #ff95a2;
}

.cardpcg:nth-child(2) svg circle:nth-child(2) {
  stroke-dashoffset: calc(440 - (440 * 90) / 100);
  stroke: #ff95a2;
}



/*********** NEW BLOG CSS *********/

.blogbox {
	position: relative;
    display: block;
    overflow: hidden;
    max-height: 450px;
    border-radius: 15px;
	margin-bottom:20px;
	z-index: 1;
}
.blogbox:hover {
	-webkit-transform: translateY(-0.25rem);
    -ms-transform: translateY(-0.25rem);
    transform: translateY(-0.25rem);
    box-shadow: 0px 2px 4px rgb(46 41 51 / 8%), 0px 5px 10px rgb(71 63 79 / 16%);
	transition: transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
	-webkit-transition: -webkit-transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
    -webkit-transition: transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
}
.blogbox img {
	max-height: 450px;
	object-fit: cover;
	height: 450px;
	width: 100%;
}
.ovlay {
	height: 100%;
    width: 100%;
    background: linear-gradient( 0deg, #2d3748 0%, transparent 100% );
    position: absolute;
    z-index: 3;
    padding: 1rem;    border-radius: 15px;
}
.contentbox-blog {
    position: absolute;
    z-index: 9;
    bottom: 0px;
    width: 100%;
    height: auto;
    padding: 20px;
    justify-content: flex-end;
    align-items: flex-end;
    /* display: flex; */
    flex-flow: wrap;
    padding-bottom: 10px;
}
.contentbox-blog .profileimg img  {
	width:60px;
	height:60px;
	border-radius:100px;    
	margin-right: 10px;
}
.contentbox-blog h3 {
        color: #fff;
    line-height: 26px;
    max-height: 55px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    transition: .15s ease-in-out;
    font-size: 22px;
    text-align: left;
    flex: 0 0 100%;
    max-width: 100%;
       height: auto;
    max-height: 55px;
    margin-bottom: 0px;
}
.profileimg {
    float: left;
    display: flex;
    width: 100%;
    margin-top: 0px;
	flex: 0 0 100%;
    max-width: 100%;
}
.contentbox-blog .profileimg span {
    font-size: 16px;
    color: #fff;
}
.contentbox-blog .profileimg small {
    font-size: 12px;
    color: #fff;
}

.small-blog-card {
	position: relative;
    display: block;
    overflow: hidden;
    border-radius: 15px;
	margin-bottom:20px;
	background:#fff;box-shadow: 1px 1px 5px 0 rgb(1 1 1 / 5%);
}
.small-blog-card:hover {
	cursor:pointer;
	-webkit-transform: translateY(-0.25rem);
    -ms-transform: translateY(-0.25rem);
    transform: translateY(-0.25rem);
    box-shadow: 0px 2px 4px rgb(46 41 51 / 8%), 0px 5px 10px rgb(71 63 79 / 16%);
	transition: transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
	-webkit-transition: -webkit-transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
    -webkit-transition: transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
}
.blog-inner-content {
	box-sizing: border-box;
    margin: 0;
    min-width: 0;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    height: 100%;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
	    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    border-left-style: solid;
    border-left-width: 5px;
    border-left-color: #ff95a2;
}
.leftimg-blog {
    flex-basis: 33.333333%;
    margin: 0.5rem;
    margin-right: 0;
}
.leftimg-blog img {
    height: 100%;
    vertical-align: middle;
    min-height: 150px;
    max-height: none;
    display: inline-block;
    position: relative;
    overflow: hidden;
    object-fit: cover;
}

.blog-rightcontent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
        padding: 0.8rem;
    text-align: left;
}
.box-innerbogmsmall {
    display: inline-block;
	box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: inline-block;
    margin-bottom: 10px;
}

.tag-bloginner {
    display: inline-block;
    vertical-align: baseline;
    font-size: 0.75rem;
    font-weight: 700;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 2px;
    color: var(--theme-ui-colors-white,#fff);
    background-color: primary;
    border-radius: 0.5rem;
    text-decoration: none;
    font-weight: 600;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    background-color: #dfddde;
    color: #2d3748;
    margin-bottom: 0px;
}

.title-blogname {
    font-weight: 500;
    text-decoration: none;
    display: block;
    margin-bottom: 0.5rem;
    font-size: 15px;
    color: #000;
    line-height: 22px;
    max-height: 60px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -webkit-transition: .15s ease-in-out;
    transition: .15s ease-in-out;
}
.title-blogname:hover {
	text-decoration:none;
	color:#000;	
}
.bloguser-name {
	font-size:14px;
	    color: #4a4a4a;
	margin-bottom:0px;
}
.blog-posttime {
	font-size:12px;
	color:#ddd;
	margin-bottom:0px;
}


.news-block-two .inner-box {
    position: relative;
    margin-bottom: 30px;
}
.news-block-two .image img {
    border-radius: 6px;
    width: 100%;
    height: 250px;
    max-width: 100%;
    border: none;
    border-radius: 8px;
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 1;
    transition: opacity 400ms;
    transition-delay: 0ms;
    object-fit: cover;
}
.news-block-two .lower-content {
    position: relative;
    background-color: #fff;
    border-radius: 6px;
    margin-left: 11px;
    margin-right: 11px;
    margin-top: -50px;
    padding: 0 23px 25px;
    -webkit-box-shadow: 0 0 62px 0 rgb(27 31 45 / 9%);
    box-shadow: 0 0 62px 0 rgb(27 31 45 / 9%);
}
.news-block-two .lower-content:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: -moz-linear-gradient(90deg,#191d2a 0,#30364d 75%);
    background-image: -webkit-linear-gradient(90deg,#191d2a 0,#30364d 75%);
    background-image: -ms-linear-gradient(90deg,#191d2a 0,#30364d 75%);
    opacity: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    -webkit-transform: perspective(400px) rotateX(-90deg);
    -ms-transform: perspective(400px) rotateX(-90deg);
    transform: perspective(400px) rotateX(-90deg);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    border-radius: 6px;
}
.news-block-two .category {
    position: relative;
    font-size: 13px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 12px;
    padding: 3px 21px;
    -webkit-transform: translateY(-12px);
    -ms-transform: translateY(-12px);
    transform: translateY(-12px);
    margin-bottom: 10px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    background: #292f43;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    line-height: 18px;
}
.news-block-five .category a, .news-block-one .category a, .news-block-two .category a {
    color: #fff;
}
.news-block-two .post-meta {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0px;
	    list-style: none;
    padding-left: 0px;
}
.news-block-two .post-meta li {
    font-size: 15px;
    font-weight: 500;
    color: grey;
    margin-right: 5px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.news-block-two .post-meta li a {
	color:grey;
}
.news-block-two h4 {
        position: relative;
    font-size: 20px;
    font-weight: 600;
    color: #202020;
    padding-bottom: 10px;
    margin-bottom: 0px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    text-transform: none;
    letter-spacing: normal;padding-left: 0px;
}
.news-block-two h4 a {
    color: #202020;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
	line-height: 25px;
    max-height: 48px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -webkit-transition: .15s ease-in-out;
    transition: .15s ease-in-out;
}
.news-block-two h4 a:hover {
    text-decoration:none;
}
.news-block-two h4::before {
	display:none;
}
.news-block-two .read-more-link {
    position: relative;
    padding-left: 0px;
    font-size: 14px;
    font-weight: 500;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    color: #ff95a2;
}

/***** news single page ****/

.title-singlenws {
	    font-size: 50px;
    font-weight: 800;
    margin-bottom: 0px;
}
.single-new-detail {
	box-sizing: border-box;
    margin: 0px;
    min-width: 0px;    text-align: left;
	margin-bottom:20px;
}
.single-new-detail .byadmin  {
	    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: inline-block;
    margin-right: 10px;
    padding-right: 10px;
}
.single-new-detail .byadmin .adminame {
	box-sizing: border-box;
    margin: 0;
    min-width: 0;
    color: var(--theme-ui-colors-omegaDark,#718096);
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.single-blog0igg {
	    width: 100%;
    height: 450px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 0px 7px #00000059;
	margin-bottom:20px;
}
.ptb-60 { padding:70px 0px;}

.singleblog-post p {
	text-align:left;
	margin-top:10px;
	margin-bottom:10px;
}
blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 40px!important;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    margin-top: 30px;
    font-size: 24px;
    color: #001733;
}
blockquote:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #ff95a2;
    margin-top: 20px;
    margin-bottom: 20px;
}
blockquote:before {
    color: #efefef;
    content: "\f10e ";
    position: absolute;
    left: 50px;
    top: -50px;
    z-index: -1;
    font-family: "fontawesome";
    font-size: 140px;
    font-weight: 900;
}
blockquote .post-desc p {
    line-height: 1.8;
}
blockquote p {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 20px !important;
    line-height: 30px !important;
}
blockquote .post-excerpt p strong {
    color: #263a4f;
    font-weight: 700;
}

.single-tags.post-tags {
    margin-top: 15px;
}
.single-tags.post-tags {
    border-top: 1px solid #bfbfbf;
    border-bottom: 1px solid #bfbfbf;
    padding: 15px 0 5px;
    text-align: left;
}
.single-tags.post-tags a {
    margin-bottom: 10px;
    font-size: 16px!important;
}
.post-tags a {
    border: 1px solid #e9e9e9;
    background-color: #fff;
    display: inline-block;
    font-size: 14px!important;
    margin-bottom: 10px;
    margin-right: 3px;
    padding: 5px 18px;
    border-radius: 0;
    color: #4e4e4e;
    text-transform: capitalize;
    transition: all .3s ease 0s;
    o-transition: all .3s ease 0s;
    -ms-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -webkit-transition: all .3s ease 0s;
}
.post-tags a:hover {
	text-decoration:none;
	color:#fff;
	background:#ff95a2;
}
.share-details-btn {
    position: relative;
    margin-top: 30px;
    margin-bottom: 24px;
	text-align:left;
}
.share-details-btn ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.share-details-btn ul li {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 6px;    margin-left: 0px;
}
.share-details-btn ul li h5 {
    margin: 0;
    vertical-align: middle;
    display: inline-block;
}
.post-desc h5 {
    font-size: 18px;
    margin: 30px 0;
    font-weight: 600;
}
.site-button.facebook {
    background-color: #3b5998;
}
.site-button.linkedin {
    background-color: #209bd6;
}
.site-button.instagram {
    background-color: #8a5a4e;
}
.site-button.twitter {
    background-color: #1ea1f3;
}
.site-button.whatsapp {
    background-color: #01c854;
}


.button-sm {
    padding: 5px 10px;
    font-size: 14px;
}
.site-button, .site-button-secondry {
    color: #fff !important;
    padding: 5px 15px;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0 solid transparent;
    border-radius: 0;
    font-weight: 600;
    text-align: center;
    white-space: pre;
    border-radius: 4px;
}
.button-sm:hover {
	text-decoration:none;
}


/* Testimonial Spacing */
.bf-testimonial-icon {
    margin-bottom: 13px;
}
.bf-testimonial-title {
    margin-bottom: 20px;
}
.bf-testimonial-message {
    padding: 0 50px;
    margin-bottom: 45px;
}
.bf-testimonial-author-info .author-name {
    margin-bottom: 10px;
}


/******* CUSTOMER PROFILE CSS ************/

.coverphoto {
    background-image: url(../img/07.jpeg);
    overflow: visible;
    position: relative;
    z-index: 1;
    height: 190px !important;
    min-height: 190px;
    background-position: center;
    padding: 0px;
}
.pto-profile {
	padding:0px;
	position: relative;
    z-index: 1;
}
.pto-profile .avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 0px;
    margin-top: -90px;
}
.customename {
	margin-bottom: 0px;
    padding-top: 10px;
    font-size: 20px;
    font-weight: 600;
}
.cusotmemail {
	font-size:14px;
}
.lgout {
    position: absolute;
    top: 0px;
    right: 0px;
    background: #ff95a2;
    color: #fff;
    padding: 10px 20px;
    display: inline-block;
    margin-top: 10px;
}
.lgout:hover {
	text-decoration:none;
	color:#fff;
}

.p40 {
	padding:40px 0px;
}


.user-profile-menu-wrap {
    box-shadow: 0px 0px 12px #e8f0fd;
    background: #fff;
    border-radius: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    float: left;
}
.user-profile-menu {
    padding-bottom: 0px;
    float: left;
    width: 100%;
    position: relative;
}
.user-profile-menu .no-list-style {
    list-style: none;
    padding-left: 0px;
    float: left;
    width: 100%;
    padding-top: 0px;
    margin-bottom: 0px;
}
.user-profile-menu li:first-child {
    border-top: none;
}
.user-profile-menu li:last-child {
    border-bottom: none;
	margin-bottom:0px;
}
.user-profile-menu li {
    width: 100%;
    text-align: left;    position: relative;
    float: left;margin-left: 0px;
    border-bottom: 1px solid #eee;    margin-bottom: 0px;
}
.user-profile-menu li a.user-profile-act, .user-profile-menu li a:hover, .user-profile-menu li.submenu-act a, .user-profile-menu li.submenu-act ul li a:hover {
    background: #f9f9f9;
	text-decoration:none;
}
.user-profile-menu li a {
    font-weight: 400;
    color: #000;
    position: relative;
    display: block;
    padding: 8px 20px;
    font-size: 14px;
}
.user-profile-menu li a.user-profile-act:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background: #ff95a2 !important;
}
.user-profile-menu li a:hover i, .user-profile-menu li a.user-profile-act i {
    opacity: 1;
}
.user-profile-menu li a i {
    margin-right: 14px;
    position: relative;
    top: 10px;
    width: 12px;
    text-align: center;
    float: left;
}

.filte-category {
	display: none !important;
    background: #ff95a2 !important;
    color: #ffffff !important;
    border-radius: 0px;
    font-weight: bold;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none;
    text-align: center;
    margin: 10px auto;
    width: auto;
    padding: 14px;
    border: none;
}
.filte-category:hover {
	text-decoration:none;
}
.modal.left .modal-dialog {
	position: fixed;
	margin: auto;
	width: 340px;
	height: 100%;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}
.modal.left .modal-content {
	height: 100%;
	overflow-y: auto;
}
.modal.left .modal-body {
	padding: 15px 15px 80px;
}
.modal.left.fade .modal-dialog{
	left: 0px;
	-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
	-o-transition: opacity 0.3s linear, left 0.3s ease-out;
	transition: opacity 0.3s linear, left 0.3s ease-out;
}
.modal.left.fade.in .modal-dialog{
	left: 0;
}
.modal-title::before {
	display:none;
}
.modal-header button::after {
	display:none;
}


/***** MOVE TAB ******/

.admoves {
    background: #fff;
    color: #ff95a2;
    padding: 10px 20px;
    display: inline-block;
    margin-top: 0px;
    float: right;
    border: 1px solid #ff95a2;
}
.admoves:hover {
	text-decoration:none;
	color:#fff;
	background:#ff95a2;
}

#tsum-tabs { 
   margin-top:20px;
}

#tsum-tabs h1 {
  padding: 50px 0;
  font-weight: 400;
  text-align: center;
}

#tsum-tabs p {
  margin: 0 0 20px;
  line-height: 1.5;
}

#tsum-tabs main {
  min-width: 320px;
  max-width: 800px;
  padding: 50px;
  margin: 0 auto;
  background: #fff;
}

#tsum-tabs section {
  display: none;
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}

#tsum-tabs input {
  display: none;
}

#tsum-tabs label {
    display: table-cell;
    margin: 0 0 -1px;
    padding: 15px 25px;
    font-weight: 600;
    text-align: center;
    color: #000;
    border: 1px solid transparent;
    width: 1%;
}

#tsum-tabs label:before {
  font-family: fontawesome;
  font-weight: normal;
  margin-right: 10px;
}

#tsum-tabs label:hover {
  color: #888;
  cursor: pointer;
}
#tsum-tabs input:checked + label {
    color: #ff95a2;
    border: none;
    border-top: none;
    border-bottom: 3px solid #ff95a2;
}

#tsum-tabs #tab1:checked ~ #content1,
#tsum-tabs #tab2:checked ~ #content2,
#tsum-tabs #tab3:checked ~ #content3,
#tsum-tabs #tab4:checked ~ #content4 {
  display: block;
}
.mymovesbox .itemdetail .col-lg-4 {
    padding-right:0px;
}
.mymovesbox .itemdetail .cardbox {
    display: block;
    width: 100%;
    align-items: center;
    float: left;
    border: 1px solid #d1d1d1;
    padding: 5px;
}


.mymovesbox .cardbox img {
    width: 100%;
    height: 130px;
    object-fit: cover;
    margin-bottom: 10px;
    float: left;
    margin-right: 0px;
    border-radius: 0px;
}
.mymovesbox .itemdetail .cardbox h5 {
    top: 0px;
        margin-bottom: 10px;
}
.mymovesbox .cardbox h5 {
       text-align: left;
    font-size: 16px;
    letter-spacing: normal;
    color: #ff95a2;
    margin-bottom: 10px;
    position: relative;
    top: 0px;
	font-weight:bold;
    display: block;
    float: left;
    width: 100%;
}
.mymovesbox .cardbox h6 {
    text-align: left;
    font-size: 14px;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 5px;
    position: relative;
    top: 0px;
    display: block;
    float: left;
    text-transform: none;
    width: 100%;
}
.mymovesbox .cardbox p {
    text-align: left;
    font-size: 12px;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 10px;
    position: relative;
    top: 0px;margin-bottom: 5px !important;
	 display: block;
    float: left;
    width: 100%;
}
.mymovesbox .cardbox i {
	    color: #ff95a2;
    margin-right: 10px;
font-size: 12px;}
.mymovesbox .itemdetail .delete {
        background: #585454;
    color: #fff;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 5px;
    font-size: 12px;
    height: 27px;
    line-height: 15px;
}
.mymovesbox .itemdetail .delete:hover,  .mymovesbox .itemdetail .editbtn {
text-decoration:none;
}
.mymovesbox .itemdetail .editbtn {
    background: #ff95a2;
    color: #fff;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 5px;
    font-size: 12px;
    height: 27px;
    line-height: 15px;
    margin-right: 5px;
}
.editbtn-complete {
    color: #008000;
    background: #fff;
    padding: 1px;
    display: block;
    float: left;
    width: 100%;
    text-align: right;
    font-size: 16px;
    border: none;
    line-height: 15px;
    height: auto;
    font-weight: 400;
}
.delete-caclled {
    color: #f00;
    background: #fff;
    padding: 1px;
    display: block;
    float: left;
    width: 100%;
    text-align: right;
    font-size: 16px;
    border: none;
    line-height: 15px;
    height: auto;
    font-weight: 400;
}


.repotrpbolem {
    padding-bottom: 60px;
    max-width: 100%;
    padding-top: 0px;
}
.repotrpbolem .innereport .form-group.check-group {
    margin-bottom: 15px;
}
.repotrpbolem .innereport .form-group {
    position: relative;
    margin-bottom: 25px;
}
.repotrpbolem .innereport .form-group textarea.form-control {
    border-radius: 5px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 25px 15px 0;
    font-weight: 400;
    line-height: 80px;
    color: #546274 !important;
	height: 140px;
    padding-top: 25px;
    line-height: 1.7;
    border: 2px solid rgba(0, 0, 0, 0.08);
    resize: none;
    font-size: 16px;
	
}
.repotrpbolem .innereport .form-group label {
    position: absolute;
    top: 18px;
    margin-bottom: 0;
    left: 17px;
    z-index: 2;
    font-size: 14px;
    font-weight: 400;
    color: #546274;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: text;
    letter-spacing: normal;
}
.repotrpbolem .innereport label {
    display: block;
    font-size: 16px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    letter-spacing: 3px;
    line-height: 20px;
    margin: 0 0 30px;
}
.repotrpbolem .innereport .form-group .form-control:valid {
    border-color: rgb(247 186 191);
    color: #546274 !important;
}

.repotrpbolem .innereport .form-group .form-control:valid + label {
    top: 7px;
    font-size: 14px;
    color: #ff95a2;
    letter-spacing: normal;
}
.repotrpbolem .innereport .form-group .form-control:focus, .repotrpbolem .innereport .form-group .form-control:valid {
    border-color: rgb(247 186 191);
    color: #546274 !important;
}
.border-bottom {
    border-bottom: 1px solid #f2f3f7 !important;
}
.p-3 {
    padding: 1rem !important;
}
.dropdown-list-image {
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
}
.dropdown-list-image img {
    height: 2.5rem;
    width: 2.5rem;
}
.rounded-circle {
    border-radius: 50% !important;
}
.notification-hrf div {
    font-family: 'Josefin Sans', sans-serif;
}
.notification-hrf a {
    color: #000;
}
.notification-hrf a:hover{
	text-decoration:none;
	color:#000;
}
.noimg-img {
	width:120px;
	display:block;
	margin:0px auto;
	margin-bottom:20px;
}
.movedetail-boxcustomer {
	width:100%;
}
.movedetail-boxcustomer .itemstep .cardbox {
    display: block;
    width: 100%;
    align-items: center;
    float: left;
}
.movedetail-boxcustomer .add-item {
    padding: 5px;
    border: 1px solid #ededed;
    margin-bottom: 0px;
}
.movedetail-boxcustomer .itemstep .cardbox img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-bottom: 0px;
    float: left;
    margin-right: 20px;
    border-radius: 0px;
}
.movedetail-boxcustomer .itemstep .cardbox h5 {
         text-align: left;
    font-size: 14px;
    margin-bottom: 10px;
    position: relative;
    top: 5px;
    font-weight: 700;
    color: #000;
}
.movedetail-boxcustomer .itemstep .cardbox p {
	margin-bottom: 0px;
    font-size: 14px;
}
.movedetail-boxcustomer .itemstep .cardbox p a {
	    color: #ff95a2;
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
}
.movedetail-boxcustomer .itemstep .t-item {
    display: inline-block;
    background: #ff95a2;
    padding: 5px;
    border: none;
    height: auto;
    font-size: 14px;
    color: #fff;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 10px;
    margin-left: 5px;
    float: right;
}
.pick-l-detail {
	display: flex;
    padding: 10px;
    background: #fff;
    border: 1px solid #ededed;
    font-size: 14px;
    width: 100%;
	margin-bottom:10px;
}
.pick-l-detail h5 {
    font-size: 14px;
    width: 50%;
	text-align:left;
	margin-bottom:0px;
}
.pick-l-detail h6 {
        font-size: 13px;
    width: 50%;
    margin-bottom: 0px;
    text-align: right;
    text-transform: none;
    color: #ff95a2;
    font-weight: 700;
}
.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    font-size: 30px !important;
}
.charge-model .last-itme-box {
    height: 50px;
}
.charge-model .last-itme-box h6 {
    width: 50%;
    font-family: 'Montserrat';
}
.charge-model .edit {
    font-size: 14px;
    width: 50%;
    text-align: right;
}
.charge-model .edit a {
    font-size: 16px;
	color:#ff95a2;
}
.charge-model .fontname a {
    font-size: 13px !important;
    font-weight: bold;
    padding-top: 10px !important;
    color: #330eff;
    text-decoration: underline;
}
    


 /* MAP CITY PAGE */

.bocform {
    padding: 20px;
    margin-top: 30px;
    border: 1px solid #f9b0b6;
    border-radius: 5px;
    background: #fff;
}

.form-control {
    height: 54px;
    font-size: 14px;
}
.footer-black { margin: 0px !important;}
.live__map_wrap {
    position: relative;
    padding: 3rem 0;
}

.live__map_wrap .loc_map-mark {
    position: absolute;
    z-index: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.live__map_wrap .loc_map-mark img {
    width: 22px;
}
.live__map_wrap .map_img {
    width: 100%;
}
.servingbox {
    width: 100%;
    float: left;
    padding: 10px 20px;
    margin-bottom: 20px;
    border-radius: 14px;
    text-align: left;
}
.servingbox img {width: 30px; position: relative; top: -6px;}

.newtop {
        margin-top: 250px;
    padding: 0px;
}
.citylist {
    text-align: left;
    list-style: none;
    padding-left: 0px;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.citylist li {
    background: #fff;
    color: #000;
    padding: 0px 10px;
    display: flex;
    margin-bottom: 5px;
    border-radius: 0px;
    font-size: 13px;
    border-right: 1px solid #000;
    font-weight: 700;
    position: relative;
    margin-left: 0px;
    align-items: center;
    gap: 11px;
}
.citylist li:last-child {
    border-right: none;
}
.citylist1 {
    text-align: left;
    list-style: none;
    padding-left: 0px;
    position: relative;
}
.citylist1 li {
        background: #fff;
    color: #000;
    padding: 0px 10px;
    display: inline-block;
    margin-bottom: 5px;
    border-radius: 0px;
    font-size: 13px;
    border-right: 1px solid #000;
    font-weight: 700;
    margin-left: 0px;
}
.citylist1 li:last-child {
    border-right: none;
}
.citylist li a {
    color: #000;
}
.pulse {
      background: rgb(249 104 122);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 5px 0px 0px -7px;
  -webkit-transform: rotateX(55deg);
  -moz-transform: rotateX(55deg);
  -o-transform: rotateX(55deg);
  -ms-transform: rotateX(55deg);
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -18px;
  -webkit-animation: pulsate 1s ease-out;
  -moz-animation: pulsate 1s ease-out;
  -o-animation: pulsate 1s ease-out;
  -ms-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-box-shadow: 0 0 1px 2px #89849b;
  box-shadow: 0 0 1px 2px #89849b;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  animation-delay: 1.1s;
}
@-moz-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-moz-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@-o-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

.cituform-box {
	 padding: 40px;
}

.cituform-box.login-page {
    margin: 0em 0;
}
.n-badge {
	position: absolute;
    top: 15px;
    right: 10px;
    background: #ff95a2;
    color: #fff;
    font-size: 14px;
    padding: 5px;
    padding-bottom: 2px;
}

.modal-confirm {		
	color: #636363;
	width: 400px;
}
.modal-confirm .modal-content {
	padding: 20px;
	border-radius: 5px;
	border: none;
	text-align: center;
	font-size: 14px;
}
.modal-confirm .modal-header {
	border-bottom: none;   
	position: relative;
}
.modal-confirm h4 {
	text-align: center;
	font-size: 26px;
	margin: 30px 0 -10px;
}
.modal-confirm .close {
	position: absolute;
	top: -5px;
	right: -2px;
}
.modal-confirm .modal-body {
	color: #999;
}
.modal-confirm .modal-footer {
	border: none;
	text-align: center;		
	border-radius: 5px;
	font-size: 13px;
	padding: 10px 15px 25px;
}
.modal-confirm .modal-footer a {
	color: #999;
}		
.modal-confirm .icon-box {
	    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 50%;
    z-index: 9;
    text-align: center;
    border: 3px solid #ff0000;
    line-height: 40px;
}
.modal-confirm .icon-box i {
	color: #ff0000;
	font-size: 46px;
	display: inline-block;
	margin-top: 13px;
}
.modal-confirm .btn, .modal-confirm .btn:active {
	color: #fff;
	border-radius: 4px;
	background: #60c7c1;
	text-decoration: none;
	transition: all 0.4s;
	line-height: normal;
	min-width: 120px;
	border: none;
	min-height: 40px;
	border-radius: 3px;
	margin: 0 5px;
}
.modal-confirm .btn-secondary {
	background: #c1c1c1;
}
.modal-confirm .btn-secondary:hover, .modal-confirm .btn-secondary:focus {
	background: #a8a8a8;
}
.modal-confirm .btn-danger {
	background: #ff95a2;
}
.modal-confirm .btn-danger:hover, .modal-confirm .btn-danger:focus {
	background: #ff95a2;
}
.modal-footer button:after {
	display:none;
}
#delete-model {
	padding-right:0px;
}


/*
 *  STYLE 4
 */

#style-4::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #fff;
}

#style-4::-webkit-scrollbar
{
	width: 3px;
	background-color: #fff;
}

#style-4::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 1px solid #555555;
}
.notification-scroll {
    overflow-y: auto;
    overflow-x: hidden;
	max-height:450px;
}
#pagination {
  margin: 0;
  padding: 0;
  text-align: center
}
#pagination li {
    display: inline;
    margin-left: 0px;
}
#pagination li a {
  display: inline-block;
  text-decoration: none;
  padding: 0px 10px;
  color: #000
}

/* Active and Hoverable Pagination */
#pagination li a {
  border-radius: 5px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s
    
}
#pagination li a.active {
  background-color: #ff95a2;
  color: #fff
}
#pagination li a:hover:not(.active) {
  background-color: #ddd;
} 

.n-ficaition-img {
	 width: 200px;
    height: 35px;
}
.n-ficaition-img img {
	width: 35px;
    height: 35px;
}


/************ HELPER PAGES CSS PROFILE *****************/

.coverphoto-helper {
    background-image: url(../img/home-video-bg.jpg);
    overflow: visible;
    position: relative;
    z-index: 1;
    height: 240px !important;
    min-height: 240px;
    background-position: top;
    padding: 0px;
}

.pto-profile h6 {
    text-align: left;
    padding-bottom: 12px;
    font-weight: 300;
    padding-right: 0px;
    font-size: 12px;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: inline-block;
    float: left;
	letter-spacing: normal;
    font-weight: 500;
}
.ratingproflile {
        background: #ff95a2;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    margin-right: 5px;
    margin-top: 0px;
    text-align: center;
    padding-right: 5px;
}
.border-bottom {
    border-bottom: 1px solid #f2f3f7 !important;
}
.p-3 {
    padding: 1rem !important;
}
.p-10all-side {
    padding: 5px 15px;
}
.maptxt {
        display: flex;
    width: 100%;
    float: left;
    font-size: 13px;
    font-weight: 600;
}
.maploc {
    display: flex;
    width: 20px;
    float: left;
    padding-top: 5px;    color: #ff95a2;
}
.maploc-1 {
    display: flex;
    width: 20px;
    float: left;    color: #ff95a2;
    padding-top: 4px;
}
.price-helper {
    font-size: 18px;
    font-weight: bold;
    color: #000;
	padding-top:0px;
}
.accpet-helpebtn {
    color: #fff;
    background-color: #ff95a2;
    border-color: #ff95a2;
    border-radius: 0px; display: inline-block;
    font-size: 14px; margin-bottom:5px;
    padding: 8px 30px;transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.accpet-helpebtn:hover {
    color: #fff; background-color: #ff95a2;
    text-decoration:none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-card-verifycation {
    background: white;
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0 2px 2px 2px rgb(239 234 234);
    padding: 20px 20px 30px 20px;
    box-sizing: border-box;
    width: 100%;
    margin: 0 0% 28px 0%;padding-bottom: 0px;
    position: relative;float: left; width: 100%;
}
.form-card-verifycation .fs-title {
    font-size: 20px;
    color: #2C3E50;
    margin-bottom: 24px;
    font-weight: bold;
    text-align: center;
}
.imgpnd {
    text-align: center;
    display: block;
    margin: 0px auto;
    width: 100px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.form-card-verifycation h4 {
    letter-spacing: normal;
    font-size: 18px;    padding-left: 0px;
    text-transform: none;
}
.form-card-verifycation h4:before {
	display:none;
}

.vechicleinfo > .form-row > input, .vechicleinfo > .form-row > button {
    height: 50px;
    border-radius: 0px;
    font-family: 'Josefin Sans', sans-serif;
    padding: 15px 15px;
    width: 100%;
    margin: 0px auto;
}
.vechicleinfo > .form-row > span {
    left: 20px;
}
.vechicle-insurance-pic {
	 margin-right: 15px;
    display: block;
    float: left;
    margin-bottom: 15px;
}

.vechicle-insurance-pic .avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 0px;
    float: left;
}
.vechicle-insurance-pic .avatar-upload .avatar-preview {
    width: 160px;
    height: 160px;
    position: relative;
    border-radius: 0px;
    border: 2px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 10%);
}
.vechicle-insurance-pic .avatar-upload .avatar-preview > div {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.vechicle-insurance-pic .avatar-upload .avatar-edit {
    position: absolute;
    right: 5px;
    z-index: 1;
    top: 5px;
}
.vechicle-insurance-pic .avatar-upload .avatar-edit input + label {
    width: 26px;
    height: 26px;
}
.vechicle-insurance-pic .avatar-upload .avatar-edit input + label:after {
    top: 5px;
    font-size: 12px;
}
.trashpostion {
	position: absolute;
    right: 10px !important;
    z-index: 1;
    top: 10px;
}
.trash-vehicle {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #ff002b;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 12%);
    cursor: pointer;
    font-weight: normal;
	color:#fff !important;
    transition: all 0.2s ease-in-out;
	    text-align: center;
    line-height: 30px;
}
.active-service {
    font-size: 12px;
    background: #ff95a2;
    color: #fff;
    display: inline-block;
    padding: 3px 10px;
    border-radius: 100px;
    line-height: 12px;
    padding-bottom: 2px;
}
.price-helper-red {
	color:red;
	font-size: 18px;
    font-weight: bold;
    padding-top: 0px;
}
.price-helper-green {
	color:green;
	font-size: 18px;
    font-weight: bold;
    padding-top: 0px;
}


.radio-btn-w-9form {
    padding-bottom: 0px;
    max-width: 100%;    width: 100%;
    padding-top: 0px;
}
.radio-btn-w-9form .job-application-form .form-group.radio-group {
    margin-bottom: 15px;
    padding-bottom: 0px;
    position: relative;
    border-bottom: none;    display: inline-block;
    margin-right: 20px;
}
.radio-btn-w-9form .job-application-form .form-group.radio-group:last-child {
    margin-bottom: 0px;
}
.radio-btn-w-9form .job-application-form .form-group.radio-group .custom-radio .custom-control-input {
    display: none;
}
.radio-btn-w-9form .job-application-form .form-group.radio-group .custom-radio .custom-control-label {
    width: auto;
    text-align: left;
    position: static;
    top: auto;
    left: auto;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    padding-left: 30px;
    cursor: pointer;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 400;
    color: #1c2437;
    letter-spacing: normal;
}
.radio-btn-w-9form .job-application-form .form-group label {
    position: absolute;
    top: 18px;
    margin-bottom: 0;
    left: 17px;
    z-index: 2;
    font-size: 14px;
    font-weight: 400;
    color: #546274;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: text;
    letter-spacing: normal;
}
.radio-btn-w-9form .job-application-form .form-group.radio-group .custom-radio .custom-control-label:before {
    top: 0px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}
.radio-btn-w-9form .job-application-form .form-group.radio-group .custom-radio .custom-control-label:after {
    top: 5px;
    left: 5px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #ff95a2;
    opacity: 0;
    content: "";
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.radio-btn-w-9form .job-application-form .form-group.radio-group .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    border-width: 2px;
    border-color: #ff95a2;
    background: #ffffff;
}
.radio-btn-w-9form .job-application-form .form-group.radio-group .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    opacity: 1;
}

.add-addressw0form .job-application-form .addressfleld .form-group.check-group {
    margin-bottom: 15px;position: relative;    width: 100%;
}
.add-addressw0form .job-application-form .addressfleld .form-group textarea.form-control {
    height: 80px;
    padding-top: 25px;
    line-height: 1.7;
    border: 0.075em solid #ddd;
    resize: none;
    font-size: 16px;
	border-radius: 0px;
    outline: none;
    box-shadow: none;
    padding: 10px 15px 0;
    font-weight: 400;
    color: #546274 !important;
}
.add-addressw0form .job-application-form .addressfleld .form-group label {
    position: absolute;
    top: 18px;
    margin-bottom: 0;
    left: 17px;
    z-index: 2;
    font-size: 14px;
    font-weight: 400;
    color: #546274;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: text;
    letter-spacing: normal;
}
.add-addressw0form .job-application-form .addressfleld .form-group .form-control:focus, 
.add-addressw0form .job-application-form .addressfleld .form-group .form-control:valid {
    border-color: rgb(247 186 191);
    color: #546274 !important;
}
.add-addressw0form .job-application-form .addressfleld .form-group .form-control:valid + label {
    top: 7px;
    font-size: 14px;
    color: #ff95a2;
    letter-spacing: normal;
}
.fancy-title {
    line-height: 0.5;
    text-align: left;
    display: block;
    width: 100%;
        padding: 0px 75px;
}
.fancy-title span {
    display: inline-block;
    position: relative;
    color: #000;
    font-weight: 600;
}
.fancy-title span:before {
    right: 100%;
    margin-right: 15px;
}
.fancy-title span:before, .fancy-title span:after {
    content: "";
    position: absolute;
    height: 5px;
    /* border-bottom: 1px solid #000; */
    border-top: 1px solid #000;
    top: 5px;
    width: 60px;
}
.fancy-title span:after {
    left: 100%;
    margin-left: 15px;
}

.helper-register .cardbox h5 {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
}
.helper-register .cardbox h6 {
    color: #000;
    font-size: 13px;
    text-align: left;
    letter-spacing: normal;
} 
.stepone .helper-register .cardbox img {
    width: 100%;
    height: 190px;
}
.stepone .helper-register .checkbox-tools:not(:checked) + label {
    background-color: #eff1f3;
    box-shadow: none;
    border-radius: 0px;
    border: 2px solid #f3f3f3;
    display: block;
    width: 100%;
    height: 310px;
}
.helper-register .checkbox-tools:checked + label, .helper-register .checkbox-tools:not(:checked) + label {
	height: 310px;
}
.helper-register .helperbtn {
	height: 55px;
    width: auto;
    display: inline-block;
    background: #ff95a2;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    border-radius: 0px;
    outline: none;
    box-shadow: none;
    padding: 6px 26px;
    letter-spacing: normal;
    position: relative;
    top: -3px;
    line-height: 45px;
    text-transform: uppercase;
}
.helper-register .helperbtn:hover {
	text-decoration:none;
}
.check-helper .form-group.check-group .custom-checkbox .custom-control-label {
    font-size: 15px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
}

.helper-leftbg {
   background: url(../img/heper-bgmoveit.jpg) no-repeat !important;
       background-position: 80% 80% !important;
    background-size: cover !important;
}
#ui-datepicker-div {
	display: none;
	background-color: #fff;
	box-shadow: 0 0.125rem 0.5rem rgba(0,0,0,0.1);
	margin-top: 0.25rem;
	border-radius: 0.5rem;
	padding: 0.5rem;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.ui-datepicker-calendar thead th {
	padding: 0.25rem 0;
	text-align: center;
	font-size: 0.75rem;
	font-weight: 400;
	color: #78909C;
}
.ui-datepicker-calendar tbody td {
	width: 2.5rem;
	text-align: center;
	padding: 0;
}
.ui-datepicker-calendar tbody td a {
	display: block;
	border-radius: 0.25rem;
	line-height: 2rem;
	transition: 0.3s all;
	color: #546E7A;
	font-size: 0.875rem;
	text-decoration: none;
}
.ui-datepicker-calendar tbody td a:hover {	
	background-color: #E0F2F1;
}
.ui-datepicker-calendar tbody td a.ui-state-active {
	background-color: #009688;
	color: white;
}
.ui-datepicker-header a.ui-corner-all {
	cursor: pointer;
	position: absolute;
	top: 0;
	width: 2rem;
	height: 2rem;
	margin: 0.5rem;
	border-radius: 0.25rem;
	transition: 0.3s all;
}
.ui-datepicker-header a.ui-corner-all:hover {
	background-color: #ECEFF1;
}
.ui-datepicker-header a.ui-datepicker-prev {	
	left: 0;	
	background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
	background-repeat: no-repeat;
	background-size: 0.5rem;
	background-position: 50%;
	transform: rotate(180deg);
}
.ui-datepicker-header a.ui-datepicker-next {
	right: 0;
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
	background-repeat: no-repeat;
	background-size: 10px;
	background-position: 50%;
}
.ui-datepicker-header a>span {
	display: none;
}
.ui-datepicker-title {
	text-align: center;
	line-height: 2rem;
	margin-bottom: 0.25rem;
	font-size: 0.875rem;
	font-weight: 500;
	padding-bottom: 0.25rem;
}
.ui-datepicker-week-col {
	color: #78909C;
	font-weight: 400;
	font-size: 0.75rem;
}
.wrap-custom-file {
    position: relative;
    display: inline-block;
    width: 140px;
    height: 130px;
    margin: 0rem 0rem;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 0px;    margin-right: 10px;
}
.wrap-custom-file input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 2px;
    overflow: hidden;
    opacity: 0;
}
.wrap-custom-file label.file-ok {
    background-size: cover;
    background-position: center;
    margin-bottom: 0px;
}
.wrap-custom-file label {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    padding: 0 0.5rem;
    cursor: pointer;
    background-color: #fff;
    border-radius: 4px;
    -webkit-transition: -webkit-transform 0.4s;
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
}
.wrap-custom-file label.file-ok span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.3rem;
    font-size: 14px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.7);
    line-height: 20px;
}
.wrap-custom-file label span {
    display: block;
    margin-top: 2rem;
    font-size: 14px;
    color: #777;
    -webkit-transition: color 0.4s;
    transition: color 0.4s;
    line-height: 15px;
}
.wrap-custom-file label.file-ok .fa {
    display: none;
}
.wrap-custom-file label .pluicon {
    position: absolute !important;
    bottom: 0rem;
    left: 50% !important;
    -webkit-transform: translatex(-50%) !important;
    transform: translatex(-50%) !important;
    font-size: 1.5rem;
    color: lightcoral;
    -webkit-transition: color 0.4s;
    transition: color 0.4s;
    top: initial !important;
}
.caseuper {
  text-transform: none;
}
.linebtween {
	border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 15px;
}
.yesnobtn {
        line-height: 10px;
    border-bottom: none;
    padding-bottom: 0px;
    font-size: 14px;
	    display: block;
    width: 100%;
}
.yesnobtn .switch {
        display: inline-block;
    width: 60px;
    height: 30px;
    margin: 4px;
    transform: translateY(50%);
    position: relative;
}
.yesnobtn .switch input {
    display: none;
}
.slider-radio {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    box-shadow: 0 0 0 2px #777, 0 0 4px #777;
    cursor: pointer;
    border: 4px solid transparent;
    overflow: hidden;
    transition: .4s;
}
.slider-radio:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #777;
    border-radius: 30px;
    transform: translateX(-30px);
    transition: .4s;
}
input:checked + .slider-radio {
    box-shadow: 0 0 0 2px #ff95a2, 0 0 2px #ff95a2;
}
input:checked + .slider-radio:before {
    transform: translateX(30px);
    background: #ff95a2;
}

.headshot .avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 0px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.headshot .avatar-upload .avatar-edit {
    position: absolute;
    right: 10px;
    z-index: 1;
    top: 20px;
}
.headshot .avatar-upload .avatar-edit input + label:after {
    content: "\f040";
    font-family: 'FontAwesome';
    color: #757575;
    position: absolute;
    top: 8px;
    left: 3px;
    right: 0;
    text-align: center;
    margin: auto;
}
.box-signture {
	padding:10px;
	border:1px solid #ddd;
	height:200px;
	width:100%;
	display:block;
}
.numbeagrment {
	font-weight:600; font-size:18px;
}
.item-photomovedetal .cardbox img {
    width: 100%;
    height: 90px;
    object-fit: cover;
    margin-bottom: 0px;
    float: left;
    margin-right: 0px;
    border-radius: 0px;
}
.item-photomovedetal .cardbox {
    display: block;
    width: 100%;
    align-items: center;
    float: left;
}
.mb-0 { margin-bottom:0px;}
.highlight .content-container h4:before {
	display:none;
}
.highlight .content-container h4 {
	padding-left:0px;
}
.contact-right a {
    color: #ff95a2;
    text-decoration: none;
    background-color: transparent;
}
.term-page-content h2 {
	font-size:30px;
	font-weight:bold;
}
.term-page-content h4 {
	margin-bottom: 15px;
    margin-top: 30px;
    text-transform: none;
    font-weight: 700;
    color: #0c0c0c;
}
.term-page-content h3 {
	margin-bottom: 15px;
    margin-top: 30px;
    text-transform: none;
    font-weight: 700;
    color: #0c0c0c;
}
.verticlebox {
    display: flex;
    padding: 10px;
    border: 1px solid #ddd;
    width: 100%;
    position: relative;
    flex: 0 0 100%;
	margin-bottom:10px;
}
.leftimgmove {
	width:100px;
	margin-right:10px;
}
.leftimgmove img {
	width:100px;
	width: 100px;
    height: 85px;
    object-fit: cover;
}
.verticlebox h5 {
	text-align: left;
    font-size: 16px;
    letter-spacing: normal;
    color: #ff95a2;
    margin-bottom: 10px;
    position: relative;
    top: 0px;
    font-weight: bold;
    display: block;
    float: left;
    width: 100%;
}
.verticlebox h6 {
	font-size:12px;
	font-weight:500;margin-bottom:3px !important;
}
.verticlebox p {
	font-size:12px;
	font-weight:500;
	margin-bottom:3px !important;
}
.verticlebox .delete {
    background: #585454;
    color: #fff;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 5px;
    font-size: 12px;
    height: 27px;
        line-height: 20px;
}
.verticlebox .delete:hover {
	text-decoration:none;
}
.verticlebox .editbtn {
    background: #ff95a2;
    color: #fff;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 5px;
    font-size: 12px;
    height: 27px;
    line-height: 15px;
    margin-right: 5px;    
    line-height: 20px;
}
.verticlebox .editbtn:hover {
	text-decoration:none;
}
.content-mvebox {
	width: 55%;
}
.btncontentmove {
	width: 40%;
    float: right;
    text-align: right;
}
.slick-prev, .slick-next {
    display: none !important;
}
.bf-testimonial-slick .slick-prev, .slick-next {
    display:block !important;
}

.bf-testimonial-slick .slick-prev {
    left: -40px;
    z-index: 1;
}
.bf-testimonial-slick .slick-next {
    right: -40px;
}
.bf-testimonial-slick .slick-next:before, .bf-testimonial-slick .slick-prev:before {
    font-size: 20px;
    line-height: normal;
    opacity: 1;
    color: #fff;
    text-align: center;
    display: block;
}
.bf-testimonial-slick .slick-prev, .bf-testimonial-slick .slick-next {
    background: #ff95a2;
    border-radius: 15px;
    border: none;
    width: 40px;
    height: 40px;
    text-align: center;    line-height: 30px;
    border-radius: 100px;    padding: 5px;
}
.slick-prev:before {
    content: "❮";
}
.slick-next:before {
    content: "❯";
}


/* Accordion styles */
.faq-accordian .tabs {
    border-radius: 0px;
    overflow: hidden;
    box-shadow: none;
}

.faq-accordian .tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.faq-accordian .tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #ffffff;
    font-weight: bold;
    cursor: pointer;
    color: #000;margin-bottom: 0px;
}
.faq-accordian .tab-label:hover {
    background: #ff95a2;
    color: #fff;
}
.faq-accordian .tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.faq-accordian .tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;font-family: 'Josefin Sans', sans-serif;
      font-size: 14px;
    font-weight: 500;
}
.faq-accordian .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}

.faq-accordian input:checked + .tab-label {
  background: #ff95a2;color:#fff;
}
.faq-accordian input:checked + .tab-label::after {
  transform: rotate(90deg);
}
.faq-accordian input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}
.faq-accordian input:checked ~ .tab-content ul li {
  font-size: 14px;margin-bottom: 0px;
}
.select-input {
	width:100%;
}
.select-text {
	position: relative;
    font-family: 'Josefin Sans', sans-serif;
    background-color: transparent;
    width: 100%;
    padding: 10px 10px 10px 10px;
    font-size: 14px;
    border-radius: 0px;    font-weight: 400;
    border: 0.075em solid #ddd;
}
/* Remove focus */
.select-text:focus {
	outline: none;
    border: 0.075em solid #ff95a2;
}
.select-input .select-text {
	appearance: none;
	-webkit-appearance:none;
    color: gray;
    font-size: 14px;
}
.select-input:after {
	position: absolute;
	top: 25px;
    right: 25px;
	width: 0;
	height: 0;
	padding: 0;
	content: '';
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid rgba(0, 0, 0, 0.12);
	pointer-events: none;
}
.phonecountry .select-input:after {
    top: 22px;
    right: 10px;
}
.select-label {
    color: #adafca;
    background: white;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    padding: 0px 5px;
    left: 20px;
    top: 15px;
    transition: 0.2s ease all;
    letter-spacing: normal;
}
.select-text:focus ~ .select-label, .select-text:valid ~ .select-label {
	color: #adafca;
	top: -8px;left: 20px;
	transition: 0.2s ease all;
	font-size: 14px;
}
.select-text:valid:not(focus) ~ .select-label {
  	color: #adafca;left: 20px;
    background: #fff;
}
.select-text:valid:focus ~ .select-label {
  color: #adafca;left: 20px;
}
.select-text .optionslect{
    padding: 10px;
    line-height: 30px;
    display: block;
    font-size: 14px;
}
.select-text option {
    padding: 10px;
    line-height: 30px;
    display: block;
    font-size: 14px;
}
.movetype-book .ufg-job-application-wrapper {
    padding-bottom: 20px;
    max-width: 100%;
    padding-top: 40px;
}

.helper-setting .yesnobtn .switch {
    display: inline-block;
    width: 60px;
    height: 30px;
    margin: 0px;
    transform: translateY(50%);
    position: relative;
    position: relative;
    top: -20px;
}

.helperdays-setting .job-application-form .form-group.check-group:nth-last-child(2) {
    margin-bottom: 25px;
}
.helperdays-setting .job-application-form .form-group.check-group {
    margin-bottom: 15px;
}
.helperdays-setting .job-application-form .form-group {
    position: relative;
    margin-bottom: 25px;
}
.helperdays-setting .job-application-form .form-group.check-group .custom-checkbox .custom-control-input {
    height: 18px;
    width: 18px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.helperdays-setting .job-application-form .form-group.check-group .custom-checkbox .custom-control-label {
    width: auto;
    text-align: left;
    position: static;
    top: auto;
    left: auto;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    padding-left: 30px;
    cursor: default;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    letter-spacing: normal;
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
    line-height: 32px;
}
.helperdays-setting .form-group.check-group .custom-checkbox .custom-control-label {
    font-size: 15px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
}
.helperdays-setting .job-application-form .form-group label {
    position: absolute;
    top: 18px;
    margin-bottom: 0;
    left: 17px;
    z-index: 2;
    font-size: 14px;
    font-weight: 400;
    color: #546274;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: text;
    letter-spacing: normal;
}
.helperdays-setting .job-application-form .form-group.check-group .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background: #ff95a2;
}
.helperdays-setting .job-application-form .form-group.check-group .custom-checkbox .custom-control-label:before {
    top: 4px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 0px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}
.helperdays-setting .job-application-form .form-group.check-group .custom-checkbox .custom-control-label:after {
    top: 4px;
    left: 0;
    height: 18px;
    width: 18px;
}
.output {
    display: block;
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
    width: 35px;
    text-align: center;
    height: 30px;
    float: left;
    color: #328957;
    margin-right: 10px;
}
.output {
    color: #e89aa7 !important;
}
output {
    display: inline-block;
}
.output span {
    font-size: 12px;
    font-weight: normal;
    display: block;
}
.range-container {
    display: block;
    width: 270px;
    padding-top: 13px;
    height: 30px;
    float: left;
}
.rangeslider--horizontal {
    height: 2px;
    width: 100%;
}
.rangeslider, .rangeslider__fill {
    display: block;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
.rangeslider {
    display: block;
    width: 100%;
}
.rangeslider {
    background: #b5b5b5 !important;
    position: relative;
}
.rangeslider--horizontal {
    height: 5px;
    width: 100%;
}
.rangeslider--horizontal .rangeslider__fill {
    top: 0;
    height: 100%;
}
.rangeslider, .rangeslider__fill {
    display: block;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
.rangeslider__fill {
    background: #e89aa7 !important;
    position: absolute;
}
.rangeslider--horizontal .rangeslider__handle {
    top: -11px;
    touch-action: pan-y;
    -ms-touch-action: pan-y;
}
.rangeslider__handle {
    background: #ff95a2 !important;
    /* border: 1px solid #ccc; */
    cursor: pointer;
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    -moz-border-radius: 25%;
    -webkit-border-radius: 25%;
    border-radius: 25%;
}
.rangeslider__handle:after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.rangeslider-horizontal .rangeslider__handle:after {
    background-color: #ff95a2;
    box-shadow: none; 
}
.border-1 {
	border: 1px solid #ddd;
}
.helper-msg {
	background: #ff95a2;
    color: #fff !important;
    padding: 10px;
    border-radius: 100px;
    position: relative;
    top: 13px;
}
.price-moev {
    color: #ff95a2;
    font-size: 18px !important;
    font-weight: 800 !important;
	    position: relative;
    top: -4px;
}
.price-moev span {
        color: #000;
    font-size: 14px !important;
    font-weight: 600 !important;
    position: relative;
    top: 4px;
}
.move-dmap .maptxt {
    display: flex;
    width: 100%;
    float: left;
    font-size: 13px;
    font-weight: 600;
    padding-left: 20px;
    margin-bottom: 10px;
}


/***** ABOUT US PAGE CSS *******/

.linebtween {
    padding-bottom: 0px;
}
.linebtween .form-group.radio-group {
    margin-bottom: 15px !important;
    padding-bottom: 0px !important;
    border-bottom: none !important;
    padding-top: 15px;
    display: inline-block;
    margin-right: 20px;
}
.helper-steppro .ufg-job-application-wrapper .prev {
    margin-right: 10px;
}

.helper-steppro .wrap-custom-file .avatar-preview div {
    width: 100%;
    height: 130px;
        background-position: center;
        background-size: cover;
    background-repeat: no-repeat;
}
.login-page > .form-row > select option {
    padding: 15px 15px;
}
.pl-15 {
    padding-left: 15px !important;
}
.mt-70 {
    margin-top: 70px;
}

.datepicks input {
    background: #fff;
    border: 1px solid #ddd;
    box-sizing: border-box;
    color: #000;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    height: 50px;
    line-height: 20px;
    padding: 14px 50px;
    width: 100%;
}
.datepicks  span {
    left: 55px !important;
    top: 5% !important;
}
.datepicks input:focus {
    border-color: #ff95a2;
}
.react-datepicker button:after {
    display: none;
}
.z1 {
  z-index: 1;
}
.rbntclear {
    right: 0px;
    float: right;
    display: block;
}
.bg-pod {
    background-position: 50% 50%;
}
form input:focus, form select:focus, form textarea:focus {
    border-color: #e89aa7;
}
.servicw-sliderbox .slick-initialized .slick-slide {
    height: 60vh;
}
.servicw-sliderbox .slick-prev, .servicw-sliderbox .slick-next {
    display: none !important;
}


.topimg-city {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
    height: 700px;
    text-align: center;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
}
    .topimg-city h5 {     
        color: #fff; padding: 20px;  font-size: 44px; position: relative;
        text-transform: uppercase; display: block;  width: 100%; text-align: center;line-height: 30px;  padding-bottom: 0px;
        font-weight: bold;    z-index: 2; text-shadow: 0px 2px 2px #000;
    }
    .topimg-city p {     
        color: #fff; padding: 20px;   font-size: 20px; position: relative;
        text-transform: uppercase; display: block;  width: 100%; text-align: center;line-height: 30px;
        font-weight: bold;    z-index: 2; text-shadow: 0px 2px 2px #000;
    }
    .shapeimg {  position: absolute; top: 0px; width: 100%; height: auto;   background-position: bottom; background-size: cover;
    }
    .img-width-partner { width: 100%;  object-fit: contain; padding: 15px; border: 1px solid #ddd; border-radius: 10px; margin-bottom: 10px; height: 110px; background:#fff;}
    
    .responsive-map-container {
        position: relative;  padding-bottom: 40.25%; padding-top: 30px; height: 0; overflow: hidden; border-radius: 14px; 
    }
    
    .responsive-map-container iframe, .responsive-map-container object, .responsive-map-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; border-radius: 14px;
    }
    .landing-card-desc {  padding-left: 0px;}



.erromsg {
    position: absolute;
    left: 0px;
    bottom: -20px;
}
.position-relative {
    position: relative;
}
.sameday {
        border: 3px solid #e78293;
    padding: 15px;
    border-radius: 15px;
    padding-bottom: 10px;
}
.businessbox {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: center;
}
.busiepink {
    background: #ff95a2;
    color: #fff;
}
.businessbox i {
    background: #ff95a2;
    padding: 5px;
    font-size: 40px;
    margin-bottom: 15px;
    border-radius: 100px;
    color: #ffffff;
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 70px;
    position: relative;
    margin-top: -65px;
    border: 10px solid #dae4e6;
}
.businessbox h6 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}
.businessbox p {
    font-size: 16px;
    margin-bottom: 0px;
}

.servicw-sliderbox .slick-slide img {
    height: 240px;
    object-fit: cover;
}

.moveservicepage .img-holder img {
    width: 100%;
    transition: all .5s;
    height: 240px;
    object-fit: cover;
}
.accordions .close {
    position: absolute;
    z-index: 1;
    right: 140px;
    top: 30px;
    opacity: 1;
    color: #e78293;
    z-index: 2;
    text-shadow: none;
    cursor: pointer;
}
.accordions section.active .close  {
    opacity: 1;
}

video::-webkit-media-controls {
  display: none;
}
.video-wrapper controls {
    display: none;
}

.bomove-caladr-icon {
    position: absolute;
    top: 45px;
    left: 30px;
}

.react-datepicker__day, .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.5rem;
    line-height: 1.2rem;
    text-align: center;
    margin: 3.5px;
}
.react-datepicker__day-name {
    color: #ff95a2;
    display: inline-block;
    width: 1.5rem;
    line-height: 1.2rem;
    text-align: center;
    margin: 3px;
    font-size: 12px;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}
.react-datepicker-popper[data-placement^=bottom] {
    padding-top: 0px;
}
.react-datepicker__day--selected {
    background-color: #ff95a2;
    color: #fff !important;
}
.movesummary .maptxt {
    display: inline-block;
}
.apt-unit {
    margin-bottom: 0px;
    font-size: 12px;
}
.datepicks span {
    left: auto !important;
    top: 5% !important;
}
.helperwidth {
    width: 100%;
}
.helperwidth .select-input:after {
    top: 22px;
    right: 25px;
}
.option-margin {
    margin: 15px 0px;
    padding:15px 0px;
    display: block;
}
.bdHMCv {
    position: initial !important;
}
.bdHMCv > .wrapper {
    border-radius: 0px !important;
    color: #212121;
    font-size: 14px !important;
    z-index: 1 !important;    
    width: 95% !important;
}
.fASA-Dv {
    margin: 0 0 0 13px !important;
    fill: #ff95a2 !important;
    width: 20px !important;
    height: 20px !important;
}
.kOYplv > svg {
    fill: grey;
    width: 20px !important;
    height: 20px !important;
}
.pHieT .ellipsis {
    font-size: 14px !important;
    line-height: normal !important;
}
.sc-gsnTZi .search-icon {
    display: none !important;
}


.serv a {
    color: #000000;
}
.serv a:hover {
    color: #000000;
	text-decoration:none;
}

@media (max-width: 2000px) and (min-width: 1500px){
.bf-testimonial-slick .slick-dots {
    position: absolute;
    bottom: -50px !important;
}
.servicw-sliderbox .slick-initialized .slick-slide {
    height: auto;
}
.vc_custom_banner {
    padding-top: 270px !important;
    padding-bottom: 290px !important;
    background-position: 100% 30%;
}

}

@media (max-width: 1499px) and (min-width: 1400px){
.servicw-sliderbox .slick-initialized .slick-slide {
    height: auto;
}
}


@media (max-width: 991px) and (min-width: 320px){
    
.nopadd-0px {
    padding: 0px;
}

.m-bile {top: 11% !important;}
.mia-bile {
    top: 76% !important;
    left: 83% !important;
}
.live__map_wrap .map_img {
    width: 101%;
    max-width: 100%;
    position: relative;
    top: 0px;
    padding-top: 5px;
}
.sandngo {
    top: 54% !important;
    left: 8.9 !important;
}
.houston {
    top: 69.5% !important;
    left: 51.4% !important;
}
.newtop {
    margin-top: 40px;
    padding: 0px;
}
.servingbox {
    padding: 0px 0px;
}
.login-right-img {
    border-radius: 14px;
    height: 430px;
    object-fit: cover;
}

}


@media (max-width: 991px) and (min-width: 768px){
.helopadd-login {
	padding: 120px 0px;
    padding-bottom: 50px;
}

}

@media (max-width: 767px) and (min-width: 320px){
.helplogin {
	margin-top:60px;
}
.servicw-sliderbox .slick-slider .slick-list {
    transform: translateZ(0);
    padding: 0px !important;
    width: inherit;
}
.servicw-sliderbox .slick-initialized .slick-slide {
    margin: 0 0px;
}
.range-container {
    width: 250px;
}

.accpetstatus-helper .status:last-child {
    border-top: 2px solid #D6DCE0;
}
.accpetstatus-helper .li.complete:last-child .status {
    border-top: 2px solid #ff95a2 !important;
}
.mt10px {
    margin-top: 10px !important;
}
.movepro-title {
    padding-top: 0px;
    padding-left: 20px;
    margin-top: -15px;
}

}



@media screen and (max-width: 650px) {
  #tsum-tabs label {
        font-size: 14px;
		padding: 15px 15px;
  }
  #tsum-tabs label:before {
    margin: 0;
    font-size: 18px;
  }
  .admoves {
    background: #fff;
    color: #ff95a2;
    padding: 5px 10px;
    display: inline-block;
    margin-top: 0px;
    float: right;
    border: 1px solid #ff95a2;
    font-size: 13px;
    line-height: 22px;
    height: 33px;
}
.pick-l-detail {
    display: block;
}
.pick-l-detail h5 {
    font-size: 14px;
    width: 100%;
	margin-bottom:5px;
}
.pick-l-detail h6 {
    font-size: 12px;
    width: 100%; 
	text-align:left;
}
.verticlebox .editbtn {
    margin-right: 0px;
    margin-bottom: 5px;
}

}

@media screen and (max-width: 400px) {
  #tsum-tabs label {
    padding: 15px;
  }
.accpet-helpebtn {
    padding: 8px 28px;
}
.maptxt {
    font-size: 13px;
}
  
  
}





@media only screen and (max-width: 1367px){
	.bf-testimonial-slick-single{
		padding: 20px;height: 240px;
	}
	.bf-testimonial-message {
		padding: 0 10px;
	}
	
}

@media only screen and (max-width: 1280px){
	.cspt-steps-counter .vc_column_container:nth-child(2) .cspt-ihbox-style-5:before, .cspt-steps-counter .vc_column_container:nth-child(1) .cspt-ihbox-style-5:before {
    content: '';
    top: 30px;
    right: -40%;
    z-index: 99;
    width: 170px;
    height: 50px;
    background: url(../img/arrow.png) no-repeat 0 0;
    display: block;
    position: absolute;
}
}

@media only screen and (max-width: 991px){
	.bf-testimonial-slick-single{
		padding: 20px;
	}
	.bf-testimonial-message {
		padding: 0px;
	}
	.cspt-steps-counter .vc_column_container:nth-child(2) .cspt-ihbox-style-5:before, .cspt-steps-counter .vc_column_container:nth-child(1) .cspt-ihbox-style-5:before {
    display:none;
}
.contentbox-blog h3 {
    color: #fff;
    line-height: 25px;
    max-height: 54px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -webkit-transition: .15s ease-in-out;
    transition: .15s ease-in-out;
    font-size: 18px;
    text-align: left;
    flex: 0 0 100%;
    max-width: 100%;
    height: auto;
}
.ptb-60 {
    padding: 130px 0px;
}

}

@media all and (max-width:767px) and (min-width:320px){
.filte-category {
    display: block !important;    margin-bottom: 30px;
}
.categoerybox-mnone {
    display: none;
}
.user-profile-menu-wrap {
    box-shadow: none;
}
#dashbaordmenu .modal-body {
    padding: 0px;
}	
.p40 {
    padding: 40px 0px;
    padding-top: 0px;
}


}



@media only screen and (min-device-width: 641px){
#otp-model .modal-content {
    margin-top: -2vh;
}

}

@media (min-width: 576px){
.modal-sm {
    max-width: 400px !important;
}


}


@media all and (max-width:400px) and (min-width:360px){
 .hero.home iframe {
    box-sizing: border-box;
    height: inherit;
    min-height: inherit;
    min-width: inherit;
    pointer-events: none;
    width: 100%;
}
.hero.home {
    min-height: calc(100vh - 500px);
    padding-bottom: 0px;
    padding-top: 50px;
    border-bottom: 10px solid #fff;
    height: auto;
    -o-object-fit: fill;
    object-fit: fill;
    position: relative;
    top: 70px;
    margin-bottom: 65px;
}
.video-wrapper video {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    width: 100vw;
    height: 33vh;
    object-fit: cover;
    /* position: fixed; */
    top: 0;
    left: 0;
}
.hero.home>img, .hero.home iframe {
    left: inherit;
    top: inherit;
    transform: inherit;
    position: absolute;
}
.logo-topvideo {
    top: -60px !important;
}
.logo-icontop {
    width: 40px !important;
    top: 25px !important;
    right: -50px !important;
}
.moveitlog-center {
    width: 240px !important;
}
.moveit-tagline {
    width: 225px !important;
    left: -8px;
}  
.accordion-section .hero h1 {
    font-size: 13px !important;
    margin-bottom: 10px !important;
    line-height: 22px;
    padding-top: 15px;
}
.accordion-section .hero.full {
    min-height: 300px !important;
    max-height: 300px !important;
}
.hero article a img {
    width: 120px;
}
.moveservicepage .img-holder img {
    height: 130px;
}
.vc_custom_banner {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
}
.soculbtn .facebook {
    width: 100%;
    margin: 0px 5px;
}
.soculbtn .twitter {
    width: 100%;
    margin: 0px 5px;
}
.facebook-button-class {
   padding: 10px 0px !important;
    font-size: 11px !important;
}
.google-button-class {
    padding: 10px 0px !important;
    font-size: 11px !important;
}

}


@media all and (max-width:567px) and (min-width:401px){
 .hero.home iframe {
    box-sizing: border-box;
    height: inherit;
    min-height: inherit;
    min-width: inherit;
    pointer-events: none;
    width: 100%;
}
.hero.home {
    min-height: calc(100vh - 500px);
    padding-bottom: 0px;
    padding-top: 50px;
    border-bottom: 10px solid #fff;
    height: auto;
    -o-object-fit: fill;
    object-fit: fill;
    position: relative;
    top: 70px;
    margin-bottom: 65px;
}
.video-wrapper video {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    width: 100vw;
    height: 33vh;
    object-fit: cover;
    /* position: fixed; */
    top: 0;
    left: 0;
}
.hero.home>img, .hero.home iframe {
    left: inherit;
    top: inherit;
    transform: inherit;
    position: absolute;
}
.logo-topvideo {
    top: -60px !important;
}
.logo-icontop {
    width: 40px !important;
    top: 25px !important;
    right: -50px !important;
}
.moveitlog-center {
    width: 240px !important;
}
.moveit-tagline {
    width: 225px !important;
    left: -8px;
}  
.accordion-section .hero h1 {
    font-size: 15px !important;
    margin-bottom: 10px !important;
    line-height: 22px;
    padding-top: 15px;
}
.accordion-section .hero.full {
    min-height: 250px !important;
    max-height: 250px !important;
}
.hero article a img {
    width: 120px;
}
.moveservicepage .img-holder img {
    height: 130px;
}
.vc_custom_banner {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
}
.soculbtn .facebook {
    width: 100%;
    margin: 0px 5px;
}
.soculbtn .twitter {
    width: 100%;
    margin: 0px 5px;
}
.facebook-button-class {
    padding: 10px;
    font-size: 12px !important;
}
.google-button-class {
    padding: 10px;
    font-size: 12px !important;
}
}



@media (max-width:767px) {
 .f-36 {
    font-size: 30px;
}   
.contact-wrapper {
    width: 100%;
}
.login-right-img {
	margin-top:30px;
}
.card-body {
    height: 120px;
}
.detailsservice {
    padding: 10px 10px 15px;
    background-color: rgb(255 149 162);
    background-image: linear-gradient(357deg, rgb(255 111 129) 0%, #ffc9d0 74%);
    position: relative;
    z-index: 1;
    margin: -25px 5px 0;
    box-shadow: 0 10px 20px rgb(0 0 0 / 14%);
    padding-bottom: 0px;
    min-height: 110px;
}
.detailsservice h5 {     
    font-size: 13px;
    font-weight: 700;
}
.detailsservice p { 
    font-size:12px; line-height:16px;
}
.hero {
    padding: 90px 0 20px;
}
.news-block-one.style-two .image {
    margin-right: 0px;
    height: 230px;
}
.news-block-one.style-two .image img {
    width: 100%;
    height: 230px;
}
.title-singlenws {
    font-size: 26px;
    font-weight: 800;
    margin-bottom: 10px;
}
.single-blog0igg {
    width: 100%;
    height: 190px;
}
.lgout {
    top: -110px;
    right: 20px;
    margin-top: 20px;
}
.mymovesbox .itemdetail .col-lg-4 {
    padding-right:15px;
}

img.full {
    width: 100%;
}
.accordions .close {
    display: none;
}
.highlights .highlight .content .content-container {
    padding: 0 30px;
}
.mobile-paddtop {
    padding-top: 100px;
}
.login-page .checkbox__custom-checkbox {
    width: 100px;
}
.phonecountry {
    margin-bottom: 15px !important;
}
.fnt {
    margin-bottom: 0px;
    margin-top: 5px;
}
.mobile-boomove {
    padding-top: 50px !important;
}
.modal.left .modal-dialog {
    width: 100%;
    left: 0px;
}
.pagination-wrapper .pagination .page-link {
    color: #6c757d;
    font-size: 11px !important;
}
.page-link {
    padding: 10px 6px;
    line-height: 8px;
}
.breadcrumbs-wrapper:after {
    display: none;
}
.breadcrumbs-wrapper:before {
    width: 100% !important;
}
.promuscle {
    padding: 25px 0px !important;
}
.themestek-ihbox-inner {
    margin-bottom: 20px;
}
.why-pro-muscles .mb-5 {
        margin-bottom: 0px !important;
}
.rigtxt li {
    text-align: left !important;
}
.hero {
    padding: 130px 0 60px;
    background-position: 80% 40%;
}
.tandc {
    padding: 140px 0 60px;
    background-position: 40% 40%;
    position: relative;
    top: 70px;
    margin-bottom: 70px;
    padding-top: 70px;
}
.topimg-city h5 {
    padding: 0px;
    font-size: 30px;
    line-height: 30px;
    padding-top: 20px;
}
.topimg-city p {
    padding: 0px;
    font-size: 12px;
    text-align: center;
    line-height: 25px;
}
.faq-andimg {
    background-position: 40% 30%;
    position: relative;
    top: 50px;
    margin-bottom: 50px;
    padding-top: 90px;
}
.pp-andpolicy {
    background-position: 40% 30%;
    position: relative;
    top: 65px;
    margin-bottom: 65px;
    padding-top: 70px;
}
.conus-img {
    background-position: 60% 50%;
}
.section.slate .content-box.pattern, section.slate .content-box.pattern {
    margin-right: 0px;
}
.ufg-job-application-wrapper .prev {
    font-size: 15px;
    padding: 6px 15px;
    margin-right: 10px !important;
}
.verticlebox .editbtn {
    width: 70px;
    text-align: center;
}
.verticlebox .delete {
    width: 70px;
    text-align: center;
}
.page-item:not(:first-child) .page-link {
    margin-left: -5px;
}
.coverphoto {
    height: 240px !important;
    min-height: 240px;
    background-position: 100% 0%;
}
.messaging-member__avatar img {
    width: 50px;
    height: 50px;
}
.chat-member__avatar img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.custom-form__send-wrapper input {
    padding-right: 1rem;
    padding-left: 1.25rem;
}
.breadcrumbs-wrapper {
    position: relative;
    z-index: 1;
    padding: 125px 0 30px !important;
}
.pro-muscles {
    padding-top: 30px;
}
.margin-bottom-lg {
    margin-bottom: 30px !important;
}
.term-page-content h2 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 15px;
}
.live__map_wrap {
    position: relative;
    padding: 2rem 0;
}
.accpet-helpebtn {
    padding: 10px 10px;
    font-size: 13px;
}
.vechicle-insurance-pic .avatar-upload .avatar-preview {
    width: 100px;
    height: 100px;
}
.stepone-2 .checkbox-tools:checked + label, .stepone-2 .checkbox-tools:not(:checked) + label {
    height: auto;
}
.stepone-2 .checkbox-tools:not(:checked) + label {
    height: auto;
}
.hp-seting .yesnobtn {
    padding: 0px 0px;
}
.site-button, .site-button-secondry {
    padding: 5px 10px;
    font-size: 12px;    line-height: 20px;
}
.pbtnewdetal {
    padding-bottom: 0px;
}
.hero h1 {
    font-size: 18px;
    margin-bottom: 10px !important;
    line-height: 22px;
    padding-top: 15px;
}
.login-right-img {
    display: none;
}
.accordions .flourish {
    margin-left: 0px;
}


.tandc h1 {
    font-size: 20px !important;
}
.pp-andpolicy h1 {
    font-size: 20px !important;
}
.hero .h5 {
    font-size: 16px;
}
.whychoosepage b {
    font-size: 20px;
    margin-bottom: 10px;
    display: inline-block;
}
.whychoosepage .flourish-square {
    left: -30px !important;
    top: -35px !important;
    width: 60px;
    height: 60px;
}
.whychoosepage.text{
    line-height: 18px;
}
.howitworkpage .flourish-square {
    z-index: 1;
}
.howitworkpage .flourish-square {
    left: -30px !important;
    top: -25px !important;
    width: 60px;
    height: 60px;
}
.flourish.text .flourish-square {
    left: -40px;
    top: -30px;
    width: 60px;
    height: 60px;
}
.moveservicepage .flourish.text .flourish-square {
    left: -20px;
    top: -30px;
    width: 60px;
    height: 60px;
}
.client-padd .flourish.text .flourish-square {
    top: -35px;
}
.topimg-city {
    height: auto;
    padding-bottom: 40px;
    float: left;
    width: 100%;
    z-index: 1;
    padding-top: 100px;
}

.shapeimg {
	display:none;
}
.pro-muscles .flourish.text .flourish-square {
    left: -5px;
    top: -30px;
    width: 60px;
    height: 60px;
}
.becomepromuscle .flourish-square {
    z-index: 1;
}
.becomepromuscle .flourish-square {
    left: -35px !important;
    top: -35px !important;
}
.term-page-content h2 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
}
.term-page-content h3 {
    font-size: 20px;
}
.term-page-content h4 {
    font-size: 20px;
}


}





@media all and (max-width:1080px) and (min-width:768px){
.mobile-paddtop {
    padding-top: 100px;
}
.login-page .checkbox__custom-checkbox {
    width: 100px;
}
.mobile-boomove {
    padding-top: 50px !important;
}
.accordion-section .hero.full {
    height: calc(100vh - 0px);
    min-height: 530px;
    max-height: 530px;
}
.accordion-section img.full {
    width: 95%;
    margin: 0px auto;
    display: block;
}
.accordion-section .inline-button {
    color: #fff;
    padding-left: 25px;
}
.hero {
    padding: 90px 0 20px;
}
.servicw-sliderbox .slick-initialized .slick-slide {
    height: 40vh;
}
.stepone .offset-lg-6 {
    margin-left: 0% !important;
}
.ufg-job-application-wrapper {
    padding-top: 40px;
}
.stepone .helper-register .cardbox img {
    width: 100%;
    height: 240px;
}
.stepone .helper-register .checkbox-tools:not(:checked) + label {
    height: auto;
    margin-bottom: 20px;
}
.ftablemotop {
    margin: 100px 0% 28px 0%;
}

.blogbox img {
    max-height: 350px;
    height: 350px;
}
.accordions .close {
    display: none;
}
.highlights .highlight .content .content-container {
    padding: 0 30px;
}
.mobile-paddtop {
    padding-top: 100px;
}
.login-page .checkbox__custom-checkbox {
    width: 100px;
}
.phonecountry {
    margin-bottom: 15px !important;
}
.fnt {
    margin-bottom: 0px;
    margin-top: 5px;
}
.mobile-boomove {
    padding-top: 50px !important;
}
.breadcrumbs-wrapper:after {
    display: none;
}
.breadcrumbs-wrapper:before {
    width: 100% !important;
}
.promuscle {
    padding: 30px 0px !important;
}
.themestek-ihbox-inner {
    margin-bottom: 20px;
}
.why-pro-muscles .mb-5 {
        margin-bottom: 0px !important;
}
.rigtxt li {
    text-align: left !important;
}
.hero {
    padding: 130px 0 60px;
    background-position: 80% 40%;
}
.tandc {
    padding: 140px 0 60px;
    background-position: 40% 40%;
    position: relative;
    top: 70px;
    margin-bottom: 70px;
    padding-top: 70px;
}
.topimg-city h5 {
    padding: 10px;
    font-size: 34px;
    line-height: 40px;
}
.topimg-city p {
    padding: 10px;
    font-size: 16px;
    text-align: center;
    line-height: 25px;
}
.faq-andimg {
    background-position: 40% 30%;
    position: relative;
    top: 50px;
    margin-bottom: 50px;
    padding-top: 90px;
}
.pp-andpolicy {
    background-position: 40% 30%;
    position: relative;
    top: 65px;
    margin-bottom: 65px;
    padding-top: 70px;
}
.conus-img {
    background-position: 60% 50%;
}
.section.slate .content-box.pattern, section.slate .content-box.pattern {
    margin-right: 0px;
}
.ufg-job-application-wrapper .prev {
    font-size: 15px;
    padding: 6px 15px;
    margin-right: 10px !important;
}

.messaging-member__avatar img {
    width: 50px;
    height: 50px;
}
.chat-member__avatar img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.custom-form__send-wrapper input {
    padding-right: 1rem;
    padding-left: 1.25rem;
}
.breadcrumbs-wrapper {
    position: relative;
    z-index: 1;
    padding: 125px 0 30px !important;
}
.pro-muscles {
    padding-top: 30px;
}
.margin-bottom-lg {
    margin-bottom: 30px !important;
}
.term-page-content h2 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 15px;
}
.live__map_wrap {
    position: relative;
    padding: 2rem 0;
}
.ugf-wrapper {
    position: relative;
    min-height: auto;
}
.user-profile-menu li a {
    padding: 8px 10px;
    font-size: 13px;
}
.chat-member__avatar img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
}
.messaging-member__avatar img {
    width: 50px;
    height: 50px;
}
.accordion-section {
    height: calc(100% - 100px);
    position: relative;
    height: fit-content;
    min-height: auto;
    max-height: initial;
}
.hero.full {
    height: auto;
    min-height: calc(50vh - 0px);
}
.accordion-section .accordions section article {
    padding-bottom: 0px;
}

}



@media all and (max-width:1300px) and (min-width:1200px){
header .primary>ul>li>a {
    font-size: 13px;
    padding: 15px 0px;
}
}


@media all and (max-width:1799px) and (min-width:1400px){
.topimg-city {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
    height: 700px;
    text-align: center;
    background-position: center;
    background-size: cover;
    position: relative;
}
}



@media all and (max-width:2200px) and (min-width:1800px){
.topimg-city {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
    height: 700px;
    text-align: center;
    background-position: center;
    background-size: cover;
    position: relative;
}
}



