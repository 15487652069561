.pt30 {
    padding-top: 30px;
}

.pb30 {
    padding-bottom: 30px;
}

.pt40 {
    padding-top: 40px;
}

.pb40 {
    padding-bottom: 40px;
}

.pt60 {
    padding-top: 60px;
}

.pb60 {
    padding-bottom: 60px;
}

.pt70 {
    padding-top: 70px;
}

.pb70 {
    padding-bottom: 70px;
}

.pt80 {
    padding-top: 80px;
}

.pb80 {
    padding-bottom: 80px;
}

.pt100 {
    padding-top: 100px;
}

.pb100 {
    padding-bottom: 100px;
}

.pt130 {
    padding-top: 130px;
}

.pb130 {
    padding-bottom: 130px;
}

.pt150 {
    padding-top: 150px;
}

.pb150 {
    padding-bottom: 150px;
}

.pt180 {
    padding-top: 180px;
}

.pb180 {
    padding-bottom: 180px;
}

.pt200 {
    padding-top: 200px;
}

.pb200 {
    padding-bottom: 200px;
}

.mt10 {
    margin-top: 10px;
}

.mb10 {
    margin-bottom: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mb20 {
    margin-bottom: 20px;
}
.step-logo {
	    display: block;
    margin: 0px auto;
    width: 100%;
    text-align: center;
    width: 130px;
    margin-bottom: 50px;
}
.ugf-wrapper {
    position: relative;
    min-height: 100vh;
}
.ugf-wrapper .logo {
    width: 40%;
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 3;
}
.ugf-wrapper .logo .logo-black {
    display: none;
}
.ugf-wrapper .logo .logo-white {
        display: block;
    width: 210px;
    margin: 0px auto;
}
.ugf-wrapper .ugf-content-block {
    position: absolute;
    width: 810px;
    left: 0;
    top: 0;
    bottom: 0;
    background: url(../img/movebook-left-bg.png) no-repeat center;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.ugf-wrapper .ugf-content-block .content-block {
    padding-left: 200px;
    padding-right: 50px;
}
.ugf-wrapper .ugf-content-block .content-block h1 {
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 30px;
}
.ugf-wrapper .ugf-content-block .content-block p {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 25px;
}
.ugf-wrapper .footer {
    position: absolute;
    bottom: 50px;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 3;
    width: 810px;
    padding: 0 50px;
}
.ugf-wrapper .footer .copyright {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}
.ugf-wrapper .footer .copyright p {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    position: relative;
    top: 10px;
}
.ugf-wrapper .footer .social-links {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
}
.ugf-wrapper .footer .social-links a {
    margin-right: 15px;
    color: #ffffff;
}
.ugf-wrapper .footer .social-links a:last-child {
    margin-right: 0;
}
.ugf-wrapper .footer .social-links a i {
        font-size: 14px;
}

.form-steps {
    display: none;
}
.form-steps.active {
    display: block;
}

.ufg-job-application-wrapper {
    padding-bottom: 60px;
    max-width: 100%;
    padding-top: 70px;
}
.ufg-job-application-wrapper .progress {
    background: #eff1f3;
    height: 8px;
    overflow: visible;
    border-radius: 4px;
    margin-bottom: 25px;
}
.ufg-job-application-wrapper .progress .progress-bar {
    position: relative;
    overflow: visible;
    line-height: 1.7;
    border-radius: 4px;
    background: #ff95a2;
}
.ufg-job-application-wrapper .progress .progress-bar .step-text {
    position: absolute;
    top: -30px;
    left: 0;
    font-size: 14px;
    font-weight: 600;
    color: #546274;
    text-transform: uppercase;
    font-family: 'Josefin Sans', sans-serif;
}
.ufg-job-application-wrapper .prev {
    font-size: 16px;
    font-weight: 700;
    text-transform: none;
    color: #fff;
    margin-top: 10px;
    display: inline-block;
    line-height: 46px;position: relative;
}
.ufg-job-application-wrapper .prev:hover {
	text-decoration:none;
}
.ufg-job-application-wrapper .prev i {
    margin-right: 8px;
}
.ufg-job-application-wrapper h3 {
    font-size: 22px;
    font-weight: 700;
    color: #1c2437;
    padding-bottom: 10px;
}
.ufg-job-application-wrapper h3 span {
    font-size: 1.6rem;
    font-weight: 400;
}
.title-righbx {
    font-size: 22px;
    font-weight: 700;
    color: #1c2437;
    padding-bottom: 10px;
}
.login-page h5{
    font-size: 16px;
}
.ufg-job-application-wrapper h3.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    margin-bottom: 25px;
}
.ufg-job-application-wrapper .job-application-form .block-title {
    font-size: 1.4rem;
    font-weight: 700;
    color: #1c2437;
    margin-bottom: 15px;
}
.ufg-job-application-wrapper .job-application-form .form-group + .block-title {
    margin-top: 25px;
}
.ufg-job-application-wrapper .job-application-form .form-group {
    position: relative;
    margin-bottom: 25px;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-gender {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    padding-top: 25px;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-gender .lebel-text {
    position: absolute;
    top: -5px;
    left: 0;
    font-size: 1.2rem;
    font-weight: 700;
    color: #1c2437;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-gender .custom-radio {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 135px;
    flex: 0 0 135px;
    height: 60px;
    margin-right: 20px;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-gender .custom-radio .custom-control-input {
    display: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-gender .custom-radio .custom-control-input:checked ~ .custom-control-label {
    border-color: #e9b6bc;
    color: #e9b6bc;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-gender .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background: #e9b6bc;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-gender .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none;
    content: url(../img/check-white.svg);
}
.ufg-job-application-wrapper .job-application-form .form-group.check-gender .custom-radio .custom-control-label {
    cursor: pointer;
    background: #ffffff;
    height: 60px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: #b2becc;
    padding-left: 58px;
    text-transform: capitalize;
    position: relative;
    top: 0;
    left: 0;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-gender .custom-radio .custom-control-label:before {
    height: 18px;
    width: 18px;
    border-radius: 5px;
    background: #e1e7ee;
    border: none;
    top: 19px;
    left: 20px;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-gender .custom-radio .custom-control-label:after {
    height: 18px;
    width: 18px;
    top: 20px;
    left: 20px;
}
.ufg-job-application-wrapper .job-application-form .form-group.radio-group {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
}
.ufg-job-application-wrapper .job-application-form .form-group.radio-group:nth-last-child(2) {
    margin-bottom: 25px;
}
.ufg-job-application-wrapper .job-application-form .form-group.radio-group .custom-radio .custom-control-input {
    display: none;
}
.ufg-job-application-wrapper .job-application-form .form-group.radio-group .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    border-width: 2px;
    border-color: #e9b6bc;
    background: #ffffff;
}
.ufg-job-application-wrapper .job-application-form .form-group.radio-group .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    opacity: 1;
}
.ufg-job-application-wrapper .job-application-form .form-group.radio-group .custom-radio .custom-control-label {
        width: auto;
    text-align: left;
    position: static;
    top: auto;
    left: auto;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    padding-left: 30px;
    cursor: pointer;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 700;
    color: #1c2437;
    letter-spacing: normal;
}
.ufg-job-application-wrapper .job-application-form .form-group.radio-group .custom-radio .custom-control-label:before {
    top: 0px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}
.ufg-job-application-wrapper .job-application-form .form-group.radio-group .custom-radio .custom-control-label:after {
    top: 5px;
    left: 5px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #e9b6bc;
    opacity: 0;
    content: "";
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-group {
    margin-bottom: 15px;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-group:nth-last-child(2) {
    margin-bottom: 25px;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-group .custom-checkbox .custom-control-input {
    height: 18px;
    width: 18px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-group .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background: #ff95a2;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-group .custom-checkbox .custom-control-label {
    width: auto;
    text-align: left;
    position: static;
    top: auto;
    left: auto;
    transform: translateY(0);
    padding-left: 30px;
    cursor: default;
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    letter-spacing: normal;
    margin-bottom: 10px;
    margin-top: 0px;
    display: block;
    line-height: 30px;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-group .custom-checkbox .custom-control-label:before {
    top: 4px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 0px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-group .custom-checkbox .custom-control-label:after {
    top: 4px;
    left: 0;
    height: 18px;
    width: 18px;
}
.ufg-job-application-wrapper .job-application-form .form-group.check-group .custom-checkbox .custom-control-label a {
    color: #ff95a2;
}
.ufg-job-application-wrapper .job-application-form .form-group .form-control {
    height: 60px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 25px 15px 0;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 80px;
    color: #546274 !important;
}
.ufg-job-application-wrapper .job-application-form .form-group .form-control:focus,
.ufg-job-application-wrapper .job-application-form .form-group .form-control:valid {
    border-color: rgb(247 186 191);
    color: #546274 !important;
}
.ufg-job-application-wrapper .job-application-form .form-group .form-control:focus + label,
.ufg-job-application-wrapper .job-application-form .form-group .form-control:valid + label {
    top: 7px;
    font-size: 14px;
    color: #ff95a2;
    letter-spacing: normal;
}
.ufg-job-application-wrapper .job-application-form .form-group .select-input {
    position: relative;
}
.ufg-job-application-wrapper .job-application-form .form-group .select-input span {
    position: absolute;
    top: 27px;
    right: 15px;
    height: 0;
    width: 0;
    border-top: 6px solid #b2becc;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}
.ufg-job-application-wrapper .job-application-form .form-group .select-input .form-control {
    padding-right: 0;
    padding-top: 0;
    line-height: 60px;
    color: #546274;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.ufg-job-application-wrapper .job-application-form .form-group .select-input .form-control option {
    color: #546274;
}
.ufg-job-application-wrapper .job-application-form .form-group label {
        position: absolute;
    top: 18px;
    margin-bottom: 0;
    left: 17px;
    z-index: 2;
    font-size: 14px;
    font-weight: 400;
    color: #546274;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: text;
    letter-spacing: normal;
}
.ufg-job-application-wrapper .job-application-form .form-group textarea.form-control {
    height: 140px;
    padding-top: 10px;
    line-height: 1.7;
    border: 2px solid rgba(0, 0, 0, 0.08);
    resize: none;
    font-size: 16px;
}
.ufg-job-application-wrapper .job-application-form .form-group .custom-file {
    height: 52px;
    display: block;
}
.ufg-job-application-wrapper .job-application-form .form-group .custom-file .form-control {
    outline: none;
    display: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.ufg-job-application-wrapper .job-application-form .form-group .custom-file .form-control + .custom-file-label {
    top: 0;
}
.ufg-job-application-wrapper .job-application-form .form-group .custom-file .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.ufg-job-application-wrapper .job-application-form .form-group .custom-file .custom-file-label {
    cursor: pointer;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    margin-bottom: 0;
    height: 50px;
    border-radius: 30px;
    padding: 0;
    color: #b2becc;
    border: 1px dashed #bfbfbf;
    padding-left: 140px;
    line-height: 50px;
}
.ufg-job-application-wrapper .job-application-form .form-group .custom-file .custom-file-label:after {
    content: "+ CHOOSE FILE";
    top: 9px;
    left: 10px;
    right: auto;
    font-size: 12px;
    color: #546274;
    background: #eceff2;
    border-radius: 20px;
    border: 0;
    letter-spacing: normal;
}
.ufg-job-application-wrapper .job-application-form .btn {
    height: 55px;
    width: auto;
        display: inline-block;
    background: #ff95a2;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    border-radius: 0px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 6px 26px;
    letter-spacing: normal;
	    position: relative;
    top: -3px;
}

.final-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    padding: 120px 0;
    text-align: center;
}
.final-content .icon {
    margin-bottom: 20px;
}
.final-content h2 {
    font-size: 3.6rem;
    font-weight: 700;
    color: #1c2437;

    margin-bottom: 10px;
}
.final-content p {
    font-size: 1.5rem;
    font-weight: 400;
    color: #546274;
}

[type="radio"]:checked, [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
}
[type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
}
.checkbox-tools:checked + label, .checkbox-tools:not(:checked) + label {
    position: relative;
    display: inline-block;
    padding: 8px;
    display: block;
    width: 100%;
    line-height: 20px;
    letter-spacing: 1px;
    margin: 0 auto;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    color: #fff;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    border-radius: 0px;
    border: 2px solid #ecbfcc;
    display: block;
    width: 100%;height: 250px;
}
.itemstep .checkbox-tools:checked + label, .checkbox-tools:not(:checked) + label {
	height: auto;
}

.checkbox-tools:not(:checked) + label:hover {
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
}
.checkbox-tools:not(:checked) + label {
    background-color: #eff1f3;
    box-shadow: none;
    border-radius: 0px;
    border: 2px solid #f3f3f3;
    display: block;
    width: 100%;
}
.stepone .checkbox-tools:not(:checked) + label {
    background-color: #eff1f3;
    box-shadow: none;
    border-radius: 0px;
    border: 2px solid #f3f3f3;
    display: block;
    width: 100%;
	height: 250px;
}
.stepone-2 .checkbox-tools:not(:checked) + label {
    background-color: #eff1f3;
    box-shadow: none;
    border-radius: 0px;
    border: 2px solid #f3f3f3;
    display: block;
    width: 100%;
    height: 220px;
    padding: 0px;
}
.stepone-2 .checkbox-tools:checked + label, .stepone-2 .checkbox-tools:not(:checked) + label {
	height: 220px;
}
.stepone-2 .cardbox h5 {
    margin-bottom: 5px;
}


.drop-service .checkbox-tools:checked + label, .drop-service .checkbox-tools:not(:checked) + label {
    height: 270px;
}
.drop-service .itemstep .checkbox-tools:checked + label, .drop-service .checkbox-tools:not(:checked) + label {
    height: 270px;
}
.checkbox-tools:checked + label::before, .checkbox-tools:not(:checked) + label::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    z-index: -1;
}
.cardbox {
    display: block;
}
.cardbox img {
    width: 100%;
    height: 130px;
    object-fit: cover;
    margin-bottom: 10px;
    float: left;
    margin-right: 0px;
    border-radius: 0px;
}
.cardbox h5 {
    text-align: left;
    font-size: 16px;
    /* white-space: nowrap; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    letter-spacing: normal;
    color: #000;
    margin-bottom: 10px;
    position: relative;
    top: 0px;
}
.cardbox p {
    color: #707070;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    letter-spacing: normal;
    margin-bottom: 0px;
}

.add-more-itembtn:hover {
    color: #000000;
    text-decoration: none;
}

@media all and (max-width: 2000px) and (min-width: 1500px) {
.ugf-wrapper .ugf-content-block, .ugf-wrapper .footer {
    width: 47%;
}
.ugf-wrapper .offset-lg-6 {
    margin-left: 60%;
}
.stepone .offset-lg-6 {
    margin-left: 50%;
}


}




@media all and (max-width: 1439px) {
    .ugf-wrapper .ugf-content-block,
    .ugf-wrapper .footer {
        width: 650px;
    }
    .ugf-wrapper .ugf-content-block .content-block {
        padding-left: 50px;
    }
}

@media all and (max-width: 1199px) {
    .ugf-wrapper .ugf-content-block, .ugf-wrapper .footer {
		width: 490px;
	}
    .ugf-wrapper .ugf-content-block .content-block {
        padding-left: 100px;
    }
}

@media (min-width: 992px){
.stepone .offset-lg-6 {
    margin-left: 50%;
}
}


@media all and (max-width: 991px) {
    .ugf-wrapper .logo {
        width: 690px;
        padding: 0;
        margin: 30px auto 0;
        position: static;
    }
    .ugf-wrapper .logo .logo-black {
        display: block;
    }
    .ugf-wrapper .logo .logo-white {
        display: none;
    }
    .ugf-wrapper .ugf-content-block {
        display: none;
    }
    .ugf-wrapper .ufg-job-application-wrapper .progress {
        margin-bottom: 50px;
    }
    .ugf-wrapper .footer {
        width: 690px;
        padding: 0;
        margin: 0 auto 20px;
        position: static;
    }
    .ugf-wrapper .footer .copyright p {
        color: #546274;
    }
    .ugf-wrapper .footer .social-links a {
        color: #546274;
    }
	footer .grid.grid-lg>[class*=unit-] {
		padding: 0 15px;
	}
	
}

textarea {
    resize: none;
}

input::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
    color: #546274;
}
input:-moz-placeholder,
.form-control:-moz-placeholder {
    color: #546274;
}
input::-moz-placeholder,
.form-control::-moz-placeholder {
    color: #546274;
}
input:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
    color: #546274;
}
.primary-color {
    color: #1e85ff;
}

.primary-bg {
    background: #1e85ff;
}

.white-text {
    color: #ffffff;
}

.white-bg {
    background: #ffffff;
}

.ugf-bg {
    background-size: cover;
}

.qty .input-group {
    clear: both;
    margin: 0px 0;
    position: relative;
    margin-bottom: 0px;
}
.qty .input-group input[type='button'] {
    background-color: #eeeeee;
    min-width: 30px;
    width: 30px !important;
    transition: all 300ms ease;
    margin-bottom: 0px !important;
    font-size: 24px !important;
    padding: 0px !important;
    border-radius: 0px !important;
    border: none !important;
	line-height:20px !important;
}
.qty .input-group .quantity-field {
    position: relative;
    height: 30px;
    left: 0px;
    text-align: center;
    width: 45px !important;
    display: inline-block;
    font-size: 11px !important;
    margin: 0 0 5px;
    resize: vertical;
    padding: 0px !important;
    margin-bottom: 0px !important;
    border-radius: 0px !important;
    /* border: 1px solid #ddd !important; */
    text-align: center;
    font-weight: 700;
    font-size: 15px !important;
    justify-content: center;
}
.qty .input-group .button-minus, .qty .input-group .button-plus {
    font-weight: bold;
    height: 30px;
    padding: 0;
    width: 30px;
    position: relative;
}
.labefifle {
        letter-spacing: normal;
    position: relative !important;
    top: 5px !important;
    margin-bottom: 0 !important;
    left: 0px !important;
    z-index: 2;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #546274 !important;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: text;
    margin-right: 15px;
}

.form-steps .login-page > .form-row {
    display: flex;
    margin: 20px 0px;
    position: relative;
    margin-top: 0px;
}
.pstep-from {
	    padding-left: 0px;
    padding-top: 0px;
    font-size: 14px;
    line-height: 18px;
    color: #707070;
}
.itemstep .checkbox-tools:not(:checked) + label {
    background-color: #eff1f3;
    box-shadow: none;
    border-radius: 0px;
    border: 2px solid #f3f3f3;
    display: flex;
    width: 100%;
    padding: 5px;
}
.itemstep .checkbox-tools:checked + label, .itemstep .checkbox-tools:not(:checked) + label {
    position: relative;
    display: inline-block;
    padding: 5px;
    display: block;
}
.itemstep .cardbox {
    display: block;
    width: 100%;
    align-items: center;
}
.itemstep .cardbox h5 {
    text-align: left;
    font-size: 17px;
    margin-bottom: 0px;
    position: relative;
    top: 0px;
}
.itemstep .checkbox-tools:not(:checked) + label:hover {
    background-color: #fff;
}
.select-itembox {
	position:relative;
	margin-bottom:25px;
}
.select-itembox img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    position: relative;
}
.select-itembox h5 {
       content: "";
    position: absolute;
    left: 0px;
    bottom: 0px;
    padding: 5px;
    color: #fff;
    background: #00000073;
    width: 100%;
    text-align: center;
    text-transform: none;
    font-weight: 900;
    font-size: 24px;
}
.add-item {
	padding: 5px;
    border: 1px solid #d1d1d1;
    margin-bottom: 15px;
}

.step-add-item .login-page  {
    margin:0px;
}
.step-add-item .login-page > .form-row > input {
    padding: 15px 15px;
}
.step-add-item .login-page > .form-row > span {
    left: 15px;
}

.itemdetail .cardbox {
    display: block;
    width: 100%;
    align-items: center;
    float: left;
    border: 1px solid #d1d1d1;
    padding: 5px;
}
.itemdetail .cardbox h5 {
    top: 5px;margin-bottom: 10px;
}
.itemdetail .editbtn {
	background: #ff95a2;
    color: #fff;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 5px;
    font-size: 12px;
    height: 27px;
    line-height: 20px;    margin-right: 5px;
}
.itemdetail .delete {
	background: #5a5a5a;
    color: #fff;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 5px;
    font-size: 12px;
    height: 27px;
    line-height: 20px;
}
.itemdetail .editbtn:hover {
	text-decoration: none;
}
.itemdetail .delete:hover {
	text-decoration: none;
}

.t-item {
	    display: inline-block;
    background: #ff95a2;
    padding: 5px;
    border: none;
    height: auto;
    font-size: 14px;
    color: #fff;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 13px;
    margin-left: 5px;
    float: right;
}
.add-more-itembtn {
	text-align: center;
    display: block;
    margin-top: 0px;
    background: #eff1f3;
    padding: 10px;
    border: 5px;
    color: #000;
}
.small-radio {
	display: block;
    font-size: 14px;
    color: #5e5e5e;
    font-weight: 400;
	margin-top:5px;
}

.drop-service .cardbox h5 {
    margin-bottom: 0px;
    top: 0px;
}

.pricedetail {
    float: right;
    font-size: 18px;
    font-weight: 900;
    color: #ff95a2;
}

.last-itme-box {
        display: flex;
    position: relative;
    background: #eff1f3;
    border-radius: 0px;
    padding: 0px 15px;
    border: 1px solid #dadada;
    float: left;
    width: 100%;
    height: 70px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}
.last-itme-box h6 {
    text-align: left;
    font-size: 20px;
    /* white-space: nowrap; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    letter-spacing: normal;
    position: relative;
    bottom: 0px;
    color: #000;
    left: 0px;
    background: transparent;
    padding: 0px 0px;
    display: inline-block;
    width: 150px;
}
.fontname {
    font-size: 13px !important;
    font-weight: bold;
    padding-top: 10px !important;
}
.fontname a {
	color: #b7b7b7;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
}
.edit {
    float: right;
    color: #000;
    padding-top: 0px;
    padding-right: 0px;
    font-size: 16px;
}
.edit a {
    color: #f2b6be;
    font-weight: bold;
    font-size: 20px;
}
.fontname a:hover {
	text-decoration:none;
}
.edit a:hover {
	text-decoration:none;
}	

.promo .login-page  {
    margin:0px;
}
.promo .login-page > .form-row > input {
    padding: 15px 15px;
    padding-right: 110px;
}
.promo .login-page > .form-row > span {
    left: 15px;
}
.promo button {
	position: absolute;
    top: 0px;
    right: 16px;
    height: 50px;
    line-height: normal;
    padding: 10px 30px;
    letter-spacing: normal;
    font-weight: bold;
}
.promo button:after {
	display:none;
}

.insurancecheck .form-group.check-group .custom-checkbox .custom-control-label:before {
    top: 1px !important;
}
.insurancecheck .form-group.check-group .custom-checkbox .custom-control-label {
    line-height: 22px !important;
	font-size: 14px !important;
}
.insurancecheck .form-group.check-group .custom-checkbox .custom-control-label:after {
    top: 1px !important;
}
.w-100p { width: 100% !important;}
.d-block-price { display:block; 
    font-size:11px; 
    text-transform: none;
    position: relative;
    z-index: 1;
}
.f-18 { font-size: 18px;}

.step-card-add .login-page  {
    margin:0px;
}
.step-card-add .login-page > .form-row > input {
    padding: 15px 30px;
}
.step-card-add .login-page > .form-row > span {
    left: 35px;
}
.c-payment button:after {
	display:none;
}




.modal.right .modal-dialog {
	position: fixed;
	margin: auto;
	width: 500px;
	height: 100%;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
	height: 100%;
	overflow-y: auto;
}

.modal.right .modal-body {
	padding: 15px 15px 0px;
}
.modal.right .modal-content {
    border-radius: 0px !important;
    border: none !important;
}
.modal.right .modal-header button:after {
	display:none;
}

/*Right*/
.modal.right.fade .modal-dialog {
	right: 0px;
	-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
	-o-transition: opacity 0.3s linear, right 0.3s ease-out;
	transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
	right: 0;
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    padding-left: 0px;
    font-size: 20px;
    letter-spacing: normal;
}
.modal-title:before {
	display:none;
}
.modal-header {
	border-bottom-color: #eeeeee;
	background-color: #fafafa;
}


.junk-removal-content .itemstep .cardbox {
        display: flex;
    width: 100%;
    align-items: center;
    float: left;
}
.junk-removal-content .itemstep .cardbox img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-bottom: 0px;
    float: left;
    margin-right: 20px;
    border-radius: 0px;
}
button:focus {
    outline: 1px dotted;
    outline: none;
}

.modal-dialog .ufg-job-application-wrapper {
    padding-top: 0px;
}
#subcategory-item-model .modal-dialog {
    width: 500px;
}
#subcategory-item-model .modal-body {
    padding-bottom: 0px;
}
#add-item-model .cardbox img {
    height: 220px;
}


/* Accordion styles */
.tabs {
      border-radius: 0px;
    overflow: visible;
    box-shadow: none;
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;    margin-bottom: 10px;
}
.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #2c3e50;
  font-weight: bold;
  cursor: pointer;
      margin-bottom: 0px;
  /* Icon */
}
.tab-label:hover {
  background: #ff95a2;
}
.tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
}
.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.tab-close:hover {
  background: #1a252f;
}

.movebook-tabs input:checked + .tab-label {
  background: #ff95a2;
}
.movebook-tabs input:checked + .tab-label::after {
  transform: rotate(90deg);
}
.movebook-tabs input:checked ~ .tab-content {
      min-height: auto;
    padding: 1em 0px;
    display: block;
    position: relative;
    width: 100%;
    /* overflow-x: scroll; */
    max-height: 100%;
}


#checkdetail-service .last-itme-box {
    width: 100%;
    height: 50px;
}
#checkdetail-service .last-itme-box h6 {
        width: 70%;
}
#checkdetail-service .edit {
    width: 50%;
    text-align: right;
}
.ttt-price {
	text-align: left;
    font-size: 20px;
}
.tprice {
	text-align: right;
    font-size: 20px;
}

.prev-btn {
	    height: 55px;
    width: auto;
    display: block;
        background: #484848;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    border-radius: 0px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 6px 26px;
    letter-spacing: normal;
}
.prev-btn-bokmove span {
    position: relative;
    z-index: 1;
}
.prev-btn-bokmove:after {
    background: #3a3a3a;
     -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    content: "";
    display: block !important;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.prev-btn-bokmove:focus:after {
    -webkit-clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);  
    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.prev-btn-bokmove:hover:after {
    -webkit-clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
        transition: all .4s cubic-bezier(.645, .045, .355, 1);
}




.step-btn-bokmove span {
    position: relative;
    z-index: 1;
}
.step-btn-bokmove:after {
    background: #e78293;
    -webkit-clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 100%, 0 100%, 100% 100%);
    content: "";
    display: block !important;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.step-btn-bokmove:focus:after {
    -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);    transition: all .4s cubic-bezier(.645, .045, .355, 1);
}
.step-btn-bokmove:hover:after {
    -webkit-clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 100%, 0 0, 0 100%, 100% 100%);
        transition: all .4s cubic-bezier(.645, .045, .355, 1);
}


.alert-confirm-footer {
    justify-content: center;
}
.alert-confirm-button.default-button:hover, .alert-confirm-button.default-button:focus {
    color: #fff;
}
.alert-confirm-content {
    color: rgba(0,0,0,.85);
    font-size: 20px;
    padding-top: 10px;
    text-align: center;
}
.yesnobtn {
    background: #ff95a2;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px !important;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 16px;
    padding: 10px 30px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase !important;
    letter-spacing: normal;
    padding-bottom: 10px;
    width: auto;
	margin-bottom: 0px !important;
}
.yesnobtn:hover {
    color: #fff;
}


.yesnobtn-no {
    background: #585454 !important;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px !important;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 16px;
    padding: 10px 30px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase !important;
    letter-spacing: normal;
    padding-bottom: 10px;
    width: auto;
}
.yesnobtn-no:hover {
    color: #fff;
}

button.yesnobtn-no:after {
    background: #423f3f;
    
}

#otp-model .form-row > select {
    padding: 15px 15px;
}
.pnk-txt {
    color: #ff95a2;
}





/*------------------------------------------
	Responsive
-------------------------------------------*/


@media all and (max-width: 1300px) and (min-width: 768px) {
.ufg-job-application-wrapper {
		padding-top: 130px;
}
}

@media all and (max-width: 1080px) and (min-width: 768px) {
.steppd-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.stepone .offset-lg-6 {
    margin-left: 60%;
}
}

@media (max-width: 991px) {
    .right-content-md {
        margin-top: 30px;
    }
	body.home {
		padding-top: 0px;
	}
	.ufg-job-application-wrapper {
		padding-bottom: 60px;
		max-width: 100%;
		padding-top: 130px;
	}

}
@media all and (max-width: 767px) {
    .right-content-sm {
        margin-top: 30px;
    }
}

@media all and (max-width: 767px) {
    .ugf-wrapper .logo,
    .ugf-wrapper .footer {
        width: 510px;
    }
}

@media all and (max-width: 575px) {
    .ugf-wrapper .logo,
    .ugf-wrapper .footer {
        width: 100%;
        padding: 0 15px;
    }
    .ugf-wrapper .footer {
        display: block;
    }
    .ugf-wrapper .footer .copyright {
        margin-top: 15px;
    }
	.cardbox img {
		height: 100px;
	}
	.modal.right .modal-dialog {
		width: 100%;
	}
	#subcategory-item-model .modal-dialog {
		width: 100%;
	}
	.add-normal-itam-content .cardbox img {
		height: 120px;
	}
	.drop-service .cardbox img {
		height:200px;
	}
	.checkbox-tools:checked + label, .checkbox-tools:not(:checked) + label {
		height: auto !important;
	}
	.price-dtailcard .cardbox img {
		height: 200px;
	}
	
	.drop-service .itemstep .checkbox-tools:checked + label, .drop-service .checkbox-tools:not(:checked) + label {
		height: auto;
	}
	.ufg-job-application-wrapper .job-application-form .btn {
		font-size: 15px;
		padding: 6px 15px;    top: -1px;
	}
	.ufg-job-application-wrapper .prev {
		font-size: 15px;    padding: 6px 15px;
	}
	
}
